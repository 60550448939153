import './css/footer.css';
// import React, {useEffect, useState} from 'react';
import logoHightTechK from './img/logo-svg.svg'
import { Row, Col } from 'antd';

export default function Footer() {
    //     const [svgContent, setSvgContent] = useState('');

    //   useEffect(() => {
    //     const fetchSvg = async () => {
    //       const response = await fetch(logoHightTechK);
    //       const text = await response.text();
    //       setSvgContent(text);
    //     };
    //     fetchSvg();
    //   }, []);
    return (
        <div class="footer-basic">
            <footer>
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <p>ĐƠN VỊ TÀI TRỢ</p>
                    <br />
                    <p><a href="https://hightechk.vn" target="_blank" >CTY TNHH DỊCH VỤ PHẦN MỀM HIGH TECH K</a></p>
                    <Row>
                        <Col span={4} xl={4} xs={0}></Col>
                        <Col span={8} xl={8} xs={24}>
                            <img src={logoHightTechK} alt="High Tech K Logo" style={{ marginLeft: 5, marginRight: 5 }} />
                            {/* <div dangerouslySetInnerHTML={{ __html: svgContent }} style={{width:100}} /> */}
                        </Col>
                        <Col span={8} xl={8} xs={24}>
                            <p style={{ textAlign: "left" }}>HighTechK là đơn vị chuyên cung cấp các dịch vụ dịch vụ cho thuê máy chủ, giải pháp điện toán đám mấy, triển khai hệ thống Kubernetes ( K8S) cho nhiều doanh nghiệp trong nước cũng như ngoài nước.</p>
                            <p style={{ textAlign: "left" }}>Là đơn vị tài trợ máy chủ cho hệ thống <a href="https://dtusvc.com">dtusvc.com</a>, hỗ trợ nền tảng hoạt động mạnh mẽ và bền vững.</p>
                        </Col>
                        <Col span={4} xl={4} xs={0}></Col>
                    </Row>
                </div>
                <div class="social">
                    <a href="https://www.facebook.com/CLBTinhNguyenSinhVienDuyTan" target="_blank">
                        <img class="icon" src="https://api.dtusvc.com/NewsImages/ImagesMail/facebook-circular-logo.png" style={{ width: 40, height: 40 }} />
                    </a>
                    <a href="https://www.youtube.com/@svduytanclbtinhnguyen1056" target="_blank">
                        <img class="icon" src="https://api.dtusvc.com/NewsImages/ImagesMail/youtube.png" style={{ width: 40, height: 40 }} />
                    </a>
                    <a href="https://dtusvc.com" target="_blank">
                        <img class="icon" src="https://api.dtusvc.com/NewsImages/ImagesMail/web.png" style={{ width: 40, height: 40 }} />
                    </a>
                </div>
                <ul class="list-inline">
                    <li class="list-inline-item"><a href="sys-information">THÔNG TIN HỆ THỐNG</a></li>
                    <li class="list-inline-item"> | </li>
                    <li class="list-inline-item"><a href="data-policy">CHÍNH SÁCH SỬ DỤNG DỮ LIỆU</a></li>
                </ul>
                <p class="copyright">Copyright © 2023 - CLB Sinh viên Tình nguyện - ĐH Duy Tân</p>
            </footer>
        </div>
    )
}