import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {Tag, Layout, Card,  message, Pagination, Row, Col,} from 'antd';
import axios from 'axios';
import './css/memberrigister.css';
import moment from 'moment';
import Moment from 'react-moment';
import Cookies from 'universal-cookie';
import './css/firstsignin.css';
    import {
        BrowserRouter as Router,
        Switch,
        Route,
        NavLink,
        useRouteMatch,
    } from "react-router-dom";
import NewspaperTopic from './NewspaperTopic';

const TITLE = "Danh sách bài viết";

function NewspaperList(){
    const { Content, Footer, Header } = Layout;
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [newspaperList, setNewspaperList] = useState(null);
    const [page, setPage] = useState(0);
    const [pageCout, setPageCout] = useState(1);
    let match = useRouteMatch();
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newsCount, setNewsCount] = useState(0);
    const RecruitCard = (props) =>{
        return(
            <NavLink to={"/dashboard/newspaper/newspapers/" + props.dataFromParent.id}>
                <div className="container">
                    <Card title={props.dataFromParent.recruitName} bordered={true} style={{cursor: "pointer", margin: 10}}>
                        <Row align="middle">
                            <Col span={18} xs={24} xl={18}>
                                <Row>
                                    <Col span={24} xs={24} xl={24}>
                                        <p>
                                            <h6 style={{textAlign: "left", fontWeight: 600}}>{props.dataFromParent.title}</h6>
                                        </p>
                                    </Col>
                                    <Col span={24} xs={24} xl={24}>
                                        <p>
                                            <h6 style={{textAlign: "left"}}>Ngày đăng: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.createDate} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                        </p>
                                    </Col>
                                    <Col span={8} xs={24} xl={8}>
                                        <h6 style={{textAlign: "left"}}>Người đăng: <Tag color={"#87d068"}>{props.dataFromParent.uploaderFirstName + ' ' + props.dataFromParent.uploaderLastName}</Tag></h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6} xs={24} xl={6}>
                                <img src={serverAPIIp + props.dataFromParent.posterPath} style={{maxHeight: 100, textAlign: "center"}}/>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </NavLink>
        );
    }

    const getNewspaper = async() =>{
        setLoading(true);
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
        }
        var requestData = {
            page: page,
            pagesize: 20
        };
        await axios.get(serverAPIIp + 'api/Newspaper/GetListNewspaperService', {params: requestData, headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
                var totalNews = parseInt(response.data.message);
                setNewsCount(totalNews);
                var totalPage = Math.ceil(totalNews/20);
                setPageCout(totalPage);
                setNewspaperList(response.data.responseData);
                setLoading(false);
            }else{
                setLoading(false);
                setIsError500(true);
                setErrorMess(response.data.errors);
                message.error(response.data.errors);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    const onChangePage = page =>{
        setPage(page - 1);
        getNewspaper();
    }

    useEffect(() => {
        getNewspaper();
    }, []);

    function AllRecruit(){
        return(
            <div>
                {
                newspaperList?
                newspaperList.map((item) => <RecruitCard dataFromParent={item}/>)
                :
                <div>
                    <Card loading={true}/>
                    <Card loading={true}/>
                    <Card loading={true}/>
                </div>
                }
                <Pagination 
                    style={{marginTop: 15}}
                    total={newsCount} 
                    showTotal={(total, range) => `${range[0]}->${range[1]} / ${total}`}
                    defaultPageSize={20}
                    defaultCurrent={1}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            </div>
        )
    }

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Router>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <Layout style={{minHeight: "85vh"}}>
                    <Header style={{background:"#fff"}}>
                        <p className={"activetitle"}>{ TITLE }</p>
                    </Header>
                    <Content >
                        <Layout style={{background: "#fff"}}>
                            <Content>
                            <div style={{minHeight: "70vh"}}>
                                <Switch>
                                    <Route path={`${match.path}/:topicId`}>
                                        <NewspaperTopic/>
                                    </Route>
                                    <Route path={match.path}>
                                        <AllRecruit/>
                                    </Route>
                                </Switch>
                            </div>
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </Router>
        </div>
    )
}
export default NewspaperList;