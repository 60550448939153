import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Cookies from 'universal-cookie';
import axios from 'axios';
import {Row, Col, Upload, Form, Button, List, Input , message} from 'antd';
import moment from 'moment';
import Message from './Message/Message';
import './MessageList/MessageList.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcons, faImage, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import Picker from 'emoji-picker-react';
import InfiniteScroll from 'react-infinite-scroller';
const { TextArea } = Input;
export default function WebSocketTest(){
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState('wss://api.dtusvc.com/ws?id=');
  const serverAPIIp = 'https://api.dtusvc.com/'; 
  const [messageHistory, setMessageHistory] = useState([]);
  const cookies = new Cookies();
  const [token, setToken] = useState(cookies.get("dtu-svc-token"));
  const [memberId, setMemberId] = useState(cookies.get("dtu-svc-memberId"));
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [messageData, setMessagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [form] = Form.useForm();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const TITLE = "Nhắn tin";

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl + memberId + "&token=" + token);
  const messagesEndRef = useRef(null)
  useEffect(() => {
    if (lastMessage !== null) {
        setMessageHistory((prev) => prev.concat(lastMessage));
        const mess = JSON.parse(lastMessage.data);
        var messItem = {
            author: mess.MemberName,
            avatar: serverAPIIp + mess.Avatar + '?token=' + token,
            content: mess.Message,
            datetime: mess.SendDate,
            memberId: mess.MemberId,
            imagePath: mess.ImagePath? serverAPIIp + mess.ImagePath  + '?token=' + token : ""
        }
        var tempMessages = [];
        tempMessages.push(messItem);
        setMessagesData([...messageData, ...tempMessages])
    }
  }, [lastMessage, setMessageHistory]);

function removeCookie(){
    cookies.remove('dtu-svc-fullname');
    cookies.remove('dtu-svc-accountId');
    cookies.remove('dtu-svc-avatarPath');
    cookies.remove('dtu-svc-token');
    cookies.remove('dtu-svc-roleId');
    cookies.remove('dtu-svc-role');
    cookies.remove('dtu-svc-studentId');
    window.location.href="/";
}

const renderMessages = () => {
    let messageCount = messageData.length;
    let tempMessages = [];
    let i = 0;

    while (i < messageCount) {
      let previous = messageData[i - 1];
      let current = messageData[i];
      let next = messageData[i + 1];
      let isMine = current.memberId === memberId;
      let currentMoment = moment(current.sendDate);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.sendDate);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.memberId === current.memberId;
        
        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.sendDate);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.memberId === current.memberId;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }
    return tempMessages;
  }

async function getMessages (){
    setIsLoading(true);
      const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                x_nPageIndex: pageIndex,
                _nPageSize: pageSize
            };
            
            await axios.get(serverAPIIp + 'api/Chat/GetMessages', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    var messages = [];
                    response.data.responseData.forEach(item =>{
                        var messItem = {
                            author: item.memberName,
                            avatar: serverAPIIp + item.avatar + '?token=' + token,
                            content: item.message,
                            datetime: item.sendDate,
                            memberId: item.memberId,
                            imagePath: item.imagePath? serverAPIIp + item.imagePath + '?token=' + token : ""
                        }
                        messages.push(messItem);
                    })
                    setMessagesData(messages);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
                setIsLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
                setIsLoading(false);
            })
        }else{
            setIsLoading(false);
        }
  }
  useEffect(()=>{
    scrollToBottom();
  }, [messageData]);

  useEffect(() => {
    getMessages();
  }, []);

  function handleClickSendMessage(){
    let message = form.getFieldValue("message");
    if(message !== undefined && message !== null ){
      let str = message.replace(/\s/g, '');
      if(str.length > 0){
        let jsonMessage = {
          message: message,
          memberId: memberId
        }
        sendMessage(JSON.stringify(jsonMessage));
        form.setFieldsValue({message: ""})
      }
    }  
  }

  async function sendImage(options){
    const {onSuccess, onError, file, onProgress } = options;
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
          headers: { 
              'content-type': 'multipart/form-data', 
              'Authorization': 'Bearer ' + token, 
              'accept': '*/*'
            }
        };
        let message = form.getFieldValue("message");
        if(message === undefined){
          message = "";
        }
        fmData.append('images', file);
        fmData.append('messagerStr', message);
        try {
          const res = await axios.post(serverAPIIp + 'api/Chat/SendImageMessage', fmData, config );
          if(res.data.isSuccess){
              form.setFieldsValue({message: ""})
            }else{
            if(res.data.errors[0].indexOf("(401)") >= 0){
                removeCookie();
            }
            message.error(res.data.errors);
          }
        } catch (err) {
            if(err.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
        }
  }

  async function onScrollLoad(){
    const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            pageIndex += 1;
            var requestData = {
                x_nPageIndex: pageIndex,
                _nPageSize: pageSize
            };
            
            await axios.get(serverAPIIp + 'api/Chat/GetMessages', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    var messages = [];
                    response.data.responseData.forEach(item =>{
                        var messItem = {
                            author: item.memberName,
                            avatar: serverAPIIp + item.avatar + '?token=' + token,
                            content: item.message,
                            datetime: item.sendDate,
                            memberId: item.memberId,
                            imagePath: item.imagePath
                        }
                        messages.push(messItem);
                    })
                    setMessagesData(messages);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div className='container' >
      <div style={{background: "#ffffff", overflow: "auto", height: "90vh", position: "relative"}}>
          <Helmet>
            <title>{ TITLE }</title>
          </Helmet>
            <div style={{marginBottom: 20}}>
              <div className="message-list-container">{renderMessages()}</div>
              <div ref={messagesEndRef} />
            </div>
      </div>
      <div style={{dispay:"block",position: "relative"}}>
            <div className='position-absolute bottom-0 end-0 start-0' style={{background: "#E6F2FF",position: "absolute", bottom: "0px"}}>
              <Form
                form={form}>
                <Row justify="space-between" align="middle">
                  <Col span={3} xs={3} xl={3}>
                  <Upload 
                    customRequest={sendImage}
                    showUploadList={false}
                    accept="image/*">
                    <Button style={{width: 40, height: 40}} icon={<FontAwesomeIcon icon={faImage}/>}/>
                  </Upload>
                  </Col>
                  <Col span={18} xs={18} xl={18}>
                      <Form.Item
                        name="message" >
                        <TextArea style={{marginTop: 20}}
                          onPressEnter={(e)=>{handleClickSendMessage(); e.preventDefault()}}
                          autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Viết tin nhắn..."/>
                      </Form.Item>
                  </Col>
                  <Col span={3}xs={3} xl={3}>
                    <Button type='primary' onClick={handleClickSendMessage} htmlType="submit" style={{width: 40, height: 40}} icon={<FontAwesomeIcon icon={faPaperPlane}/>}/>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
    </div>
  );
};