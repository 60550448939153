import React, {useEffect, useState} from 'react';
import logoclb from '../images/Logo-CLBTinhNguyenSVDuyTan.png'
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import { DatePicker, 
    Image, 
    Radio, 
    Row, 
    Col, 
    Select, 
    Form, 
    Input, 
    Space, 
    Button, 
    message, 
    Tag,
    Skeleton  } from 'antd';
import moment from 'moment';
import './css/memberinfo.css';
import axios from 'axios';
import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";

function MemberProfile(){
    const { Option } = Select;
    const cookies = new Cookies();
    const dateFormat = 'YYYY/MM/DD';
    const [data, setData] = useState(null);

    const [avatar, setAvatar] = useState(null);
    const [roleList, setRoleList] = useState(null);
    const [email, setEmail] = useState(null);
    const [joinDate, setJoinDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastName, setLastName] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [birthDay, setBirthDay] = useState(null);
    const [sex, setSex] = useState(true);
    const [address, setAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [linkFacebook, setLinkFacebook] = useState(null);
    const [studenId, setStudenId] = useState(null);
    const [className, setClassName] = useState(null);
    const [facultyName, setFacultyName] = useState(null);
    const [isChangeRole, setIsChangeRole] = useState(false);
    const [roleValue, setRoleValue] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    let { memberId } = useParams();

    const serverAPIIp = 'https://api.dtusvc.com/';    
    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }
    const getJoinDate = (datetime) => {
        var date = new Date(datetime);
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var dt = date.getDate();

        if (dt < 10) {
        dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return (dt +'/' + month + '/' + year);
    }

    function getRoles(){
        const token = cookies.get('dtu-svc-token');
        if(token != null){
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/Role/GetRoles', {headers: headers})
            .then((response) =>{
                if(response.data.isSuccess){
                    setRoleList(response.data.responseData);
                }else{
                    
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                        message.error(response.data.errors);
                    }
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }
            })
        }
    }

    function setDataValue(member){
        const roleId = cookies.get('dtu-svc-role');
        setAvatar(member.avatarPath);
        setEmail(member.email);
        setJoinDate(member.joinDate);
        setLastName(member.lastName);
        setFirstName(member.firstName);
        setBirthDay(member.birthDay);
        setSex(member.sex ? 1 : 2);
        setAddress(member.hometown.ward.wardName + ', ' + member.hometown.district.districtName + ', ' + member.hometown.province.provinceName);
        setPhoneNumber(member.phoneNumber);
        setLinkFacebook(member.linkFacebook);
        setStudenId(member.studentId);
        setClassName(member.className);
        setFacultyName(member.faculty.facultyName);
        setIsChangeRole(roleId < 3 ? true : false);
        setRoleValue(member.roles.id);
        setIsLocked(member.isLocked);
    }

    const GetMemberById = async() =>{
        setLoading(true);
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            memberId: memberId
        };
        await axios.get(serverAPIIp + 'api/Member/GetMemberInfo', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                setData(response.data.responseData);
                setDataValue(response.data.responseData);
                console.log(response.data.responseData)
                setLoading(false);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
                setLoading(false);
            }
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setLoading(false);
        })
    }

    useEffect(async () => {
        GetMemberById();
        getRoles();
        setLoading(false);
    }, []);


    const UpdateForm = () =>{
        const [form] = Form.useForm();

        const updateOnClick = () =>{
            changeRole();
        }
        
        const changeRole = async () => {
            const token = cookies.get('dtu-svc-token');
            const roleId = form.getFieldValue("rolename");
            const changeMemberInfoRequestModel = {
                'memberId': data.id,
                'roleId': roleId
            };
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
    
            await axios.post(serverAPIIp + 'api/Account/ChangeRole', JSON.stringify(changeMemberInfoRequestModel), { headers })
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Thay đổi chức vụ thành công");
                        setRoleValue(roleId);
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors[0]);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối với máy chủ");
                    }
                });
        }

        const handleChangeRole = e => {
            setRoleValue(e);
        }

        useEffect(() => {
            form.setFieldsValue({firstname: lastName});
            form.setFieldsValue({lastname: firstName});
            form.setFieldsValue({phonenumber: phoneNumber});
            form.setFieldsValue({email: email});
            form.setFieldsValue({studentid: studenId});
            form.setFieldsValue({classname: className});
            form.setFieldsValue({facebookpath: linkFacebook});
            form.setFieldsValue({faculty: facultyName});
            form.setFieldsValue({rolename: roleValue});
            form.setFieldsValue({membersex: sex==true?1:2});
            setLoading(false);
        }, [])

        return(
            <Form form = {form} className={"row gutters cantop"}>
                <Row >
                    {/* Họ thành viên */}
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                        <Form.Item 
                            name="firstname"
                            label="Họ:"
                            >
                                <Input disabled={true} placeholder="Nhập họ và tên đệm" maxLength={30}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        {/* Tên thành viên */}
                        <Form.Item
                        name="lastname"
                        label="Tên:"
                        >
                            <Input disabled={true} placeholder="Nhập tên" maxLength={7}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                        {/* Ngày sinh thành viên */}
                        <Form.Item
                            name="birthday"
                            label="Ngày sinh:"
                            initialValue={moment(birthDay, dateFormat).add(7, 'hours')} 
                        >
                            <DatePicker disabled={true} className={"container"} placeholder="Chọn ngày" format={dateFormat}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        {/* Giới tính thành viên */}
                        <Form.Item
                            name="membersex"
                            label="Giới tính:"
                        >
                            <Radio.Group>
                                <Radio disabled={true} value={1}>Nam</Radio>
                                <Radio disabled={true} value={2}>Nữ</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={"col-md-5"}>
                        {/* Địa chỉ thành viên */}
                        <Form.Item
                            name="memberaddress"
                            label="Địa chỉ:"
                        >
                            <Input disabled={true}  placeholder="Địa chỉ" maxLength={150} defaultValue={address}/>
                        </Form.Item>
                    </Col>
                </Row>
                                                
                <Row>
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                    {/* Số điện thoại thành viên */}
                        <Form.Item
                            name="phonenumber"
                            label="Điện thoại:"
                        >
                            <Input disabled={true} placeholder="Nhập số điện thoại" maxLength={15}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        {/* email thành viên */}
                        <Form.Item
                            name="email"
                            label="Email:"
                        >
                            <Input disabled={true} type="email" placeholder="Nhập email" maxLength={100}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className={"col-md-5"}>
                        {/* facebook thành viên */}
                        <Form.Item
                            name="facebookpath"
                            label="Facebook:"
                        >
                            <Input.Search
                                placeholder="Nhập link Facebook"
                                enterButton="Mở Facebook"
                                size="middle"
                                onSearch={()=> window.open(linkFacebook, "_blank")}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    {/* Mã sinh viên */}
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                        <Form.Item 
                            name="studentid"
                            label="Mã số sinh viên:"
                            >
                                <Input disabled={true} placeholder="Nhập mã sinh viên" maxLength={15}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        {/* Mã lớp */}
                        <Form.Item
                        name="classname"
                        label="Lớp:"
                        >
                            <Input disabled={true} placeholder="Nhập tên lớp" maxLength={20}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    {/* Khoa viện */}
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                        <Form.Item
                        name="faculty"
                        label="Khoa/ viện:"
                        >
                            <Input disabled={true} placeholder="Nhập Khoa/Viện" maxLength={20}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        {/* Chức vụ */}
                        <Form.Item
                        name="rolename"
                        label="Chức vụ:"
                        >
                            <Select
                                showSearch
                                className="container text-left"
                                placeholder="Chọn chức vụ"
                                disabled={!isChangeRole || window.location.pathname.includes("/memberout/")}
                                filterOption={
                                    (input, option) =>
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={
                                    (optionA, optionB) =>
                                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                    }
                                onChange={handleChangeRole}
                                >
                                    {roleList != null ? roleList.map((role) => (
                                        <option value={role.id} >{role.roleName}</option>
                                    )) : <Option value="chon">Chọn chức vụ</Option>}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div>
                    <Space>
                        <Button disabled={!isChangeRole} hidden={window.location.pathname.includes("/memberout/")} type="primary" onClick={() => {updateOnClick()}}>Lưu Chức Vụ</Button>
                    </Space>
                </div>
            </Form>
        );
    }

    return(
        <>
            <Helmet>
                <title>{ data?data.lastName + " " + data.firstName:null }</title>
            </Helmet>

            {
                loading ? 
                <Skeleton active />
                :
                <div className="membereinfo">
                    {
                        data?
                        <div className="container">
                        <div className="row gutters">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="card h-100">
                                <div className="card-body">
                                    <div className="account-settings">
                                    <div className="user-profile">
                                        <div className="user-avatar">
                                        <Image src={avatar != null ? serverAPIIp + avatar : logoclb} alt="Maxwell Admin" />
                                        </div>
                                        <h5 className="user-name">{lastName + ' ' + firstName}</h5>
                                    </div>
                                        <div className="about">
                                            <h5>Hoạt động</h5>
                                            <p>Ngày gia nhập: {getJoinDate(joinDate)}</p>
                                            <p>Điểm hoạt động: {data.score === 0?
                                                    <Tag color="#f50">{data.score}</Tag>
                                                :
                                                    <Tag color="#108ee9">{data.score.toFixed(2)}</Tag>}</p>
                                            <p>Tình trạng: {data.isLocked?
                                                <Tag color="#f50">Không Hoạt Động</Tag>
                                            :
                                                <Tag color="#108ee9">Hoạt Động</Tag>}</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="row gutters">
                                            <div>
                                                <UpdateForm/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <Skeleton active />
                    }
                </div>
            }
        </>
    )
}
export default MemberProfile;