import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/NavbarComp';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import FirstSignIn from './components/pages/FirstSignIn';
import ResetPasswd from './components/pages/ResetPasswd';
import Footer from './components/pages/Footer';

function App() {
  const cookies = new Cookies();
  return (
    <div className="App">
      <Router >
        <Switch>
          {
            !window.location.pathname.includes("/FirstSignIn") &&  !window.location.pathname.includes("/ForgotPasswordConfirmation")?
            <Route path="/">
              <NavbarComp/>
            </Route>
            :null
          }
        </Switch>
      </Router>
      <Router>
        <Switch>
          {
            cookies.get('dtu-svc-isDefaulePassword') === "false" || cookies.get('dtu-svc-token') === undefined?
            null
            :
            <div>
              <Route path="/FirstSignIn">
                <FirstSignIn/>
              </Route>
            </div>
            
          }
        </Switch>
      </Router>
      <Router>
        <Switch>
          <Route path="/ForgotPasswordConfirmation">
              <ResetPasswd/>
          </Route>
        </Switch>
      </Router>
    <Footer/>
    </div>
  );
}

export default App;
