import MessengerCustomerChat from 'react-messenger-customer-chat';
import React from 'react';
function ChatFacebook(){
    return(
        <MessengerCustomerChat
            pageId="1027140530829347"
            appId="281417830712635"
        />
    );
}
export default ChatFacebook;