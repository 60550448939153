import React, { useEffect, useState, useRef } from 'react';
import { Table, Image, message, DatePicker, Row, Col, Input, InputNumber, Form, Button, Checkbox, Modal, Upload, Skeleton, Select, Switch, Result, Space, Tag, Tabs } from 'antd';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import Cookies from 'universal-cookie';
import { ImageResize } from "./ImageResize";
import "react-quill/dist/quill.snow.css";
import Preview from "./Preview";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faMedal, faParachuteBox, faQrcode, faSave, faTrash, faUserPlus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './css/createrecuit.css';
import { Video } from "./quill-video-resize";
import "./css/quill-video-resize.css";
import moment from 'moment';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import UIfx from 'uifx';
import ImgCrop from 'antd-img-crop';
import {
    BrowserRouter as Router,
    useParams,
    useHistory
} from "react-router-dom";
import Moment from 'react-moment';
import beepMp3 from './beepmp3/beep-07a.mp3';


Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register({ "formats/video": Video });
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const cookies = new Cookies();
const serverAPIIp = 'https://api.dtusvc.com/';
const { TabPane } = Tabs;
const dateFormat = 'YYYY-MM-DD hh:mm';
var upLoadImages = [];
var addImage = false;

function removeCookie() {
    cookies.remove('dtu-svc-fullname');
    cookies.remove('dtu-svc-accountId');
    cookies.remove('dtu-svc-avatarPath');
    cookies.remove('dtu-svc-token');
    cookies.remove('dtu-svc-roleId');
    cookies.remove('dtu-svc-role');
    cookies.remove('dtu-svc-studentId');
    window.location.href = "/";
}

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
        [{ color: [] }, { background: [] }],

        [{ align: [] }],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        [{ direction: "rtl" }],
        ["link", "image", "video"],
        ["clean"]
    ],
    clipboard: {
        matchVisual: false
    },
    history: {
        delay: 1000,
        maxStack: 50,
        userOnly: false
    },
    imageUploader: {
        upload: async (file) => {
            const token = cookies.get("dtu-svc-token");
            var fmData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token,
                    'accept': '*/*'
                }
            };
            fmData.append('image', file);
            try {
                const res = await axios.post(serverAPIIp + 'api/Newspaper/UploadImage', fmData, config);
                if (res.data.isSuccess) {
                    addImage = true;
                    var images = upLoadImages;
                    images.push(res.data.responseData);
                    upLoadImages = images;
                    return serverAPIIp + res.data.responseData;
                } else {
                    if (res.data.errors[0].indexOf("(401)") >= 0) {
                        removeCookie();
                    }
                    message.error(res.data.errors[0]);
                    return null;
                }
            } catch (err) {
                if (err.toString().indexOf(401) >= 0) {
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                } else {
                    message.error("Mất kết nối với máy chủ");
                }
                return null;
            }
        }
    },
    imageResize: {
        displayStyles: {
            backgroundColor: "black",
            border: "none",
            color: "white"
        },
        modules: ["Resize", "DisplaySize", "Toolbar"]
    }
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "color",
    "background",
    "font",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "clean",
    "code",
    "formula"
];

function removeImage(imagepath) {
    const token = cookies.get('dtu-svc-token');
    if (token !== null) {
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        const removeImageModel = {
            "imagePath": imagepath
        }
        axios.post(serverAPIIp + 'api/Newspaper/RemoveImage', JSON.stringify(removeImageModel), { headers: config })
            .then((response) => {
                if (response.data.isSuccess) {
                } else {
                    if (response.data.errors[0].indexOf("(401)") >= 0) {
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response) => {
                if (response.toString().indexOf(401) >= 0) {
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                } else {
                    message.error("Mất kết nối máy chủ");
                }
            })
    }
}

export default function ActivityAdminTopic() {
    const [form] = Form.useForm();
    const [formChange] = Form.useForm();
    const [sendemailform] = Form.useForm();
    const [formChangeCertificate] = Form.useForm();
    const [formChangeMember] = Form.useForm();
    const [formAddScore] = Form.useForm();
    const [supporter] = Form.useForm();
    const [value, setValue] = useState("");
    const { Search } = Input;
    const [contentNull, setContentNull] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [titleIsNull, setTitleIsNull] = useState(true);
    const [idActivity, setIdActivity] = useState(null);
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [defaultFileListCertificate, setDefaultFileListCertificate] = useState([]);
    const [supporterList, setSupporterList] = useState([]);
    const [certificate, setCertificate] = useState(null);
    const [poster, setPoster] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isCreading, setIsCreading] = useState(false);
    const [provinceList, setProvinceList] = useState(null);
    const [provinceId, setProvinceId] = useState(null);
    const [districtList, setDistrictList] = useState(null);
    const [districtId, setDistrictId] = useState(null);
    const [wardList, setWardList] = useState(null);
    const [wardId, setWardId] = useState(null);
    const [registerList, setRegisterList] = useState(null);
    const [leaveRegistration, setLeaveRegistration] = useState(null);
    const [checkInList, setCheckInList] = useState(null);
    const [checkInListName, setCheckInListName] = useState(null);
    const [isDateNull, setDateIsNull] = useState(true);
    const [isPublic, setIsPublic] = useState(true);
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [isOpenAttendance, setIsOpenAttendance] = useState(false);
    const [isViewMemberActivity, setIsViewMemberActivity] = useState(false);
    const [scanResultWebCam, setScanResultWebCam] = useState('');
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageSP, setPageSP] = useState(1);
    const [pageSizeSP, setPageSizeSP] = useState(10);
    const [leavePage, setLeavePage] = useState(1);
    const [leavePageSize, setLeavePageSize] = useState(10);
    const [countCheckIn, setCountCheckIn] = useState(0);
    const [isDownload, setIsDownload] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [isShowModalSend, setIsShowModalSend] = useState(false);
    const [isShowSupporter, setIsShowSupporter] = useState(false);
    const [isShowChangeMember, setIsShowChangeMember] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const searchInput = useRef(null);
    const [roleCreateActivity, setCreateActivity] = useState([1, 2, 4, 5]);
    const [roleSupport, setRoleSupport] = useState([1, 2]);
    const [roleId, setRoleId] = useState(parseInt(cookies.get('dtu-svc-role')));
    const [isChangeCheckIn, setIsChangeCheckIn] = useState(false);
    const [isLoadingChange, setIsLoadingChange] = useState(false);
    const [checkInId, setCheckInId] = useState(null);
    const { Option } = Select;
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isShowCertificateModal, setIsShowCertificateModal] = useState(false);
    const [registing, setRegisting] = useState(false);
    const [sending, setSending] = useState(false);
    const [isShowAddScore, setIsShowAddScore] = useState(false);
    const [memberId, setMemberId] = useState(null);
    const [currentMember, setCurrentMember] = useState(null);
    const beep = new UIfx(beepMp3);
    const [progress, setProgress] = useState(0);
    const [certificatePath, setCertificatePath] = useState(null);
    const [certificateRootPath, setCertificateRootPath] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());

    let { topicId } = useParams();
    const handleChange = (data) => {
        setValue(data);
        var images = []
        upLoadImages.forEach((image) => {
            if (data.indexOf(serverAPIIp + image) < 0 && !addImage) {
                removeImage(image);
            } else {
                images.push(image);
            }
        })
        upLoadImages = images;
        addImage = false;
        setContentNull(data === "<p><br></p>");
    };

    function confirmDeleteRegister(registerid) {
        Modal.confirm({
            title: 'Xoá bản đăng ký!',
            icon: <ExclamationCircleOutlined />,
            content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
            okText: 'Xoá',
            cancelText: 'Huỷ Bỏ',
            onOk: () => { deleteRegisterClick(registerid) }
        });
    }

    function confirmDeleteSupporter(supporterId, supporterName) {
        var content = `Bạn có muốn xoá ${supporterName} khỏi danh sách người hỗ trợ?`
        Modal.confirm({
            title: 'Xoá người hỗ trợ!',
            icon: <ExclamationCircleOutlined />,
            content: content,
            okText: 'Xoá',
            cancelText: 'Huỷ Bỏ',
            onOk: () => { deleteSupporterClick(supporterId) }
        });
    }

    const handleScan = (err, result) => {
        if (result) {
            checkIn(result.text);
        }
    }

    function checkIn(studenId) {
        const token = cookies.get('dtu-svc-token');
        if (token) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                activitiesAndEventId: idActivity,
                memberIdOrStudentId: studenId,
            };
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/CheckIn', { headers: config, params: requestData })
                .then((response) => {
                    if (response.data.isSuccess) {
                        // console.log(response.data.responseData);
                        setScanResultWebCam(response.data.responseData);
                        beep.play();
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors[0]);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                })
        }
    }

    function deleteRegisterClick(registerid) {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            if (idActivity) {
                const config = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    activitiesAndEventId: idActivity,
                    registrationId: registerid,
                };
                axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveRegistration', { headers: config, params: requestData })
                    .then((response) => {
                        if (response.data.isSuccess) {
                            message.success("Xoá thành công");
                            var member = [];
                            registerList.forEach((item) => {
                                if (item.id !== registerid) {
                                    member.push(item);
                                }
                            });
                            setRegisterList(member);
                        } else {
                            if (response.data.errors[0].indexOf("(401)") >= 0) {
                                removeCookie();
                            }
                            message.error(response.data.errors[0]);
                        }
                    })
                    .catch((response) => {
                        if (response.toString().indexOf(401) >= 0) {
                            removeCookie();
                            message.error("Phiên đăng nhập đã hết hạn");
                        } else {
                            message.error("Mất kết nối máy chủ");
                        }
                    })
            } else {
                message.error("Bạn chỉ được xoá hoạt động vừa tạo");
            }
        }
    }

    function deleteSupporterClick(supporterId) {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            if (topicId) {
                const config = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    activityId: topicId,
                    studentId: supporterId,
                };
                axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveMemberSupportActivity', { headers: config, params: requestData })
                    .then((response) => {
                        if (response.data.isSuccess) {
                            message.success("Xoá thành công");
                            var member = [];
                            supporterList.forEach((item) => {
                                if (item.studentId !== supporterId) {
                                    member.push(item);
                                }
                            });
                            setSupporterList(member);
                        } else {
                            if (response.data.errors[0].indexOf("(401)") >= 0) {
                                removeCookie();
                            }
                            message.error(response.data.errors[0]);
                        }
                    })
                    .catch((response) => {
                        if (response.toString().indexOf(401) >= 0) {
                            removeCookie();
                            message.error("Phiên đăng nhập đã hết hạn");
                        } else {
                            message.error("Mất kết nối máy chủ");
                        }
                    })
            } else {
                message.error("Bạn chỉ được xoá hoạt động vừa tạo");
            }
        }
    }

    function confirm() {
        Modal.confirm({
            title: 'Xoá bài viết!',
            icon: <ExclamationCircleOutlined />,
            content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
            okText: 'Xoá',
            cancelText: 'Huỷ Bỏ',
            onOk: () => { deleteClick() }
        });
    }

    function confirmSave() {
        Modal.confirm({
            title: 'Lưu thay đổi!',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn có thực sự muốn lưu các thay đổi về hoạt động không?',
            okText: 'Lưu',
            cancelText: 'Huỷ Bỏ',
            onOk: () => { saveActivity() }
        });
    }

    const handleOnChange = ({ fileList: newFileList }) => {
        if (newFileList.length !== 0 && newFileList[0].name.match(/\.(jpg|jpeg|png)$/)) {
            setDefaultFileList(newFileList);
        }
        if (newFileList.length == 0) {
            setPoster(null);
            setDefaultFileList([]);
        }
    };

    const handleOnChangeCertificate = ({ fileList: newFileList }) => {
        if (newFileList.length !== 0 && newFileList[0].name.match(/\.(jpg|jpeg|png)$/)) {
            if (certificatePath) {
                setDefaultFileListCertificate([{
                    uid: '-1',
                    name: certificatePath.split('/')[certificatePath.split('/').length - 1],
                    status: 'done',
                    url: certificatePath,
                    thumbUrl: certificatePath,
                }]);
            }
        }
        if (newFileList.length == 0) {
            setCertificate(null);
            setDefaultFileListCertificate([]);
        }
    };

    const onPreviewCertificate = async file => {
        if (certificatePath) {
            setDefaultFileListCertificate([{
                uid: '-1',
                name: certificatePath.split('/')[certificatePath.split('/').length - 1],
                status: 'done',
                url: certificatePath,
                thumbUrl: certificatePath,
            }]);
            let src = certificatePath;
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        } else {
            setCertificate(null);
        }
    };

    const onPreview = async file => {
        if (file) {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        } else {
            setPoster(null);
        }
    };

    async function saveActivity() {
        setIsCreading(true);
        var title = form.getFieldValue("title");
        var timeline = form.getFieldValue("timeline");
        var deadline = form.getFieldValue("deadline");
        var provinceId = form.getFieldValue("province");
        var district = form.getFieldValue("district");
        var ward = form.getFieldValue("ward");
        var street = form.getFieldValue("street");
        var type = form.getFieldValue("type");
        var valid = true;
        if (title === undefined || title === null) {
            valid = false;
            form.setFields([{
                name: "title",
                errors: ["Vui lòng nhập tiêu đề!"]
            }]);
        } else {
            if (title.replace(/ /g, '') === '') {
                valid = false;
                form.setFields([{
                    name: "title",
                    errors: ["Vui lòng nhập tiêu đề!"]
                }]);
            }
        }

        if (value === null || value === "<p><br></p>" || value === "") {
            valid = false;
            setContentNull(true);
        }

        if (timeline === null || timeline === undefined) {
            valid = false;
            form.setFields([{
                name: "timeline",
                errors: ["Vui lòng nhập thời gian diễn ra!"]
            }]);
        }

        if (deadline === null || deadline === undefined) {
            valid = false;
            form.setFields([{
                name: "deadline",
                errors: ["Vui lòng nhập hạn cuối nhận hồ sơ!"]
            }]);
        }

        if (deadline < new Date()) {
            valid = false;
            form.setFields([{
                name: "deadline",
                errors: ["Hạn cuối nhận hồ sơ không được nhỏ hơn thời gian hiện tại!"]
            }]);
        }

        if (provinceId === null || provinceId === undefined) {
            valid = false;
            form.setFields([{
                name: "province",
                errors: ["Vui lòng chọn Tỉnh/Thành phố!"]
            }]);
        }

        if (district === null || district === undefined) {
            valid = false;
            form.setFields([{
                name: "district",
                errors: ["Vui lòng chọn Quận/Huyện!"]
            }]);
        }

        if (ward === null || ward === undefined) {
            valid = false;
            form.setFields([{
                name: "ward",
                errors: ["Vui lòng chọn Xã/Phường!"]
            }]);
        }

        if (street === null || street === undefined) {
            valid = false;
            form.setFields([{
                name: "street",
                errors: ["Vui lòng nhập địa chỉ cụ thể!"]
            }]);
        }

        if (type === null || type === undefined) {
            type = true;
        }

        if (valid) {
            const token = cookies.get('dtu-svc-token');
            if (token !== null) {
                var frdata = new FormData();
                frdata.append("title", title);
                frdata.append("content", value);
                frdata.append("poster", poster);
                frdata.append("streesNamed", street);
                frdata.append("provinceId", provinceId);
                frdata.append("districtId", district);
                frdata.append("wardId", ward);
                frdata.append("startDate", timeline[0].toISOString());
                frdata.append("endDate", timeline[1].toISOString());
                frdata.append("registrationDeadline", deadline.toISOString());
                frdata.append("isPublic", !isPublic);
                frdata.append("id", idActivity);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + token,
                        'accept': '*/*'
                    },
                }
                var path = "api/ActivitiesAndEvents/ChangeActivitiesAndEvents";
                await axios.post(serverAPIIp + path, frdata, config)
                    .then((response) => {
                        if (response.data.isSuccess) {
                            message.success("Cập nhật hoạt động thành công");
                            setIsShowModalSend(true);
                        } else {
                            if (response.data.errors[0].indexOf("(401)") >= 0) {
                                removeCookie();
                            }
                            message.error(response.data.errors);
                        }
                        setIsCreading(false);
                    }).catch((response) => {
                        message.error("Mất kết nối với máy chủ");
                        setIsCreading(false);
                    });
            }
        }
        setIsCreading(false);
    }

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    }

    function onChangeDate(e) {
        setDateIsNull(e === null);
    }

    function getProvince() {
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        axios.get(serverAPIIp + 'api/Address/GetProvinces', { headers: headers })
            .then((response) => {
                if (response.data.isSuccess) {
                    setProvinceList(response.data.responseData);
                }
            })
            .catch((response) => {
                message.error("Mất kết nối với máy chủ!");
            })
    }

    function SendMail() {
        setIsSending(true);
        const isValid = true;
        const content = sendemailform.getFieldValue("mailcontent");
        const token = cookies.get('dtu-svc-token');

        if (content === undefined || content.replace(/ /g, '') === '') {
            sendemailform.setFields([{
                name: "mailcontent",
                errors: ["Vui lòng nhập nội dung cần thông báo"]
            }]);
        }
        var errorList = form.getFieldsError();
        errorList.forEach((error) => {
            if (error.errors.length > 0) {
                isValid = false;
            }
        });
        if (idActivity && isValid && cookies) {
            const sendEmailChangeEventModel = {
                'eventId': idActivity,
                'emailContent': content,
            }
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.post(serverAPIIp + 'api/ActivitiesAndEvents/SendEmailRegisterChangeActivity', JSON.stringify(sendEmailChangeEventModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        message.success("Gửi email thành công.");
                        setIsShowModalSend(false);
                        sendemailform.setFields([{
                            name: "mailcontent",
                            value: [""]
                        }]);
                    } else {
                        message.error(response.data.errors);
                    }
                    setIsSending(false);
                })
                .catch((response) => {
                    message.error("Mất kết nối với máy chủ");
                    setIsSending(false);
                });
        }
        else {
            setIsSending(false);
        }
    }

    const cancelModal = () => {
        setIsChangeCheckIn(false);
        setIsLoadingChange(false);
    }

    const changeCheckIn = async (valuedate) => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const checkInModel = {
                checkInID: checkInId,
                startDate: valuedate[0].toISOString(),
                endDate: valuedate[1].toISOString(),
                isAll: isCheckAll
            }

            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/ChangeCheckIn', JSON.stringify(checkInModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        // getActivityHistory(memberId)
                        setIsLoadingChange(false);
                        message.success("Cập nhật điểm danh thành công");
                        setIsCheckAll(false);
                        cancelModal();
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                        setIsLoadingChange(false);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                        setIsLoadingChange(false);
                    }
                })
        }
    }

    const getDistrict = async (proviceId) => {
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        await axios.get(serverAPIIp + 'api/Address/GetDistricts', {
            params: {
                provinceId: proviceId
            }
        }, { headers: headers })
            .then((response) => {
                if (response.data.isSuccess) {
                    setDistrictList(response.data.responseData);
                } else {
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response) => {
                message.error("Mất kết nối với máy chủ");
            })
    }

    const getWards = async (districtId) => {
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        await axios.get(serverAPIIp + 'api/Address/GetWards', {
            params: {
                districtId: districtId
            }
        }, { headers: headers })
            .then((response) => {
                if (response.data.isSuccess) {
                    setWardList(response.data.responseData);
                } else {
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response) => {
                message.error("Mất kết nối với máy chủ");
            })
    }

    const handleChangeProvince = e => {
        form.setFieldsValue({ district: undefined });
        form.setFieldsValue({ ward: undefined });
        setProvinceId(e);
        getDistrict(e);
    };

    const handleChangeDistrict = e => {
        form.setFieldsValue({ ward: undefined });
        setDistrictId(e);
        getWards(e);
    };

    const handleChangeWard = e => {
        setWardId(e);
    };

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    function deleteClick() {
        setIsCreading(true);
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            if (idActivity) {
                const config = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    activitiesAndEventId: idActivity
                };
                axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveActivitiesAndEvents', { headers: config, params: requestData })
                    .then((response) => {
                        if (response.data.isSuccess) {
                            message.success("Xoá thành công");
                            history.goBack();
                        } else {
                            if (response.data.errors[0].indexOf("(401)") >= 0) {
                                removeCookie();
                            }
                            message.error(response.data.errors[0]);
                        }
                    })
                    .catch((response) => {
                        if (response.toString().indexOf(401) >= 0) {
                            removeCookie();
                            message.error("Phiên đăng nhập đã hết hạn");
                        } else {
                            message.error("Mất kết nối máy chủ");
                        }
                    })
            } else {
                message.error("Bạn chỉ được xoá hoạt động vừa tạo");
            }
        }
    }

    const dummyRequest = ({ file, onSuccess }) => {
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            onSuccess("error");
        } else {
            setTimeout(() => {
                onSuccess("ok");
                setPoster(file);
            }, 0);
        }
    };

    function onChangeTitle(e) {
        setTitleIsNull(e === null);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []); }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Tìm
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Đặt Lại
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText(null);
    };

    const openChangeCheckIn = (itemId) => {
        const dateFormat = 'YYYY/MM/DD hh:mm:ss';
        var chk = checkInList.find(function (item) {
            return item.id === itemId;
        });
        formChange.setFields([{
            name: "changeDate",
            value: [moment(chk.inStart, dateFormat).add(7, 'hours'), moment(chk.outStart, dateFormat).add(7, 'hours')]
        }]);
        setIsChangeCheckIn(true);
        setCheckInId(itemId);
    }

    const listCheckInCol = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 80,
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: "Check In",
            dataIndex: "inStart",
            key: "inStart",
            render: (text, record, index) => {
                if (record.isAdditionalScore) {
                  return {
                    children: <span>{record.reason}</span>,
                    props: {
                      rowSpan: 1,
                    },
                  };
                }
                return <span><Moment date={record.inStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
              },
        },
        {
            title: "Check Out",
            dataIndex: "outStart",
            key: "outStart",
            render: (text, record) => {
                if (record.isAdditionalScore) {
                  return null;
                }
                else
                {
                    if (record.score === 0) {
                        return <span style={{ color: 'red' }}>Chưa check out</span>;
                    }
                    else{
                        return <span><Moment date={record.outStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
                    }
                }
              },
        },
        {
            title: "Điểm",
            dataIndex: "scores",
            key: "scores",
            width: 90,
            render: scores => <Tag color={"#f50"}>{scores.toFixed(2)}</Tag>
        },
        {
            title: "Quản Lý",
            dataIndex: "action",
            render: (text, record) =>
                <span>{record.managerFirstName} {record.managerLastName}</span>
        },
        {
            title: "Hành Động",
            dataIndex: "id",
            key: "id",
            render: (text, record) =>
                <Row>
                    {
                        roleId < 3 ?
                            <Col style={{ padding: 2 }}>
                                <Button disabled={record.isAdditionalScore} onClick={() => { openChangeCheckIn(record.id); }} type={"primary"} icon={<FontAwesomeIcon icon={faEdit} />} />
                            </Col>
                            : null
                    }
                    <Col style={{ padding: 2 }}>
                        <Button type={"primary"} onClick={() => { confirmDeleteCheckIn(record.id) }} icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Col>
                </Row>
        },
    ]

    function confirmDeleteCheckIn(id) {
        Modal.confirm({
            title: 'Xoá!',
            icon: <ExclamationCircleOutlined />,
            content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
            okText: 'Xoá',
            cancelText: 'Huỷ Bỏ',
            onOk: () => { deleteCheckIn(id) }
        });
    }

    const deleteCheckIn = async (id) => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const checkin = {
                checkInId: id
            }
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveCheckIn', { params: checkin, headers: config })
                .then((response) => {
                    if (response.data.isSuccess) {
                        message.success("Xoá điểm danh thành công");
                        var checkListRemove = [];
                        checkInList.forEach(item => {
                            if (item.id !== id) {
                                checkListRemove.push(item)
                            }
                        })
                        setCheckInList(checkListRemove);
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                })
        }
    }

    async function DownloadListJoinActivity() {
        setIsDownload(true);
        const token = cookies.get('dtu-svc-token');
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        var requestData = {
            activitiesAndEventId: topicId,
        };
        await axios.get(serverAPIIp + 'api/ActivitiesAndEvents/DownloadListJoinActivity', { params: requestData, headers: config })
            .then((response) => {
                if (response.data.isSuccess) {
                    window.open(serverAPIIp + response.data.responseData, "_blank");
                } else {
                    if (response.data.errors[0].indexOf("(401)") >= 0) {
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
                setIsDownload(false);
            })
            .catch((response) => {
                message.error("Mất kết nối với máy chủ");
                setIsDownload(false);
            })
    }

    const onChangeCheckIn = () => {
        var valuedate = formChange.getFieldValue("changeDate");
        if (valuedate !== null && valuedate !== undefined) {
            formChange.setFields([{
                name: "changeDate",
                errors: []
            }]);
            changeCheckIn(valuedate);
        } else {
            formChange.setFields([{
                name: "changeDate",
                errors: ["Vui lòng chọn ngày tháng!"]
            }]);
        }
    }

    function onChangeCheckBox(e) {
        setIsCheckAll(e.target.checked);
    }

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render: (value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Ngày ĐK",
            dataIndex: "createDate",
            key: "createDate",
            width: 140,
            render: createDate => <span><Moment date={createDate} format="DD-MM-YYYY HH:mm:ss" /></span>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ",
            dataIndex: "firstName",
            key: "firstName",
            ...getColumnSearchProps('firstName'),
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: "Tên",
            dataIndex: "lastName",
            key: "lastName",
            ...getColumnSearchProps('lastName'),
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ...getColumnSearchProps('phoneNumber'),
        },
        // {
        //     title: "Email",
        //     dataIndex: "email",
        //     key: "email",
        //     ...getColumnSearchProps('email'),
        // },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
            ...getColumnSearchProps('facultyName'),
        },
        {
            title: "Lớp",
            dataIndex: "className",
            key: "className",
            ...getColumnSearchProps('className'),
        },
        {
            title: "Loại",
            dataIndex: "isMember",
            key: "isMember",
            render: isMember => <Tag color={isMember ? "success" : "error"}>{isMember ? "Thành viên" : "CTV"}</Tag>
        },
        {
            title: "Tham Gia",
            dataIndex: "activity",
            render: (text, record) => <Tag color={record.listCheckIn.length === 0 ? "#f50" : "#108ee9"}>{record.listCheckIn.length ? "Có" : "Chưa"}</Tag>
        },
        {
            title: "Điểm",
            dataIndex: "scores",
            key: "scores",
            width: 90,
            render: scores => <Tag color={Math.round(scores) === 0 ? "#f50" : "#108ee9"}>{scores.toFixed(2)}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            render: (text, record) =>
                <Row>
                    <Col style={{ padding: 2 }}>
                        <Button
                            type={"primary"}
                            title="Chi tiết"
                            onClick={() => {
                                setIsViewMemberActivity(true);
                                setCheckInList(record.listCheckIn);
                                setCheckInListName(`${record.firstName} ${record.lastName}`);
                            }}
                            icon={<FontAwesomeIcon icon={faEye} />} />
                    </Col>
                    {
                        roleId < 7 ?
                            <Col style={{ padding: 2 }}>
                                <Button
                                    title="Mở Facebook"
                                    onClick={() => window.open(record.facebookPath, "_blank")}
                                    type={"primary"}
                                    icon={<i className="fab fa-facebook-f"></i>} />
                            </Col>
                            : null
                    }
                    {
                        roleCreateActivity.includes(roleId) && !record.isMember ?
                            <Col style={{ padding: 2 }}>
                                <Button onClick={() => { openModalChangeMemberInfo(record) }} type={"primary"} icon={<FontAwesomeIcon icon={faEdit} />} />
                            </Col>
                            : null
                    }
                    {
                        <Col style={{ padding: 2 }}>
                            <Button onClick={() => { openModalAddScore(record) }} type={"primary"} icon={<FontAwesomeIcon icon={faPlus} />} />
                        </Col>
                    }
                    {
                        roleId < 7 ?
                            <Col style={{ padding: 2 }}>
                                <Button
                                    onClick={() => { confirmDeleteRegister(record.id); }}
                                    type={"primary"}
                                    title="Xoá"
                                    icon={<FontAwesomeIcon icon={faTrash} />} danger />
                            </Col>
                            : null
                    }
                </Row>
        },
    ]

    function openModalChangeMemberInfo(member) {
        if (member) {
            setMemberId(member.id);
            formChangeMember.setFieldsValue({
                firstname: member.firstName,
                lastname: member.lastName,
                phonenumber: member.phoneNumber,
                email: member.email,
                studentid: member.studentId,
                classname: member.className
            });
            setIsShowChangeMember(true);
        }
    }

    function openModalAddScore(objMember) {
        if (objMember) {
            setCurrentMember(objMember);
            setIsShowAddScore(true);
        }
    }

    const addScore = async () => {
        if (currentMember) {
            var score = formAddScore.getFieldValue("score");
            var reason = formAddScore.getFieldValue("reason");

            if (score && reason){
                const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            
            const sendCertificateModel = {
                'eventId': idActivity,
                'memberId': currentMember.id,
                'reason': reason,
                'additionalScore': score,
                'isMamber': currentMember.isMember,
            };
            await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/AddScore', JSON.stringify(sendCertificateModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        setIsShowAddScore(false);
                        message.success("Thêm điểm cộng thành công.");
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                })
        }
            }
            else{
                message.error("Dữ liệu nhập điểm không hợp lệ.");
            }
        }
    }

    const supporterColumns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 60,
            render: (value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Ngày ĐK",
            dataIndex: "createDate",
            key: "createDate",
            width: 140,
            render: createDate => <span><Moment date={createDate} format="DD-MM-YYYY HH:mm:ss" /></span>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ & tên",
            dataIndex: "memberFullName",
            key: "memberFullName",
            ...getColumnSearchProps('memberFullName'),
            sorter: (a, b) => a.memberFullName.localeCompare(b.memberFullName),
        },
        {
            title: "Người thêm",
            dataIndex: "adderName",
            key: "adderName",
            ...getColumnSearchProps('adderName'),
            sorter: (a, b) => a.adderName.localeCompare(b.adderName),
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            width: 100,
            render: (text, record) =>
                <Row>
                    <Col style={{ padding: 2 }}>
                        <Button
                            onClick={() => { confirmDeleteSupporter(record.studentId, record.memberFullName); }}
                            type={"primary"}
                            title="Xoá"
                            icon={<FontAwesomeIcon icon={faTrash} />} danger />
                    </Col>
                </Row>
        },
    ]

    const leaveColumns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render: (value, item, index) => (leavePage - 1) * leavePageSize + index + 1
        },
        {
            title: "Ngày ĐK",
            dataIndex: "createDate",
            key: "createDate",
            width: 140,
            render: createDate => <span><Moment date={createDate} format="DD-MM-YYYY HH:mm:ss" /></span>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            width: 140,
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ",
            dataIndex: "memberFirstName",
            key: "memberFirstName",
            ...getColumnSearchProps('memberFirstName'),
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: "Tên",
            dataIndex: "memberLastName",
            key: "memberLastName",
            width: 50,
            ...getColumnSearchProps('memberLastName'),
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ...getColumnSearchProps('phoneNumber'),
        },
        {
            title: "Lý Do",
            dataIndex: "reason",
            key: "reason",
        },
        {
            title: "Facebook",
            dataIndex: "action",
            render: (text, record) =>
                <Button
                    title="Mở Facebook"
                    onClick={() => window.open(record.linkFacebook, "_blank")}
                    type={"primary"}
                    icon={<i className="fab fa-facebook-f"></i>} />
        },
    ]

    function pagination(page, pageSize) {
        setPage(page);
        setPageSize(pageSize);
    }

    function paginationSP(page, pageSize) {
        setPageSP(page);
        setPageSizeSP(pageSize);
    }

    function leavePagination(page, pageSize) {
        setLeavePage(page);
        setLeavePageSize(pageSize);
    }

    const getActivity = async () => {
        setLoading(true);
        const token = cookies.get('dtu-svc-token');
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        var requestData = {
            activityId: topicId,
        };
        await axios.get(serverAPIIp + 'api/ActivitiesAndEvents/GetActivityByAdmin', { params: requestData, headers: config })
            .then((response) => {
                if (response.data.isSuccess) {
                    var checkIn = 0;
                    response.data.responseData.registration.forEach(item => {
                        if (item.listCheckIn.length !== 0) {
                            checkIn += 1;
                        }
                    });
                    const timeNow = moment(new Date());
                    setCountCheckIn(checkIn);
                    setIdActivity(response.data.responseData.id);
                    setValue(response.data.responseData.content);
                    setRegisterList(response.data.responseData.registration);
                    setSupporterList(response.data.responseData.supporterList);
                    setLeaveRegistration(response.data.responseData.leaveRegistration);
                    getDistrict(response.data.responseData.address.province.provinceId);
                    getWards(response.data.responseData.address.district.districtId);
                    setDefaultFileList([{
                        uid: '-1',
                        name: response.data.responseData.posterPath.split('/')[response.data.responseData.posterPath.split('/').length - 1],
                        status: 'done',
                        url: serverAPIIp + response.data.responseData.posterPath,
                        thumbUrl: serverAPIIp + response.data.responseData.posterPath,
                    }]);
                    form.setFieldsValue({
                        title: response.data.responseData.title,
                        deadline: moment(response.data.responseData.registrationDeadline, "YYYY-MM-DD hh:mm").add(7, 'hours'),
                        timeline: [
                            moment(response.data.responseData.startDate, "YYYY-MM-DD hh:mm").add(7, 'hours'),
                            moment(response.data.responseData.endDate, "YYYY-MM-DD hh:mm").add(7, 'hours'),
                        ],
                        province: response.data.responseData.address.province.provinceId,
                        district: response.data.responseData.address.district.districtId,
                        ward: response.data.responseData.address.ward.wardId,
                        street: response.data.responseData.address.streesName,
                    });
                    setIsPublic(!response.data.responseData.isPublic);
                    setLoading(false);
                } else {
                    if (response.data.errors[0].indexOf("(401)") >= 0) {
                        removeCookie();
                    }
                    setLoading(false);
                    message.error(response.data.errors);
                }
            })
            .catch((response) => {
                message.error("Mất kết nối với máy chủ");
                setLoading(false);
            })
    }

    const addSupporter = () => {
        const studentId = supporter.getFieldValue("studentIdSP");
        const token = cookies.get('dtu-svc-token');
        var valid = true;
        if (studentId === undefined || studentId === null) {
            valid = false;
            supporter.setFields([{
                name: "studentIdSP",
                errors: ["Vui lòng nhập MSSV!"]
            }]);
        } else {
            if (studentId.replace(/ /g, '') === '') {
                valid = false;
                supporter.setFields([{
                    name: "studentIdSP",
                    errors: ["Vui lòng nhập MSSV!"]
                }]);
            }
        }
        if (valid && token) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                activityId: topicId,
                studentId: studentId
            };
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/AddMemberSupportActivity', { params: requestData, headers: config })
                .then((response) => {
                    if (response.data.isSuccess) {
                        message.success("Thêm người hỗ trợ thành công");
                        var listSp = [];
                        listSp.push(response.data.responseData);
                        supporterList.forEach(item => {
                            listSp.push(item);
                        });
                        setSupporterList(listSp);
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                })
                .catch((response) => {
                    message.error("Mất kết nối với máy chủ");
                })
        }
    }

    const onSearchCheckIn = value => {
        checkIn(value);
    }

    function onChangeMember() {
        setRegisting(true);
        const token = cookies.get('dtu-svc-token');
        var firstname = formChangeMember.getFieldValue("firstname");
        var lastname = formChangeMember.getFieldValue("lastname");
        var phonenumber = formChangeMember.getFieldValue("phonenumber");
        var email = formChangeMember.getFieldValue("email");
        var classname = formChangeMember.getFieldValue("classname");
        var studentid = formChangeMember.getFieldValue("studentid");
        var isValid = true;
        if (firstname === undefined || firstname.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "firstname",
                errors: ["Vui lòng nhập họ và tên đệm"]
            }]);
        }

        if (lastname === undefined || lastname.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "lastname",
                errors: ["Vui lòng nhập tên"]
            }]);
        }

        if (classname === undefined || classname.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "classname",
                errors: ["Vui lòng nhập lớp"]
            }]);
        }

        if (studentid === undefined || studentid.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "studentid",
                errors: ["Vui lòng nhập mã sinh viên"]
            }]);
        }

        if (phonenumber === undefined || phonenumber.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "phonenumber",
                errors: ["Vui lòng nhập số điện thoại"]
            }]);
        }

        if (email === undefined || email.replace(/ /g, '') === '') {
            formChangeMember.setFields([{
                name: "email",
                errors: ["Vui lòng nhập email"]
            }]);
        }

        var errorList = form.getFieldsError();
        errorList.forEach((error) => {
            if (error.errors.length > 0) {
                isValid = false;
            }
        });
        if (token && isValid && memberId) {
            const communityRegistrationInfoModel = {
                'id': memberId,
                'lastName': lastname,
                'firstName': firstname,
                'phoneNumber': phonenumber,
                'email': email,
                'className': classname,
                'studentId': studentid,
            }
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.post(serverAPIIp + 'api/ActivitiesAndEvents/ChangeCommunityRegistration', JSON.stringify(communityRegistrationInfoModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        message.success("Chỉnh sửa thông tin thành công.");
                    } else {
                        message.error(response.data.errors);
                    }
                    setRegisting(false);
                })
                .catch((response) => {
                    message.error("Mất kết nối với máy chủ");
                    setRegisting(false);
                });
        }
        else {
            setRegisting(false);
        }
    }

    const uploadImage = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
                'accept': '*/*'
            },
            onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            },
        };
        var location = formChangeCertificate.getFieldValue("locationY");
        var fontsize = formChangeCertificate.getFieldValue("fontsize");

        var locationY = 0;
        var nfontsize = 0;
        try {
            if (fontsize === undefined || fontsize === null || fontsize === NaN) {
                nfontsize = 52;
            } else {
                nfontsize = parseInt(fontsize);
            }
        } catch {
            nfontsize = 52;
        }

        try {
            if (location === undefined || location === null || location === NaN) {
                locationY = 520;
            } else {
                locationY = parseInt(location);
            }
        } catch {
            locationY = 520;
        }

        fmData.append('certificate', file);
        fmData.append('activityId', topicId);
        fmData.append('loacation_Y', locationY);
        fmData.append('fontSize', nfontsize);
        try {
            const res = await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/UploadCertificate', fmData, config);

            onSuccess('Ok');
            if (res.data.isSuccess) {
                setDefaultFileListCertificate([]);
                setDefaultFileListCertificate([{
                    uid: '-1',
                    name: res.data.message.split('/')[res.data.message.split('/').length - 1],
                    status: 'done',
                    url: serverAPIIp + res.data.message,
                    thumbUrl: serverAPIIp + res.data.message,
                }]);
                setCertificatePath(serverAPIIp + res.data.message);
                setCertificateRootPath(res.data.responseData);
            } else {
                if (res.data.errors[0].indexOf("(401)") >= 0) {
                    removeCookie();
                }
                message.error(res.data.errors);
            }
        } catch (err) {
            if (err.toString().indexOf(401) >= 0) {
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            } else {
                message.error("Mất kết nối với máy chủ");
                onError({ err });
            }
        }
    };

    const deleteFile = async () => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const checkin = {
                x_sFilePath: certificateRootPath,
                x_sActivityId: topicId
            }
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveCertificate', { params: checkin, headers: config })
                .then((response) => {
                    if (response.data.isSuccess) {
                        setDefaultFileListCertificate([]);
                        setCertificatePath(null);
                        setCertificateRootPath(null);
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                })
        }
    }

    const onChangeLocationOrSize = async () => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var location = formChangeCertificate.getFieldValue("locationY");
            var fontsize = formChangeCertificate.getFieldValue("fontsize");

            var locationY = 0;
            var nfontsize = 0;
            try {
                if (fontsize === undefined || fontsize === null || fontsize === NaN) {
                    nfontsize = 52;
                } else {
                    nfontsize = parseInt(fontsize);
                }
            } catch {
                nfontsize = 52;
            }

            try {
                if (location === undefined || location === null || location === NaN) {
                    locationY = 520;
                } else {
                    locationY = parseInt(location);
                }
            } catch {
                locationY = 520;
            }
            const sendCertificateModel = {
                'imagePath': certificateRootPath,
                'activityId': topicId,
                'loacation_Y': locationY,
                'fontSize': nfontsize,
            };
            await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/ReviewCertificate', JSON.stringify(sendCertificateModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        setTimestamp(Date.now());
                        setCertificatePath(`${serverAPIIp + response.data.message}&${Date.now()}`);
                        setCertificateRootPath(response.data.responseData);
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                })
        }
    }

    const onSendMail = async () => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var location = formChangeCertificate.getFieldValue("locationY");
            var fontsize = formChangeCertificate.getFieldValue("fontsize");

            var locationY = 0;
            var nfontsize = 0;
            try {
                if (fontsize === undefined || fontsize === null || fontsize === NaN) {
                    nfontsize = 52;
                } else {
                    nfontsize = parseInt(fontsize);
                }
            } catch {
                nfontsize = 52;
            }

            try {
                if (location === undefined || location === null || location === NaN) {
                    locationY = 520;
                } else {
                    locationY = parseInt(location);
                }
            } catch {
                locationY = 520;
            }
            const sendCertificateModel = {
                'imagePath': certificateRootPath,
                'activityId': topicId,
                'loacation_Y': locationY,
                'fontSize': nfontsize,
            };
            await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/SendCertificate', JSON.stringify(sendCertificateModel), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        setTimestamp('');
                        setCertificatePath('');
                        setCertificateRootPath('');
                        message.success("Gửi giấy chứng nhận thành công!");
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        message.error(response.data.errors);
                    }
                    setSending(false);
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                    setSending(false);
                })
        }
        setSending(false);
    }

    useEffect(() => {
        getActivity();
        getProvince();
    }, []);

    return (
        <div>
            {
                loading ?
                    <Skeleton />
                    :
                    <div>
                        {
                            defaultFileList ?
                                <div style={{ minHeight: "61vh" }}>
                                    <Modal
                                        title={checkInListName}
                                        centered
                                        visible={isViewMemberActivity}
                                        onCancel={() => setIsViewMemberActivity(false)}
                                        footer={[
                                            <Button type='primary' onClick={() => setIsViewMemberActivity(false)}>Đóng</Button>
                                        ]}
                                        width={1000}
                                    >
                                        <Table
                                            columns={listCheckInCol}
                                            dataSource={checkInList}
                                            pagination={{ hideOnSinglePage: true }}
                                            scroll={{ y: 1000, x: 800 }} />
                                    </Modal>
                                    <Modal title="Quét Qr-Code"
                                        style={{ top: 20 }}
                                        visible={isOpenAttendance}
                                        onCancel={() => setIsOpenAttendance(false)}
                                        footer={[
                                            <Button type='primary' onClick={() => window.location.reload()}>Hoàn Tất</Button>
                                        ]}
                                    >
                                        <BarcodeScannerComponent
                                            delay={500}
                                            onUpdate={handleScan}

                                        />
                                        {
                                            scanResultWebCam ?
                                                <Tag color="#108ee9" style={{ fontSize: 14 }}>{scanResultWebCam}</Tag>
                                                : null
                                        }
                                        <Search style={{ marginTop: 15 }} placeholder="Nhập mã sinh viên" onSearch={(value) => onSearchCheckIn(value)} enterButton="Điểm Danh" />
                                    </Modal>
                                    <Form form={form}>
                                        <Row span={24}>
                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="title"
                                                    label="Tiêu đề:"
                                                    disabled={isCreading}
                                                    rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                                                >
                                                    <Input maxLength={250} placeholder="Tiêu đề" onChange={(e) => { onChangeTitle(e) }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="timeline"
                                                    label="Thời gian:"
                                                    disabled={isCreading}
                                                    rules={[{ required: true, message: 'Vui lòng nhập thời gian tuyển!' }]}
                                                >
                                                    <RangePicker
                                                        showTime
                                                        format={dateFormat}
                                                        disabledDate={disabledDate}
                                                        onChange={(e) => { onChangeDate(e) }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="deadline"
                                                    label="Hạn cuối nhận hồ sơ:"
                                                    disabled={isCreading}
                                                    rules={[{ required: true, message: 'Vui lòng nhập hạn cuối nhận hồ sơ!' }]}
                                                >
                                                    <DatePicker
                                                        showTime
                                                        format={dateFormat}
                                                        disabledDate={disabledDate}
                                                        onChange={(e) => { onChangeDate(e) }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                <Form.Item
                                                    name="poster"
                                                    label="Poster:"
                                                    disabled={isCreading}
                                                    rules={[{ required: true, message: 'Vui lòng tải lên ảnh!' }]}
                                                >
                                                    <ImgCrop aspect={16 / 9} cropperProps={"posterCrop"}
                                                        modalTitle="Cắt ảnh"
                                                        modalOk={"Lưu"}
                                                        modalCancel={"Huỷ"}>
                                                        <Upload
                                                            accept="image/*"
                                                            onChange={handleOnChange}
                                                            onPreview={onPreview}
                                                            customRequest={dummyRequest}
                                                            defaultFileList={defaultFileList}
                                                            className="upload-list-inline"
                                                            listType="picture"
                                                            maxCount={1}
                                                        >
                                                            {
                                                                defaultFileList.length < 1 && '+ Tải lên'
                                                            }
                                                        </Upload>
                                                    </ImgCrop>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="province"
                                                    label="Tỉnh/Thành phố:"
                                                    rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/Thành phố!' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={isCreading}
                                                        className="container text-left"
                                                        placeholder="Chọn Tỉnh/Thành phố"
                                                        optionFilterProp="children"
                                                        filterOption={
                                                            (input, option) =>
                                                                RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                        }
                                                        filterSort={
                                                            (optionA, optionB) =>
                                                                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                        }
                                                        value={provinceId}
                                                        onChange={handleChangeProvince}
                                                    >
                                                        {provinceList != null ? provinceList.map((province) => (
                                                            <option value={province.provinceId} >{province.provinceName}</option>
                                                        )) : <Option value="chon">Chọn Tỉnh/Thành phố</Option>}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="district"
                                                    label="Quận/Huyện:"
                                                    rules={[{ required: true, message: 'Vui lòng chọn Quận/Huyện!' }]}
                                                >
                                                    <Select
                                                        disabled={isCreading}
                                                        showSearch
                                                        className="container text-left"
                                                        placeholder="Chọn quận/huyện"
                                                        optionFilterProp="children"
                                                        filterOption={
                                                            (input, option) =>
                                                                RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                        }
                                                        filterSort={
                                                            (optionA, optionB) =>
                                                                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                        }
                                                        defaultValue={districtId}
                                                        onChange={handleChangeDistrict}
                                                    >
                                                        {districtList != null ?
                                                            districtList.map((district) => (
                                                                <option value={district.districtId} >{district.districtName}</option>
                                                            )) : <Option value="chon">Chọn Quận/Huyện</Option>}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="ward"
                                                    label="Xã/Phường:"
                                                    rules={[{ required: true, message: 'Vui lòng chọn Xã/Phường!' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={isCreading}
                                                        className="container text-left"
                                                        placeholder="Chọn Xã/Phường"
                                                        optionFilterProp="children"
                                                        filterOption={
                                                            (input, option) =>
                                                                RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                        }
                                                        filterSort={
                                                            (optionA, optionB) =>
                                                                optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                        }
                                                        defaultValue={wardId}
                                                        onChange={handleChangeWard}
                                                    >
                                                        {wardList != null ?
                                                            wardList.map((ward) => (
                                                                <option value={ward.wardId} >{ward.wardName}</option>
                                                            )) : <Option value="chon">Chọn Xã/Phường</Option>}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                <Form.Item
                                                    name="street"
                                                    rules={[{ required: true, message: 'Vui lòng nhập Đường!' }]}
                                                    label="Số nhà:"
                                                >
                                                    <Input disabled={isCreading} rows={2} placeholder="Nhập số nhà" maxLength={40} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row justify="start">
                                            <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                <Form.Item
                                                    name="type"
                                                    label="Loại:"
                                                    rules={[{ required: true, message: 'Vui lòng chọn loại hoạt động!' }]}
                                                    disabled={isCreading}
                                                >
                                                    <Switch
                                                        defaultChecked={isPublic}
                                                        title='Hoạt động nội bộ'
                                                        style={{ float: "left" }}
                                                        onChange={(e) => { setIsPublic(e) }}
                                                        checkedChildren={"Hoạt động nội bộ"}
                                                        unCheckedChildren={"Hoạt động công khai"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Row>
                                        <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                            <div className="ant-row ant-form ant-form-horizontal">
                                                <div className="ant-col ant-form-item-label">
                                                    <label className="ant-form-item-required" >Nội dung</label>
                                                </div>
                                            </div>
                                            <ReactQuill
                                                placeholder="Nội dung..."
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                value={value}
                                                onChange={handleChange}
                                            />
                                            {
                                                contentNull ?
                                                    <div class="ant-form-item-explain ant-form-item-explain-error">
                                                        <div role="alert">Vui lòng nhập nội dung!</div>
                                                    </div>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        roleCreateActivity.includes(roleId) ?
                                            <Button
                                                style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                                disabled={value === "<p><br></p>" || value === ""}
                                                onClick={() => { setIsShowPreview(!isShowPreview) }}
                                                icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faEye} />}
                                                type="primary">Xem Trước
                                            </Button>
                                            : null
                                    }
                                    {
                                        roleCreateActivity.includes(roleId) ?
                                            <Button
                                                style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                                icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faSave} />}
                                                onClick={() => { confirmSave() }}
                                                loading={loading}
                                                disabled={isChange}
                                                type="primary">Cập Nhật
                                            </Button>
                                            : null
                                    }
                                    {
                                        roleSupport.includes(roleId) ?
                                            <Button
                                                style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                                icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faParachuteBox} />}
                                                onClick={() => { setIsShowSupporter(true) }}
                                                loading={loading}
                                                disabled={isChange}
                                                type="primary">Hỗ Trợ BCN
                                            </Button>
                                            : null
                                    }
                                    {
                                        roleCreateActivity.includes(roleId) ?
                                            <Button
                                                style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                                icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faMedal} />}
                                                onClick={() => { setIsShowCertificateModal(true) }}
                                                type="primary">Gửi Chứng Nhận
                                            </Button>
                                            : null
                                    }
                                    {
                                        roleCreateActivity.includes(roleId) ?
                                            <Button
                                                style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                                icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faTrash} />}
                                                danger
                                                onClick={() => { confirm() }}
                                                loading={loading}
                                                disabled={isChange}
                                                type="primary">Xoá
                                            </Button>
                                            : null
                                    }
                                    <Button
                                        style={{ marginTop: 15, marginLeft: 15, marginRight: 15 }}
                                        icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faQrcode} />}
                                        type="primary" onClick={() => setIsOpenAttendance(true)}>Điểm Danh
                                    </Button>
                                    {
                                        isShowPreview ?
                                            <div>
                                                <hr />
                                                <Preview value={value} />
                                            </div>
                                            : null
                                    }
                                    <div>
                                        <hr />
                                        <Tabs defaultActiveKey="1" className="container">
                                            <TabPane tab="Đăng Ký Tham Gia" key="1">
                                                <h3>Danh sách đăng ký tham gia ({countCheckIn}/{registerList.length})</h3>
                                                <Table
                                                    key={registerList.id}
                                                    columns={columns}
                                                    dataSource={registerList}
                                                    pagination={{
                                                        onChange: (page, pageSize) => {
                                                            pagination(page, pageSize);
                                                        },
                                                        current: page,
                                                        pageSize: pageSize,
                                                        total: registerList.length
                                                    }}
                                                    scroll={{ x: 800 }} />
                                            </TabPane>
                                            <TabPane tab="Xin Phép Vắng" key="2">
                                                <h3>Danh sách xin vắng ({leaveRegistration.length})</h3>
                                                <Table
                                                    key={leaveRegistration.id}
                                                    columns={leaveColumns}
                                                    dataSource={leaveRegistration}
                                                    pagination={{
                                                        onChange: (page, pageSize) => {
                                                            leavePagination(page, pageSize);
                                                        },
                                                        current: leavePage,
                                                        pageSize: leavePageSize,
                                                        total: leaveRegistration.length
                                                    }}
                                                    scroll={{ x: 1000 }} />
                                            </TabPane>
                                        </Tabs>
                                        {
                                            registerList || leaveRegistration ?
                                                <div>
                                                    {
                                                        roleSupport.includes(roleId) ?
                                                            <Button
                                                                loading={isDownload}
                                                                style={{ margin: 15 }}
                                                                onClick={() => DownloadListJoinActivity()}
                                                                type='primary'>Tải Danh Danh Sách</Button>
                                                            : null
                                                    }
                                                    <Modal
                                                        title="Gửi thông báo"
                                                        visible={isShowModalSend}
                                                        onOk={() => SendMail()}
                                                        onCancel={() => setIsShowModalSend(false)}
                                                        footer={[
                                                            <Button key="back" onClick={() => setIsShowModalSend(false)} loading={isSending}>
                                                                Huỷ
                                                            </Button>,
                                                            <Button key="submit" type="primary" loading={isSending} onClick={() => SendMail()}>
                                                                Gửi
                                                            </Button>
                                                        ]}>
                                                        <p>Bạn vừa thay đổi thông tin của hoạt động. Có lẽ rất nhiều CTV không biết sự thay đổi này. Bạn có muốn gửi email thông báo cho họ biết về sự thay đổi này không?</p>
                                                        <Form form={sendemailform}>
                                                            <Form.Item
                                                                name="mailcontent"
                                                                label="Nội dung:"
                                                                disabled={isSending}
                                                                rules={[{ required: true, message: 'Vui lòng nhập nội dung bạn muốn gửi!' }]}
                                                            >
                                                                <TextArea rows={4} placeholder="Nhập các thay đổi bạn muốn thông báo..." />
                                                            </Form.Item>
                                                        </Form>
                                                    </Modal>
                                                    <Modal
                                                        title="Người hỗ trợ"
                                                        centered
                                                        visible={isShowSupporter}
                                                        onCancel={() => setIsShowSupporter(false)}
                                                        footer={[
                                                            <Button key="back" onClick={() => setIsShowSupporter(false)}>
                                                                Đóng
                                                            </Button>
                                                        ]}
                                                        width={1000}
                                                        style={{ top: 10 }}
                                                    >
                                                        <Row>
                                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                <Form form={supporter}>
                                                                    <Form.Item
                                                                        name="studentIdSP"
                                                                        label="MSSV:"
                                                                        disabled={isCreading}
                                                                        rules={[{ required: true, message: 'Vui lòng nhập MSSV!' }]}
                                                                    >
                                                                        <Input maxLength={250} placeholder="Mã số sinh viên" />
                                                                    </Form.Item>
                                                                </Form>
                                                            </Col>
                                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                <Button
                                                                    type='primary'
                                                                    icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faUserPlus} />}
                                                                    onClick={() => addSupporter()}
                                                                    block>Thêm</Button>
                                                            </Col>
                                                        </Row>
                                                        <Table
                                                            key={supporterList ? supporterList.id : []}
                                                            columns={supporterColumns}
                                                            dataSource={supporterList ? supporterList : []}
                                                            pagination={{
                                                                onChange: (page, pageSize) => {
                                                                    paginationSP(page, pageSize);
                                                                },
                                                                current: pageSP,
                                                                pageSize: pageSizeSP,
                                                                total: supporterList ? supporterList.length : 0
                                                            }}
                                                            style={{ margin: 15 }}
                                                            scroll={{ x: 900, y: 200 }} />
                                                    </Modal>
                                                    <Modal
                                                        visible={isChangeCheckIn}
                                                        onOk={changeCheckIn}
                                                        onCancel={cancelModal}
                                                        centered
                                                        title={"Cập nhật thời gian điểm danh"}
                                                        footer={[
                                                            <Button disabled={isLoadingChange} key="back" onClick={cancelModal}>
                                                                Quay lại
                                                            </Button>,
                                                            <Button key="submit" type="primary" loading={isLoadingChange} onClick={onChangeCheckIn}>
                                                                Cập nhật
                                                            </Button>
                                                        ]}>
                                                        <Form form={formChange}>
                                                            <Form.Item name="changeDate"
                                                                style={{ textAlign: "center" }}
                                                                rules={[{ required: true, message: 'Vui lòng chọn ngày tháng!' }]}>
                                                                <RangePicker
                                                                    format={dateFormat}
                                                                    showTime
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="isSetFullList"
                                                            >
                                                                <Checkbox defaultChecked={isCheckAll} onChange={(e) => onChangeCheckBox(e)}>Áp dụng thời gian ra cho toàn bộ danh sách.</Checkbox>
                                                            </Form.Item>
                                                        </Form>
                                                    </Modal>
                                                    {/* Show modal send chứng nhận */}
                                                    <Modal
                                                        visible={isShowCertificateModal}
                                                        onOk={() => setIsShowCertificateModal(false)}
                                                        onCancel={() => setIsShowCertificateModal(false)}
                                                        centered
                                                        title={"Gửi giấy chứng nhận Online"}
                                                        footer={[
                                                            <Button disabled={isLoadingChange} key="back" onClick={() => {
                                                                setIsShowCertificateModal(false);
                                                            }}
                                                                loading={sending}>
                                                                Quay lại
                                                            </Button>,
                                                            <Button key="submit" type="primary"
                                                                loading={sending}
                                                                onClick={() => {
                                                                    setIsShowCertificateModal(false);
                                                                    setSending(true);
                                                                    onSendMail();
                                                                }}>
                                                                Gửi
                                                            </Button>
                                                        ]}>
                                                        {
                                                            certificatePath ?
                                                                <div className='container'>
                                                                    <Image
                                                                        width={"100%"}
                                                                        key={timestamp}
                                                                        src={certificatePath}
                                                                    />
                                                                    <Button type='primary' onClick={() => {
                                                                        deleteFile();
                                                                    }}>Xoá ảnh</Button>
                                                                </div>
                                                                :
                                                                <Upload
                                                                    accept="image/*"
                                                                    onChange={handleOnChangeCertificate}
                                                                    onPreview={onPreviewCertificate}
                                                                    customRequest={uploadImage}
                                                                    defaultFileList={defaultFileListCertificate}
                                                                    className="upload-list-inline"
                                                                    listType="picture-card"
                                                                    maxCount={1}
                                                                >
                                                                    {
                                                                        defaultFileListCertificate.length < 1 && '+ Tải lên'
                                                                    }
                                                                </Upload>
                                                        }
                                                        <Form form={formChangeCertificate}>
                                                            <Row>
                                                                <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        style={{ marginTop: 15 }}
                                                                        label="Toạ độ tên"
                                                                        name={"locationY"}
                                                                        rules={[{ required: true, message: 'Vui lòng nhập toạ độ tên!' }]}
                                                                    >
                                                                        <Input defaultValue={520} onChange={() => onChangeLocationOrSize()} type={"number"} min={0} placeholder="Toạ độ tên"></Input>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        style={{ marginTop: 15 }}
                                                                        label="Cỡ chữ"
                                                                        name={"fontsize"}
                                                                        rules={[{ required: true, message: 'Vui lòng nhập cỡ chữ!' }]}
                                                                    >
                                                                        <Input defaultValue={52} onChange={() => onChangeLocationOrSize()} type={"number"} min={0} placeholder="Cỡ chữ"></Input>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Modal>
                                                    {/* Show change Member */}
                                                    <Modal
                                                        visible={isShowChangeMember}
                                                        onOk={changeCheckIn}
                                                        onCancel={() => setIsShowChangeMember(false)}
                                                        centered
                                                        title={"Cập nhật thông tin thành viên"}
                                                        footer={[
                                                            <Button disabled={registing} key="back" onClick={() => setIsShowChangeMember(false)}>
                                                                Đóng
                                                            </Button>,
                                                            <Button key="submit" type="primary" loading={registing} onClick={() => onChangeMember()}>
                                                                Cập nhật
                                                            </Button>
                                                        ]}
                                                        width={1000}
                                                    >
                                                        <Form form={formChangeMember}>
                                                            <Row>
                                                                <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        name="firstname"
                                                                        label="Họ & tên đệm:"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Vui lòng nhập họ & tên đệm!',
                                                                        }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập họ và tên đệm" maxLength={30} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        name="lastname"
                                                                        label="Tên:"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Vui lòng nhập tên!',
                                                                        }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập Tên" maxLength={7} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        name="phonenumber"
                                                                        label="Số điện thoại:"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Vui lòng nhập số điện thoại!',
                                                                        }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập số điện thoại" maxLength={15} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        name="email"
                                                                        label="Email:"
                                                                        rules={[{ required: true, message: 'Vui lòng nhập email!' }, { type: "email", message: 'Email không hợp lệ!' }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập Email" maxLength={100} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                    <Form.Item
                                                                        name="studentid"
                                                                        label="Mã số sinh viên:"
                                                                        rules={[{ required: true, message: 'Vui lòng nhập mã sinh viên!' }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập mã sinh viên" maxLength={15} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                    {/* Mã lớp */}
                                                                    <Form.Item
                                                                        name="classname"
                                                                        label="Lớp:"
                                                                        rules={[{ required: true, message: 'Vui lòng nhập tên lớp!' }]}
                                                                    >
                                                                        <Input disabled={registing} placeholder="Nhập tên lớp" maxLength={15} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Modal>
                                                    {
                                                        currentMember ?
                                                            <Modal
                                                                visible={isShowAddScore}
                                                                onOk={addScore}
                                                                onCancel={() => setIsShowAddScore(false)}
                                                                centered
                                                                title={"Thêm điểm cộng"}
                                                                footer={[
                                                                    <Button disabled={registing} key="back" onClick={() => setIsShowAddScore(false)}>
                                                                        Đóng
                                                                    </Button>,
                                                                    <Button key="submit" type="primary" loading={registing} onClick={() => addScore()}>
                                                                        Cập nhật
                                                                    </Button>
                                                                ]}
                                                            >
                                                                <Form form={formAddScore}>
                                                                    <Row>
                                                                        <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                                            <Form.Item
                                                                                name="fullname"
                                                                                label="Họ & tên:"
                                                                            >
                                                                                <Input disabled={true} defaultValue={currentMember.firstName + ' ' + currentMember.lastName} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                                            <Form.Item
                                                                                name="score"
                                                                                label="Điểm:"
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: 'Vui lòng nhập điểm!',
                                                                                }]}
                                                                            >
                                                                                <InputNumber disabled={registing} step={0.1} placeholder="Nhập điểm" min={0.1} max={24.0} style={{ width: '100%' }} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                                            <Form.Item
                                                                                name="reason"
                                                                                label="Lý do:"
                                                                                rules={[{
                                                                                    required: true,
                                                                                    message: 'Vui lòng nhập lý do!',
                                                                                }]}
                                                                            >
                                                                                <Input disabled={registing} placeholder="Nhập lý do" maxLength={64} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Modal>
                                                            : null
                                                    }

                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                :
                                <Result
                                    status={"500"}
                                    title={"Hoạt động không tồn tại!"}
                                    subTitle={"Không có hoạt động nào!"} />
                        }
                    </div>
            }
        </div>
    )
};