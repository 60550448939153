import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {Modal, Layout, Result, message, DatePicker, Row, Col, Input, Form, Button, Skeleton, Radio, Select, Checkbox} from 'antd';
import axios from 'axios';
import './css/memberrigister.css';
import moment from 'moment';
import Moment from 'react-moment';
import './css/firstsignin.css';
import Sliderbar from './Sliderbar';


function Recruitment(){
    const { Footer } = Layout;
    const { Option } = Select;
    const TITLE = "Tuyển Thành Viên";
    const [isRecruitValid, setIsRecryitValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [provinceList, setProvinceList] = useState(null);
    const [provinceId, setProvinceId] = useState(null);
    const [districtList, setDistrictList] = useState(null);
    const [districtId, setDistrictId] = useState(null);
    const [wardList, setWardList] = useState(null);
    const [wardId, setWardId] = useState(null);
    const [modaform] = Form.useForm();
    const [facultys, setFacultys] = useState(null);
    const [registing, setRegisting] = useState(false);
    const [readingTime, setReadingTime] = useState(null);
    const [bIsShowModal, setIsShowModal] = useState(false);
    const [isAssure, setIsAssure] = useState(false);
    const [isAccept, setIsAccept] = useState(false);
    const [inputIsNull, setInputIsNull] = useState(true);
    const [isSuccsess, setIsSuccsess] = useState(false);
    const [email, setEmail] = useState(null);
    const [noiquy, setNoiQuy] = useState(null);
    const [bShowCheckBox, setShowCheckBox] = useState(true);
    const { TextArea } = Input;

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
     }

    const getRecruitment = async() =>{
        setLoading(true);
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        axios.get(serverAPIIp + 'api/Recruit/GetValidRecruitment', {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setData(response.data.responseData);
                getProvince();
                getFaculty();
                setLoading(false);
                setIsRecryitValid(true);
            }else{
                setIsRecryitValid(false);
                setLoading(false);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setIsRecryitValid(false);
            setLoading(false);
        })
    }

    function getProvince(){
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        axios.get(serverAPIIp + 'api/Address/GetProvinces', {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setProvinceList(response.data.responseData);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ!");
        })
    }

    const getDistrict = async(proviceId) =>{
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        await axios.get(serverAPIIp + 'api/Address/GetDistricts', {params: {
            provinceId: proviceId
          }
        }, {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setDistrictList(response.data.responseData);
            }else{
                message.error(response.data.errors[0]);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
        })
    }

    const getWards = async(districtId) =>{
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        await axios.get(serverAPIIp + 'api/Address/GetWards', {params: {
            districtId: districtId
          }
        }, {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setWardList(response.data.responseData);
            }else{
                message.error(response.data.errors[0]);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
        })
    }

    const handleChangeProvince = e => {
        modaform.setFieldsValue({district: undefined});
        modaform.setFieldsValue({ward: undefined});
        setProvinceId(e);
        getDistrict(e);
        onChangeInput();
    };

    const handleChangeDistrict = e => {
        modaform.setFieldsValue({ward: undefined});
        setDistrictId(e);
        getWards(e);
        onChangeInput();
    };

    const handleChangeWard = e => {
        setWardId(e);
        onChangeInput();
    };

    const handleChangeFaculy = e => {
        modaform.setFieldsValue({faculty: e});
        onChangeInput();
    }

    function getFaculty(){
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        axios.get(serverAPIIp + 'api/Faculty/GetFacultys', {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setFacultys(response.data.responseData);
            }
        })
        .catch((response)=>{
        })
    }

    const onRegisting = () =>{
        setRegisting(true);
        var isValid = true;
        var firstname = modaform.getFieldValue("firstname");
        var lastname = modaform.getFieldValue("lastname");
        var birthday = modaform.getFieldValue("birthday");
        var membersex = modaform.getFieldValue("membersex");
        var province = modaform.getFieldValue("province");
        var district = modaform.getFieldValue("district");
        var ward = modaform.getFieldValue("ward");
        var street = modaform.getFieldValue("street");
        var phonenumber = modaform.getFieldValue("phonenumber");
        var email = modaform.getFieldValue("email");
        var facebookpath = modaform.getFieldValue("facebookpath");
        var studentid = modaform.getFieldValue("studentid");
        var faculty = modaform.getFieldValue("faculty");
        var classname = modaform.getFieldValue("classname");
        var reason = modaform.getFieldValue("reason");

        if(firstname === undefined || firstname.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "firstname",
             errors:["Vui lòng nhập họ và tên đệm"]
         }]);
        }
        if(lastname === undefined || lastname.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "lastname",
             errors:["Vui lòng nhập tên"]
         }]);
        }

        if(classname === undefined || classname.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "classname",
             errors:["Vui lòng nhập lớp"]
         }]);
        }

        if(studentid === undefined || studentid.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "studentid",
             errors:["Vui lòng nhập mã sinh viên"]
         }]);
        }
        if(phonenumber === undefined || phonenumber.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "phonenumber",
             errors:["Vui lòng nhập số điện thoại"]
         }]);
        }
        if(street === undefined || street.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "street",
             errors:["Vui lòng nhập số nhà"]
         }]);
        }
        if(email === undefined || email.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "email",
             errors:["Vui lòng nhập email"]
         }]);
        }
        if(facebookpath === undefined || facebookpath.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "facebookpath",
             errors:["Vui lòng nhập link Facebook"]
         }]);
        }

        if(!isAssure){
            modaform.setFields([{
             name: "isassure",
             errors:["Bạn phải cam đoan là thông tin chính xác"]
         }]);
        }

        if(!isAccept){
            modaform.setFields([{
             name: "isaccept",
             errors:["Bạn phải đồng ý tuân thủ nội quy CLB"]
         }]);
        }

        if(birthday === undefined){
            modaform.setFields([{
                name: "birthday",
                errors:["Vui lòng nhập ngày sinh"]
            }]);
        }

        if(membersex === undefined){
            modaform.setFields([{
                name: "membersex",
                errors:["Vui lòng chọn giới tính"]
            }]);
        }

        if(province === undefined){
            modaform.setFields([{
                name: "province",
                errors:["Vui lòng chọn Tỉnh/Thành"]
            }]);
        }

        if(district === undefined){
            modaform.setFields([{
                name: "district",
                errors:["Vui lòng chọn Quận/Huyện"]
            }]);
        }

        if(ward === undefined){
            modaform.setFields([{
                name: "ward",
                errors:["Vui lòng chọn Xã/Phường"]
            }]);
        }

        if(faculty === undefined){
            modaform.setFields([{
                name: "faculty",
                errors:["Vui lòng chọn Khoa/Viện chủ quản"]
            }]);
        }

        var errorList = modaform.getFieldsError();

        errorList.forEach((error) =>{
            if(error.errors.length > 0){
                isValid = false;
            }
        })

        if(data.id === null
            || data.id === undefined
            || data.id === ''){
                isValid = false;
        }
        
        if(reason === undefined || reason.replace(/ /g, '') === ''){
            modaform.setFields([{
             name: "reason",
             errors:["Vui lòng nhập lý do bạn muốn tham gia CLB"]
         }]);
        }

        const timeEnd = moment(new Date());
        const diff = timeEnd.diff(moment(readingTime));
        const diffDuration = moment.duration(diff);
        // if(diffDuration.seconds() < 60 && diffDuration.minutes() < 1 && diffDuration.hours() < 1 && diffDuration.days() < 1){
        //     error(diffDuration.seconds().toString());
        //     setRegisting(false);
        // }else{
            
        // }
        if(isValid){
            const memberRegisterRequestModel = {
                'lastName': firstname,
                'firstName': lastname,
                'birthDay': birthday.toISOString(),
                'sex':  membersex === 1,
                'phoneNumber': phonenumber,
                'email': email,
                'linkFacebook': facebookpath,
                'facultyId': faculty,
                'className': classname,
                'studentId': studentid,
                'provinceId': provinceId,
                'districtId': districtId,
                'wardId': wardId,
                'streesNamed': street,
                'regulatoryCompliance': isAccept,
                'verifiedInformation': isAssure,
                'recruitId': data.id,
                'reason' : reason
            };
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*"
            }
    
            axios.post(serverAPIIp + 'api/Recruit/MemberRegister', JSON.stringify(memberRegisterRequestModel), { headers })
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Đăng ký thành công");
                        setEmail(email);
                        setIsSuccsess(true);
                    }else{
                        message.error(response.data.errors);
                    }
                    setRegisting(false);
                })
                .catch((response)=>{
                    message.error("Mất kết nối với máy chủ");
                    setRegisting(false);
                });
        }else{
            setRegisting(false);
        }
    }

    function error(time) {
        var cnt = 'Chúng ta hãy thẳng thắn với nhau rằng bạn không thể đọc hết hơn 4 trang nội quy trong '.concat(time, ' giây được. Vậy nên bạn hãy dành ít thời gian để đọc thật kỹ trước khi đồng ý tuân thủ nội quy nhé');
        setIsAccept(false);
        Modal.error({
          title: 'Hãy đọc nội quy!',
          content: cnt,
        });
    }

    function AssureChange(e){
        setIsAssure(e.target.checked);
    }

    function AcceptChange(e){
        setIsAccept(e.target.checked);
    }

    function setOkOnclick(){
        setIsShowModal(false); 
        setIsAccept(true); 
        setShowCheckBox(false);
    }

    function onChangeInput(){
        var firstname = modaform.getFieldValue("firstname");
        var lastname = modaform.getFieldValue("lastname");
        var birthday = modaform.getFieldValue("birthday");
        var membersex = modaform.getFieldValue("membersex");
        var province = modaform.getFieldValue("province");
        var district = modaform.getFieldValue("district");
        var ward = modaform.getFieldValue("ward");
        var street = modaform.getFieldValue("street");
        var phonenumber = modaform.getFieldValue("phonenumber");
        var email = modaform.getFieldValue("email");
        var facebookpath = modaform.getFieldValue("facebookpath");
        var studentid = modaform.getFieldValue("studentid");
        var faculty = modaform.getFieldValue("faculty");
        var classname = modaform.getFieldValue("classname");
        setInputIsNull(
            firstname === null || firstname === undefined || firstname === ''
            || lastname === null || lastname === undefined || lastname === ''
            || birthday === null || birthday === undefined || birthday === ''
            || membersex === null || membersex === undefined || membersex === ''
            || province === null || province === undefined || province === ''
            || district === null || district === undefined || district === ''
            || ward === null || ward === undefined || ward === ''
            || street === null || street === undefined || street === ''
            || phonenumber === null || phonenumber === undefined || phonenumber === ''
            || email === null || email === undefined || email === ''
            || facebookpath === null || facebookpath === undefined || facebookpath === ''
            || studentid === null || studentid === undefined || studentid === ''
            || faculty === null || faculty === undefined || faculty === ''
            || classname === null || classname === undefined || classname === ''
        )
    }

    useEffect(() => {
        getRecruitment();
    }, []);
    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            {
                loading ? 
                <Skeleton active />
                :
                <div>
                    <Layout>
                        <Sliderbar/>
                            <div className="d-inline-flex justify-content-center">
                                <div className="recruitform">
                                    <div className="container card">
                                        {
                                            isRecruitValid?
                                            <div class="ql-container ql-snow" style={{background: "white", border: "white"}}>
                                                <div>
                                                <Modal
                                                    title="Nội quy"
                                                    visible={bIsShowModal}
                                                    width={1000}
                                                    bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
                                                    onOk={() => {setOkOnclick()}}
                                                    onCancel={() => setIsShowModal(false)}
                                                    footer={[
                                                        <Button key="back" onClick={() => setIsShowModal(false)}>
                                                        Thoát
                                                        </Button>,
                                                        <Button key="submit" type="primary" onClick={() => {setOkOnclick()}}>
                                                        Đồng ý
                                                        </Button>
                                                    ]}
                                                    centered
                                                >
                                                    <div class="ql-container ql-snow" style={{background: "white", border: "white"}}>
                                                        <div class="ql-editor" style={{marginTop: 20}} dangerouslySetInnerHTML={{ __html: data.noiQuy }} />
                                                    </div>
                                                </Modal>
                                                </div>
                                                <div class="ql-editor" style={{marginTop: 20}} dangerouslySetInnerHTML={{ __html: data.recruitContent }} />
                                                <hr/>
                                                <div className="values" style={{marginBottom: 15}}>
                                                    <div className="container box" style={{textAlign: "left"}}>
                                                        {
                                                            isSuccsess?
                                                            <Result
                                                                    status="success"
                                                                    title="Đăng ký thành công!"
                                                                    subTitle={"Chúc mừng bạn đã hoàn tất hồ sơ đăng ký Thành viên CLB Sinh viên Tình nguyện Đại học Duy Tân. Bạn hãy chú ý kiểm tra email thường xuyên nhé. Chúng tôi sẽ gửi thư mời phỏng vấn tới email: " + email + " trong thời gian sớm nhất."}
                                                                    extra={[
                                                                    <Button type="primary" key="console" onClick={() => {window.location.href="/";}}>
                                                                        Quay lại trang chủ
                                                                    </Button>
                                                                    ]}
                                                                />
                                                            :
                                                            <div>
                                                                <div style={{textAlign: "center"}}>
                                                                    <h2>
                                                                        Đăng ký thành viên
                                                                    </h2>
                                                                </div>
                                                                <h6 style={{marginBottom: 25}}>Nhận hồ sơ từ: <Moment style={{color:"red", fontWeight: 600 }} date={data.dateStart} format="hh:mm a DD-MM-YYYY"/> đến: <Moment style={{color:"red", fontWeight: 600 }} date={data.dateEnd} format="hh:mm a DD-MM-YYYY"/></h6>
                                                                <Form 
                                                                    form={modaform}>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            <Form.Item 
                                                                                name="firstname"
                                                                                label="Họ & tên đệm:"
                                                                                rules={[{ 
                                                                                    required: true, 
                                                                                    message: 'Vui lòng nhập họ & tên đệm!',
                                                                                    }]}
                                                                                >
                                                                                    <Input onChange={(e) => {onChangeInput()}} disabled={registing} placeholder="Nhập họ và tên đệm" maxLength={30}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            {/* Tên thành viên */}
                                                                            <Form.Item
                                                                                name="lastname"
                                                                                label="Tên:"
                                                                                rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                                                                            >
                                                                                <Input onChange={(e) => {onChangeInput()}} disabled={registing} placeholder="Nhập tên" maxLength={7}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            {/* Ngày sinh thành viên */}
                                                                            <Form.Item
                                                                                name="birthday"
                                                                                label="Ngày sinh:"
                                                                                rules={[{ required: true, message: 'Vui lòng nhập ngày sinh!' }]}
                                                                            >
                                                                                <DatePicker onChange={(e) => {onChangeInput()}} disabled={registing} className={"container"} showToday={false} placeholder="Chọn ngày"/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            {/* Giới tính thành viên */}
                                                                            <Form.Item
                                                                                name="membersex"
                                                                                label="Giới tính:"
                                                                                rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
                                                                            >
                                                                                <Radio.Group onChange={(e) => {onChangeInput()}} >
                                                                                    <Radio disabled={registing} value={1}>Nam</Radio>
                                                                                    <Radio disabled={registing} value={2}>Nữ</Radio>
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            <Form.Item
                                                                                name="province"
                                                                                label="Tỉnh/Thành phố:"
                                                                                rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/Thành phố!' }]}
                                                                            >
                                                                                <Select
                                                                                    showSearch
                                                                                    disabled={registing}
                                                                                    className="container text-left"
                                                                                    placeholder="Chọn Tỉnh/Thành phố"
                                                                                    optionFilterProp="children"
                                                                                    filterOption={
                                                                                        (input, option) =>
                                                                                        RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                                                    }
                                                                                    filterSort={
                                                                                        (optionA, optionB) =>
                                                                                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                                    }
                                                                                    value={provinceId}
                                                                                    onChange={handleChangeProvince}
                                                                                    >
                                                                                        {provinceList !=null?provinceList.map((province) => (
                                                                                            <option value={province.provinceId} >{province.provinceName}</option>
                                                                                        )): <Option value="chon">Chọn Tỉnh/Thành phố</Option>}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            <Form.Item
                                                                            name="district"
                                                                            label="Quận/Huyện:"
                                                                            rules={[{ required: true, message: 'Vui lòng chọn Quận/Huyện!' }]}
                                                                            >
                                                                                <Select
                                                                                    disabled={registing}
                                                                                    showSearch
                                                                                    className="container text-left"
                                                                                    placeholder="Chọn quận/huyện"
                                                                                    optionFilterProp="children"
                                                                                    filterOption={
                                                                                        (input, option) =>
                                                                                        RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                                                    }
                                                                                    filterSort={
                                                                                        (optionA, optionB) =>
                                                                                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                                    }
                                                                                    defaultValue={districtId}
                                                                                    onChange={handleChangeDistrict}
                                                                                    >
                                                                                        {districtList != null?
                                                                                        districtList.map((district) => (
                                                                                                <option value={district.districtId} >{district.districtName}</option>
                                                                                        )): <Option value="chon">Chọn Quận/Huyện</Option>}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            <Form.Item
                                                                            name="ward"
                                                                            label="Xã/Phường:"
                                                                            rules={[{ required: true, message: 'Vui lòng chọn Xã/Phường!' }]}
                                                                            >
                                                                                <Select
                                                                                    showSearch
                                                                                    disabled={registing}
                                                                                    className="container text-left"
                                                                                    placeholder="Chọn Xã/Phường"
                                                                                    optionFilterProp="children"
                                                                                    filterOption={
                                                                                        (input, option) =>
                                                                                        RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                                                    }
                                                                                    filterSort={
                                                                                        (optionA, optionB) =>
                                                                                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                                    }
                                                                                    defaultValue={wardId}
                                                                                    onChange={handleChangeWard}
                                                                                    >
                                                                                        {wardList != null?
                                                                                        wardList.map((ward) => (
                                                                                            <option value={ward.wardId} >{ward.wardName}</option>
                                                                                        )): <Option value="chon">Chọn Xã/Phường</Option>}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            <Form.Item
                                                                            name="street"
                                                                            rules={[{ required: true, message: 'Vui lòng nhập Đường!' }]}
                                                                            label="Số nhà:"
                                                                            >
                                                                                <Input onChange={(e) => {onChangeInput()}} disabled={registing} rows={2} placeholder="Nhập số nhà" maxLength={40}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                        {/* Số điện thoại thành viên */}
                                                                            <Form.Item
                                                                                name="phonenumber"
                                                                                label="Điện thoại:"
                                                                                rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
                                                                            >
                                                                                <Input onChange={(e) => {onChangeInput()}} disabled={registing} placeholder="Nhập số điện thoại" maxLength={15}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            {/* email thành viên */}
                                                                            <Form.Item
                                                                                name="email"
                                                                                label="Email:"
                                                                                rules={[{ required: true, message: 'Vui lòng nhập email!'}, {type:"email", message: 'Email không hợp lệ!'}]}
                                                                            >
                                                                                <Input onChange={(e) => {onChangeInput()}} disabled={registing} type="email" placeholder="Nhập email" maxLength={100}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            <Form.Item
                                                                                name="facebookpath"
                                                                                label="Facebook:"
                                                                                rules={[{ required: true, message: 'Vui lòng nhập facebook!' }, { type:"url", message: 'Link không hợp lệ!' }]}
                                                                            >
                                                                                <Input onChange={(e) => {onChangeInput()}} disabled={registing} placeholder="Nhập link Facebook" maxLength={150}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            <Form.Item 
                                                                                name="studentid"
                                                                                label="Mã số sinh viên:"
                                                                                rules={[{ 
                                                                                        required: true, 
                                                                                        message: 'Vui lòng nhập mã sinh viên!'
                                                                                    }]}
                                                                                >
                                                                                    <Input onChange={(e) => {onChangeInput()}} disabled={registing} placeholder="Nhập mã sinh viên" maxLength={15}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                            <Form.Item 
                                                                                name="faculty"
                                                                                label="Khoa/ viện:"
                                                                                rules={[{ 
                                                                                        required: true, 
                                                                                        message: 'Vui lòng chọn khoa viện!'
                                                                                    }]}
                                                                                    
                                                                                >
                                                                                <Select
                                                                                    disabled={registing}
                                                                                    showSearch
                                                                                    className="container text-left"
                                                                                    placeholder="Chọn khoa/viện"
                                                                                    onChange={handleChangeFaculy}
                                                                                    filterOption={
                                                                                        (input, option) =>
                                                                                        RemoveVietnameseAccents(option.children).toLowerCase().indexOf(RemoveVietnameseAccents(input).toLowerCase()) >= 0
                                                                                    }
                                                                                    filterSort={
                                                                                        (optionA, optionB) =>
                                                                                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                                                                                        }
                                                                                    >
                                                                                        {facultys != null ? facultys.map((facultyItem) => (
                                                                                            <option value={facultyItem.id} >{facultyItem.facultyName}</option>
                                                                                        )) : <Option value="chon">Chọn Khoa/Viện</Option>}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                            {/* Mã lớp */}
                                                                            <Form.Item
                                                                            name="classname"
                                                                            label="Lớp:"
                                                                            rules={[{ required: true, message: 'Vui lòng nhập tên lớp!' }]}
                                                                            >
                                                                                <Input 
                                                                                    onChange={(e) => {onChangeInput()}} 
                                                                                    disabled={registing} placeholder="Nhập tên lớp" maxLength={15}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                    <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                                            {/* Mã lớp */}
                                                                            <Form.Item
                                                                            name="reason"
                                                                            label="Lý do:"
                                                                            rules={[{ required: true, message: 'Vui lòng nhập lý do bạn muốn tham gia clb!' }]}
                                                                            >
                                                                                <TextArea rows={3} 
                                                                                    showCount maxLength={500}
                                                                                    disabled={registing} placeholder="Lý do bạn muốn tham gia CLB của chúng tôi"/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Form.Item
                                                                    name={"isassure"}
                                                                    >
                                                                        <Checkbox disabled={inputIsNull} onChange={(e) => {AssureChange(e)}}>Tôi xin cam đoan thông tin trên là hoàn toàn đúng. Nếu có bất kỳ sai sót nào tôi xin chịu trách nhiệm.</Checkbox>
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <p style={{marginTop: 15}}>Trước khi hoàn tất đăng ký, bạn hãy đọc qua nội quy của CLB <a style={{fontWeight: 600}} 
                                                                        onClick={() => {setIsShowModal(true)}}>tại đây</a></p>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name={"isaccept"}
                                                                    >
                                                                        <Checkbox checked={isAccept} onChange={(e) =>{AcceptChange(e)}} disabled={bShowCheckBox}>Nếu được tham gia vào CLB Sinh viên Tình nguyện ĐH Duy Tân. Tôi xin tuân thủ toàn bộ nội quy của CLB.</Checkbox>
                                                                    </Form.Item>
                                                                    <Button disabled={!isAccept || !isAssure} htmlType="submit" onClick={() => {onRegisting()}} loading={registing} block type='primary'>Đăng ký</Button>
                                                                </Form>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <Result
                                                status="403"
                                                title="Quay lại sau bạn nhé!"
                                                subTitle="Cám ơn bạn vì đã quan tâm tới chúng mình. Nhưng hiện tại không có đợt tuyển thành viên nào đang diễn ra cả!"
                                                // extra={<Button onClick={() => {window.location.href="/";}} type="primary">Về Trang Chủ</Button>}
                                            />
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </Layout>
                </div>
            }
        </>
    )
}
export default Recruitment;