import React, {useEffect, useState}  from 'react';
import { Layout, message, DatePicker, Row, Col, Form, Button, Skeleton } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import { ImageResize } from "./ImageResize";
import "react-quill/dist/quill.snow.css";
import Preview from "./Preview";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSave} from '@fortawesome/free-solid-svg-icons';
import './css/createrecuit.css';
import { Video } from "./quill-video-resize";
import "./css/quill-video-resize.css";
import Moment from 'react-moment';


Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register({ "formats/video": Video });
const cookies = new Cookies();
const serverAPIIp = 'https://api.dtusvc.com/'; 
const TITLE = "Giới Thiệu CLB";
const { Content, Footer, Header } = Layout;
var upLoadImages = [];
var addImage = false;

function removeCookie(){
    cookies.remove('dtu-svc-fullname');
    cookies.remove('dtu-svc-accountId');
    cookies.remove('dtu-svc-avatarPath');
    cookies.remove('dtu-svc-token');
    cookies.remove('dtu-svc-roleId');
    cookies.remove('dtu-svc-role');
    cookies.remove('dtu-svc-studentId');
    window.location.href="/";
}

const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
  
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [{ direction: "rtl" }],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      matchVisual: false
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: false
    },
    imageUploader: {
      upload: async (file) => {
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
          headers: { 
              'content-type': 'multipart/form-data', 
              'Authorization': 'Bearer ' + token, 
              'accept': '*/*'
            }
        };
        fmData.append('image', file);
        try{
            const res = await axios.post(serverAPIIp + 'api/Newspaper/UploadImage', fmData, config );
            if(res.data.isSuccess){
                addImage = true;
                var images = upLoadImages;
                images.push(res.data.responseData);
                upLoadImages = images;
                return serverAPIIp  + res.data.responseData;
            }else{
                if(res.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(res.data.errors[0]);
                return null;
            }
        }catch (err) {
            if(err.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            return null;
        }
      }
    },
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white"
      },
      modules: ["Resize", "DisplaySize", "Toolbar"]
    }
};

const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "code-block",
        "color",
        "background",
        "font",
        "align",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "clean",
        "code",
        "formula"
];

function removeImage(imagepath){
    const token = cookies.get('dtu-svc-token');
    if(token !== null){
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        const removeImageModel = {
            "imagePath": imagepath
        }
        axios.post(serverAPIIp + 'api/Newspaper/RemoveImage', JSON.stringify(removeImageModel), {headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
            }
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối máy chủ");
            }
        })
    }
}

export default function CreateContact(){
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const [contentNull, setContentNull] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [isCreading, setIsCreading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [idNewspaper, setIdNewspaper] = useState(null);
    const [createDate, setCreateDate] = useState(null);
    const [createName, setCreateName] = useState(null);
    const handleChange = (data) => {
        setValue(data);
        var images = []
        upLoadImages.forEach((image) =>{
            if(data.indexOf(serverAPIIp + image) < 0 && !addImage){
                removeImage(image);
            }else{
                images.push(image);
            }
        })
        upLoadImages = images;
        addImage = false;
        setContentNull(data === "<p><br></p>");
    };

    function saveNewspaper(){
        setIsCreading(true);
        var valid = true;
        if(value === null || value === "<p><br></p>" || value === ""){
            valid = false;
            setContentNull(true);
        }
        if(valid){
            const token = cookies.get('dtu-svc-token');
            if(token !== null && valid){
                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                const createQrRequestModel = {
                    'content': value
                };
                axios.post(serverAPIIp + 'api/Regulation/ChangeRegulation', JSON.stringify(createQrRequestModel), { headers })
                .then((response) =>{
                    if(response.data.isSuccess){
                        if(idNewspaper === null){
                            message.success("Tạo bài viết thành công");
                            setIsCreading(false);
                        }else{
                            message.success("Cập nhật thành công");
                            setIsCreading(false);
                        }
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors);
                        setIsCreading(false);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối máy chủ");
                    }
                    setIsCreading(false);
                })
            }
        }
    }

    function GetContact(){
        const token = cookies.get('dtu-svc-token');
        setIsLoading(true);
        if(token !== null){
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/Regulation/GetRegulation', {headers: headers})
            .then((response) =>{
                if(response.data.isSuccess){
                    setValue(response.data.responseData.content);
                    setCreateName(response.data.responseData.createId);
                    setCreateDate(response.data.responseData.createDate);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                }
                setIsLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    message.error("Phiên đăng nhập đã hết hạn");
                    removeCookie();
                }else{
                    message.error("Mất kết nối máy chủ");
                }
                setIsLoading(false);
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        GetContact();
    }, []);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>{ TITLE }</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        {
                            !isLoading?
                            <div style={{minHeight: "70vh"}}>
                                <Row>
                                    <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                        <div className = "ant-row ant-form ant-form-horizontal">
                                            <div className="ant-col ant-form-item-label">
                                                <label className="ant-form-item-required" >Nội dung</label>
                                            </div>
                                        </div>
                                        <ReactQuill
                                            placeholder="Nội dung..."
                                            theme="snow"
                                            modules={modules}
                                            formats={formats}
                                            disabled={isCreading}
                                            value={value}
                                            onChange={handleChange}
                                        />
                                        {
                                            contentNull?
                                            <div class="ant-form-item-explain ant-form-item-explain-error">
                                                <div role="alert">Vui lòng nhập nội dung!</div>
                                            </div>
                                            : null
                                        }
                                    </Col>
                                </Row>
                                <hr/>
                                <h5>Cập nhật lần cuối</h5>
                                <Row>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <h6 style={{textAlign:"left", marginTop: 15}}>Người cập nhật: {createName}</h6>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <h6 style={{textAlign:"left", marginTop: 15}}>Thời gian: <Moment date={createDate} format="HH:mm:ss DD-MM-YYYY"/> </h6>
                                    </Col>
                                </Row>
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    disabled={value === "<p><br></p>" || value === ""}
                                    onClick={() => {setIsShowPreview(!isShowPreview)}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faEye}/>}
                                    type="primary">Xem Trước
                                </Button>
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faSave}/>}
                                    onClick={() => {saveNewspaper()}}
                                    loading={isCreading}
                                    disabled={value === "<p><br></p>" 
                                    || value === ""}
                                    type="primary">Lưu
                                </Button>
                                {
                                    isShowPreview?
                                    <div>
                                        <hr/>
                                        <Preview value={value}/>
                                    </div>
                                    : null
                                }
                            </div>
                            : <Skeleton/>
                        }
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
};