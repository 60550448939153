import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {Tag, Layout, Card, Result, message, DatePicker, Row, Col, Input, Form, Button, Skeleton, Radio, Select, Checkbox} from 'antd';
import axios from 'axios';
import './css/memberrigister.css';
import moment from 'moment';
import Moment from 'react-moment';
import Cookies from 'universal-cookie';
import './css/firstsignin.css';
    import {
        BrowserRouter as Router,
        Switch,
        Route,
        NavLink,
        useRouteMatch,
        useParams
    } from "react-router-dom";
import Newspaper from './Newspaper';
import RecruitTopic from './RecruitTopic';
import ChatFacebook from './ChatFacebook';

const TITLE = "Danh sách tuyển thành viên";

function RecruitList(){
    const cookies = new Cookies();
    const [recruitId, setRecruit] = useState(window.location.pathname.split('/'))
    const { Content, Footer, Header } = Layout;
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [recruitList, setRecruitList] = useState(null);
    const [loading, setLoading] = useState(true);
    let match = useRouteMatch();
    const RecruitCard = (props) =>{
        return(
            <NavLink to={"/dashboard/recruitment/recruitlist/" + props.dataFromParent.id}>
                <div className="container">
                    <Card title={props.dataFromParent.recruitName} bordered={true} style={{cursor: "pointer", margin: 10}}>
                        <Row>
                            <Col span={12} xs={24} xl={12}>
                                <p>
                                    <h6 style={{textAlign: "left"}}>Ngày bắt đầu: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.dateStart} format="hh:mm a DD-MM-YYYY"/></h6>
                                </p>
                            </Col>
                            <Col span={12} xs={24} xl={12}>
                                <p>
                                    <h6 style={{textAlign: "left"}}>Ngày kết thúc: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.dateEnd} format="hh:mm a DD-MM-YYYY"/></h6>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} xs={24} xl={8}>
                                <p>
                                    <h6 style={{textAlign: "left"}}>Số người đăng ký: <Tag color={props.dataFromParent.recruitCount < 10 ? "#cd201f" : "#108ee9"} >{props.dataFromParent.recruitCount}</Tag></h6>
                                </p>
                            </Col>
                            <Col span={8} xs={24} xl={8}>
                                <h6 style={{textAlign: "left"}}>Tình trạng: <Tag color={!props.dataFromParent.isActivity?"#108ee9":"#cd201f"}>{!props.dataFromParent.isActivity?"Hoạt động":"Đã khoá"}</Tag></h6>
                            </Col>
                            <Col span={8} xs={24} xl={8}>
                                <h6 style={{textAlign: "left"}}>Người tạo: <Tag color={!props.dataFromParent.isActivity?"#108ee9":"#cd201f"}>{props.dataFromParent.createName ? props.dataFromParent.createName : "Đang cập nhật"}</Tag></h6>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </NavLink>
        );
    }

    function getRecruitList(){
        setLoading(true);
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token, 
        }
        axios.get(serverAPIIp + 'api/Recruit/GetRecruitList', {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                setRecruitList(response.data.responseData);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                    message.error(response.data.errors[0]);
                }
            }
            setLoading(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối máy chủ");
            }
            setLoading(false);
        })
    }

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    useEffect(() => {
        if(recruitId.length < 5){
            getRecruitList();
        }else{
            if(recruitId[4] === ''){
                getRecruitList();
            }
        }
    }, []);

    function AllRecruit(){
        return(
            <div>
                {
                    loading?
                    <div>
                        <Card loading={true}/>
                        <Card loading={true}/>
                        <Card loading={true}/>
                    </div>
                    :<div>
                        {
                        recruitList?
                        recruitList.map((item) => <RecruitCard dataFromParent={item}/>)
                        :
                        <Result
                            status="404"
                            title="Không tìm thấy dữ liệu"
                            subTitle="Hiện tại không có đợt tuyển thành viên nào!"
                        />
                        }
                    </div>
                }
            </div>
        )
    }

    return(
        <div className="container card-body"  style={{ background: "#fff"}}>
            <Router>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <Layout style={{minHeight: "85vh"}}>
                    <Header style={{background:"#fff"}}>
                        <p className={"activetitle"}>{ TITLE }</p>
                    </Header>
                    <Content >
                        <Layout style={{background: "#fff"}}>
                            <Content>
                            <div style={{minHeight: "70vh"}}>
                                <Switch>
                                    <Route path={`${match.path}/:topicId`}>
                                        <RecruitTopic/>
                                    </Route>
                                    <Route path={match.path}>
                                        <AllRecruit/>
                                    </Route>
                                </Switch>
                                <ChatFacebook/>
                            </div>
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </Router>
        </div>
    )
}
export default RecruitList;