import React, {useEffect, useState, useRef}  from 'react';
import { Helmet } from 'react-helmet'
import { Layout, Row, Col, Input, Space, message, Skeleton, Result, Button, Table, Tag  } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

function MembereRank(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const TITLE = "Bảng Xếp Hạng";
    const { Content, Footer, Header } = Layout;
    const [data, setData] = useState(null);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setsearchText] = useState(null);
    const [searchedColumn, setsearchedColumn] = useState(null);
    const searchInput = useRef(null);
    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        cookies.remove('dtu-svc-isDefaulePassword');
        window.location.href="/";
    }

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
     }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);}}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Tìm
              </Button>
              <Button onClick={() => {handleReset(clearFilters);}} size="small" style={{ width: 90 }}>
                Đặt Lại
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select());
          }
        },
        render: text =>
            searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText(null);
    };

    function GetMemberRank(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/Member/GetMemberRank', {headers: headers})
            .then((response) =>{
                if(response.data.isSuccess){
                    setData(response.data.responseData);
                    setIsLoading(false);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                    setIsError500(true);
                    setErrorMess(response.data.errors[0]);
                    setIsLoading(false);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setIsError500(false);
                    setErrorMess("Mất kết nối máy chủ");
                    setIsLoading(false);
                }
            })
        }
    }

    useEffect(async () => {
        GetMemberRank();
    }, []);

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rankNumber',
            key: 'rankNumber',
            width: 50,
        },
        {
            title: 'MSSV',
            dataIndex: 'studentId',
            key: 'studentId',
            width: 150,
            ...getColumnSearchProps('studentId'),
        },
        {
            title: 'Avatar',
            dataIndex: 'avatarPath',
            key: 'avatarPath',
            width: 100,
            render: avatarPath => <img src={serverAPIIp + avatarPath} style={{width: 40, height: 40, objectFit: "cover"}}/>
        },
        {
            title: 'Họ',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Tên',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Điểm',
            dataIndex: 'currentScore',
            key: 'currentScore',
            render: scores =>
                <Row align="middle">
                    <Col>
                    {
                        Math.round(scores) === 0?
                            <Tag color="red">{scores}</Tag>
                        :
                            <Tag color="#108ee9">{scores.toFixed(2)}</Tag>
                    }
                    </Col>
                </Row >
        },
    ]

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>Bảng Xếp Hạng</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}} >
                        <div style={{minHeight: "70vh"}}>
                        {
                            isLoading?
                                <Skeleton active />
                            : 
                            <div>
                                {
                                    data?
                                        <Table scroll={{x: 400}} style={{paddingBottom: 20}} columns={columns} dataSource={data} pagination={{hideOnSinglePage: true}}/>
                                    :
                                        <Result
                                        status={isError500? "500" : "404"}
                                        title={errorMess}
                                        subTitle={isError500 
                                        ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                                        : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                                    />
                                }
                            </div>
                        }
                        </div>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
}
export default MembereRank;