import React, {useEffect, useState} from 'react';
import {Table, Layout, message, DatePicker, Row, Col, Input, Form, Button, Modal, Card, Skeleton, Select, Switch, InputNumber, Space, Tag } from 'antd';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Search from 'antd/lib/transfer/search';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons';

function Paid(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const TITLE = "Xuất Quỹ";
    const { Content, Footer, Header } = Layout;
    const { Search } = Input;
    const [form] = Form.useForm();
    const [searchform] = Form.useForm();
    const [isSearching, setIsSearching] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [member, setMember] = useState(null);
    const [moneyInput, setMoneyInput] = useState(null);
    const { RangePicker } = DatePicker;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [size, setSize] = useState(0);
    const [totalComfirm, setTotalComfirm] = useState(0);
    const [totalNotComfirm, setTotalNotComfirm] = useState(0);
    const [data, setData] = useState([]);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function getActivity(){
        setIsSearching(true);
        const token = cookies.get('dtu-svc-token');
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        var studentId = form.getFieldValue("memberId");
        if(studentId){
            var requestData = {
                studentId: studentId,
            };
            axios.get(serverAPIIp + 'api/Member/SearchMemberByStudenId', {params: requestData, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    setMember(response.data.responseData);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                }
                setIsSearching(false);
            })
            .catch((response)=>{
                message.error("Mất kết nối với máy chủ");
                setIsSearching(false);
            })
        }else{
            setIsSearching(false);
        }
    }

    function onChangeMoney(e){
        try{
            var fValue = parseFloat(e.target.value.replace(",","."));
            if(fValue < 1000)
            {
                setMoneyInput(null);
            }else{
                setMoneyInput(fValue);
            }
        }catch{
            setMoneyInput(null);
        }
    }

    async function savePaid(){
        if(member){
            setSaveLoading(true);
            var content = form.getFieldValue("content");
            var money = form.getFieldValue("money");
            var isSuccsess = true;
            if(content === null || content === undefined){
                form.setFields([{
                    name: "content",
                    errors:["Vui lòng điền nội dung!"]
                }]);
                isSuccsess = false;
            }

            var moneyNumber = 0;
            if(money === null || money === undefined){
                form.setFields([{
                    name: "money",
                    errors:["Vui lòng điền số tiền!"]
                }]);
                isSuccsess = false;
            }else{
                // console.log(money);
                try{
                    moneyNumber = parseFloat(money);
                    if(moneyNumber < 1000){
                        form.setFields([{
                            name: "money",
                            errors:["Số tiền không hợp lệ!"]
                        }]);
                        isSuccsess = false;
                    }
                }catch{
                    form.setFields([{
                        name: "money",
                        errors:["Số tiền không hợp lệ!"]
                    }]);
                    isSuccsess = false;
                }
            }
            if(isSuccsess){
                const token = cookies.get('dtu-svc-token');

                const createPaidFundRequestModel = {
                    'content': content,
                    'money': moneyNumber,
                    'payerId': member.id,
                };

                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }

                await axios.post(serverAPIIp + 'api/Fund/CreateSpendMoney', JSON.stringify(createPaidFundRequestModel), { headers })
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Xuất thành công. Vui lòng đợi người nhập xác nhận!");
                        GetListPaidMoney();
                    }else{
                        message.error(response.data.errors[0]);
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                    }
                    setSaveLoading(false);
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối với máy chủ");
                    }
                    setSaveLoading(false);
                });
            }else{
                setSaveLoading(false);
            }
        }else{
            form.setFields([{
                name: "memberId",
                errors:["Vui lòng kiểm tra MSSV!"]
            }]);
        }
    }

    function GetListPaidMoney(){
        setLoading(true);
        const token = cookies.get('dtu-svc-token');
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }

        var content = searchform.getFieldValue("contentSearch");
        var datetime = searchform.getFieldValue("datetime");
        var startDate = "2019-11-18";
        var datetimeNow = new Date();
        datetimeNow.setDate(datetimeNow.getDate() + 1);
        var endDate = datetimeNow.toISOString().substring(0, 10);
        if(datetime){
            startDate = datetime[0].toISOString().substring(0, 10);
            endDate = datetime[1].toISOString().substring(0, 10)
        }
        const createCollectFundRequestModel = {
            'startDate': startDate,
            'endtDate': endDate,
            'content': content ? content : "",
            'page': page,
            'pageSize': pageSize,
        };
        axios.post(serverAPIIp + 'api/Fund/GetListSpendMoney', JSON.stringify(createCollectFundRequestModel), { headers })
        .then((response) =>{
            if(response.data.isSuccess){
                var messages = response.data.message.split(',');
                setSize(parseFloat(messages[0]));
                setTotalComfirm(parseFloat(messages[1]));
                setTotalNotComfirm(parseFloat(messages[2]));
                setData(response.data.responseData);
            }else{
                message.error(response.data.errors[0]);
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie(response.data.responseData);
                }
            }
            setLoading(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setLoading(false);
        });
    }

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },{
            title: "Ngày",
            dataIndex: "transactionDate",
            key: "transactionDate",
            width: 140,
            render: transactionDate => <span><Moment date={transactionDate} format="DD-MM-YYYY HH:mm:ss"/></span>
        },
        {
            title: "MSSV",
            dataIndex: "studentid",
            render: (text, record) => <span>{record.payer.studentId}</span>
        },
        {
            title: "Người nộp",
            dataIndex: "member",
            render: (text, record) => <span>{record.payer.lastName} {record.payer.firstName}</span>
        },
        {
            title: "SĐT",
            dataIndex: "phonenumber",
            render: (text, record) => <span>{record.payer.phoneNumber}</span>
        },
        {
            title: "Số tiền",
            dataIndex: "money",
            key: "money",
            render: money => <span><CurrencyFormat value={money} displayType={'text'} thousandSeparator={true}/> VNĐ</span>
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
        },
        {
            title: "Thủ quỹ",
            dataIndex: "treasurer",
            render: (text, record) => <span>{record.treasurer.lastName} {record.treasurer.firstName}</span>
        },
        {
            title: "SĐT (TQ)",
            dataIndex: "phonenumber",
            render: (text, record) => <span>{record.treasurer.phoneNumber}</span>
        },
        {
            title: "Tình Trạng",
            dataIndex: "isConfirmed",
            key: "isConfirmed",
            render: isConfirmed => <Tag color={isConfirmed?"#108ee9": "#F50"}>{isConfirmed?"Xác Nhận":"Chờ Xác Nhận"}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "id",
            render: (text, record) => 
                <Row>
                    <Col style={{padding: 2}}>
                        <Button type={"primary"} disabled={record.isConfirmed} onClick={() => {confirmDeleteCollect(record)}} icon={<FontAwesomeIcon icon={faTrash} />} danger/>
                    </Col>
                </Row>
        },
    ]

    function ExportListSpendMoney(){
        setIsDownloadLoading(true);
        const token = cookies.get('dtu-svc-token');
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }

        var content = searchform.getFieldValue("contentSearch");
        var datetime = searchform.getFieldValue("datetime");
        var startDate = "2019-11-18";
        var datetimeNow = new Date();
        datetimeNow.setDate(datetimeNow.getDate() + 1);
        var endDate = datetimeNow.toISOString().substring(0, 10);
        if(datetime){
            startDate = datetime[0].toISOString().substring(0, 10);
            endDate = datetime[1].toISOString().substring(0, 10)
        }
        const createCollectFundRequestModel = {
            'startDate': startDate,
            'endtDate': endDate,
            'content': content ? content : "",
            'page': page,
            'pageSize': pageSize,
        };
        axios.post(serverAPIIp + 'api/Fund/ExportListSpendMoney', JSON.stringify(createCollectFundRequestModel), { headers })
        .then((response) =>{
            if(response.data.isSuccess){
                window.open(serverAPIIp + response.data.responseData, "_blank");
                
            }else{
                message.error(response.data.errors[0]);
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie(response.data.responseData);
                }
            }
            setIsDownloadLoading(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsDownloadLoading(false);
        });
    }

    function confirmDeleteCollect(collect) {
        var conent = "Xoá phiếu thu của: " + collect.payer.lastName + " " + collect.payer.firstName + ", số tiền: " + formatMoney(collect.money) + " VNĐ, nội dung thu tiền: " + collect.content;
        Modal.confirm({
          title: 'Xoá phiếu thu tiền!',
          icon: <ExclamationCircleOutlined />,
          content: conent,
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteCollectClick(collect)}
        });
    }

    function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
        //   console.log(e)
        }
    };

    function deleteCollectClick(collect){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config  = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                RemoveCollectOrSpendMoney: collect.id,
            };
            axios.get(serverAPIIp + 'api/Fund/RemoveCollectOrSpendMoney', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Xoá thành công");
                    var collectList = [];
                    data.forEach((item) =>{
                        if(item.id !== collect.id){
                            collectList.push(item);
                        }
                    });
                    setData(collectList);
                    if(collect.isConfirmed){
                        var totalMoney = totalComfirm - collect.money;
                        setTotalComfirm(totalMoney);
                    }else{
                        var totalMoney = totalNotComfirm - collect.money;
                        setTotalNotComfirm(totalMoney);
                    }
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function pagination(page, pageSize){
        setPage(page);
        setPageSize(pageSize);
    }

    useEffect(() => {
        GetListPaidMoney();
    }, [page]);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>{ TITLE }</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        <div style={{minHeight: "70vh"}}>
                            <Form form={form}>
                                <Row>
                                    <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                        <Form.Item 
                                            name="content"
                                            label="Nội dung:"
                                            // disabled={isCreading}
                                            rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                                        >
                                            <Input maxLength={250} placeholder="Nội dung" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <Form.Item 
                                            name="money" 
                                            label="Số tiền nộp:"
                                            // disabled={isCreading}
                                            rules={[{ required: true, message: 'Vui lòng nhập số tiền xuất!' }]}
                                        >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,',')}
                                                placeholder="Số tiền nộp" 
                                                />
                                        </Form.Item>
                                        <Form.Item 
                                            name="memberId" 
                                            label="MSSV:"
                                            // disabled={isCreading}
                                            rules={[{ required: true, message: 'Vui lòng nhập mã sinh viên!' }]}
                                        >
                                            <Search placeholder="MSSV" 
                                            enterButton 
                                            onSearch={() => getActivity()}
                                            loading={isSearching}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <Card style={{marginBottom: 10}}>
                                            {
                                                member?
                                                <div>
                                                    <Row>
                                                        <Col span={6} xs={6} xl={6}>
                                                        <img src={serverAPIIp + member.avatarPath} 
                                                            style={{width: 40, height: 40, objectFit: "cover"}}/>
                                                        </Col>
                                                        <Col span={18} xs={18} xl={18}>
                                                            <h4>{member.firstName} {member.lastName}</h4>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : <p>Chưa có thông tin người nhận tiền</p>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                                <Button loading={isSaveLoading} type='primary' icon={<FontAwesomeIcon style={{marginRight: 15}} icon={faSave} />} onClick={()=>savePaid()}>Lưu</Button>
                            </Form>
                            <hr/>
                            <h3>Danh Sách Xuất Quỹ</h3>
                            <Form form={searchform}>
                                <Row>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <Form.Item 
                                            name="datetime" 
                                            label="Ngày:"
                                        >
                                            <RangePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <Form.Item 
                                            name="contentSearch" 
                                            label="Nội dung:"
                                        >
                                            <Search placeholder="Nhập nội dung" 
                                            onSearch={() => GetListPaidMoney()}
                                            enterButton />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            {
                                data?
                                <div>
                                    <Button onClick={() => ExportListSpendMoney()} loading={isDownloadLoading} style={{float:"right", marginBottom: 15}} type='primary' icon={<FontAwesomeIcon style={{marginRight: 15}} icon={faDownload} />}>Tải Xuống</Button>
                                    <Table 
                                        key={data.id}
                                        columns={columns} 
                                        dataSource={data} 
                                        loading={isLoading}
                                        pagination={{
                                            onChange: (page, pageSize) =>{
                                                pagination(page, pageSize);
                                            },
                                            current: page,
                                            pageSize: pageSize,
                                            total: size
                                        }}
                                        scroll={{x: 1000}}/>
                                    <h4 style={{textAlign:"right"}}>Tổng(XN): <CurrencyFormat value={totalComfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                    <h4 style={{textAlign:"right"}}>Tổng(C): <CurrencyFormat value={totalNotComfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                </div>
                                :null
                            }
                        </div>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
}
export default Paid;