import React, {useState} from 'react';
import { Layout, Menu, Space } from 'antd';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/dashboard.css';
import { faDonate, faIdCard, faUsers, faUserPlus, faNewspaper, faHiking, faDollarSign, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IndividualParticipatingEvent from './IndividualParticipatingEvent';
import PersonalEventsCurrentlyParticipating from './PersonalEventsCurrentlyParticipating';
import MembereRank from './MemberRank';
import QrCodeManager from './QrCodeManager';
import MemberCard from './MemberCard';
import AccountList from './AccountList';
import CreateRecuit from './CreateRecuit';
import Cookies from 'universal-cookie';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from "react-router-dom";
import RecruitList from './RecruitList';
import Member from './Member';
import CreateNewspaper from './CreateNewspaper';
import NewspaperList from './NewspaperList';
import UploadBanner from './UploadBanner';
import CreateActivity from './CreateActivity';
import ActivityList from './ActivityList';
import CreateContact from './CreateContact';
import MoneyReceived from './MoneyReceived';
import Paid from './Paid';
import SpendConfirmation from './SpendConfirmation';
import CreateInviteSponsors from './CreateInviteSponsors';
import SponsorsListByAdmin from './SponsorsListByAdmin';
import WebSocketTest from './WebSocketTest';

function Dashboard(){
    const { Sider } = Layout;
    const rootSubmenuKeys = ['personal', 'member', 'recruitment', 'newspaper', 'event', 'money', 'sponsor', 'webside', 'report', 'statistical'];
    const { SubMenu } = Menu;

    // submenu keys of first level
    const cookies = new Cookies();
    const [pathList, setPathList] = useState(window.location.pathname.split('/'));
    const [openKeys, setOpenKeys] = useState(pathList[2] === "" && pathList.length === 3 || pathList[1] === "dashboard" && pathList.length === 2 ? ['personal'] : [pathList[2]]);
    const [selectKeys, setSelectKeys] = useState(pathList[2] === "" && pathList.length === 3 || pathList[1] === "dashboard" && pathList.length === 2 ? ['activitiesdone'] : [pathList[3] ? pathList[3] : 
        pathList[2] === 'personal'? 'activitiesdone' :
        pathList[2] === 'member'? 'memberlist' :
        pathList[2] === 'recruitment'? 'createrecruit' :
        pathList[2] === 'newspaper'? 'createnewspaper' :
        pathList[2] === 'event'? 'createactivity' :
        pathList[2] === 'money'? 'payment' :
        pathList[2] === 'sponsor'? 'createcallsfunding' :
        pathList[2] === 'webside'? 'createbanner' :
        pathList[2] === 'report'? 'reportpayment' :
        pathList[2] === 'statistical'? 'statisticalmember' : null
    ]);
    const [roleId, setRoleId] = useState(parseInt(cookies.get('dtu-svc-role')));
    const [roleViewMember, setRoleViewMember] = useState([1,2,3,4,5,6]);
    const [roleMemberCard, setMemberCard] = useState([1,2,5]);
    const [roleMemberAccount, setMemberAccount] = useState([1,2]);
    const [roleViewRecruit, setViewRecruit] = useState([1,2, 3,4,5,6]);
    const [roleCreateRecruit, setCreateRecruit] = useState([1,2]);
    const [roleNewspaper, setNewspaper] = useState([5,9]);
    const [roleViewActivity, setViewActivity] = useState([1,2,3,4,5,6, 10]);
    const [roleCreateActivity, setCreateActivity] = useState([1,2,4,5]);
    const [roleMoney, setMoney] = useState([3]);
    const [roleForeignAffairs, setroleForeignAffairs] = useState([6]);
    const [roleMedia, setMedia] = useState([5,9]);


    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
        // console.log(window.location.pathname.split('/')[1]);
    };

    const onclick = ({key}) => {
        // console.log(window.location.pathname.split('/')[2]);
        setSelectKeys(key);
    }

    // window.onload = () =>{
    //     setSelectKeys(['memberlist']);
    //     console.log(pathList[2] === "" && pathList.length === 3 || pathList[1] === "dashboard" && pathList.length === 2 ? ['activitiesdone'] : [pathList[3] ? pathList[3] : pathList[2]]);
    // }

    return(
        <>
            <Router>
                <div >
                    <Layout>
                        <Sider
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={broken => {
                                // console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                // console.log(collapsed, type);
                            }}
                            theme="light"
                            width={250}
                        >
                            <Menu onClick={onclick} theme="light" mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} defaultSelectedKeys={selectKeys}>
                                <SubMenu key="personal" title={<Space><FontAwesomeIcon icon={faIdCard} /><span>Cá Nhân</span></Space>}>
                                <Menu.ItemGroup key="personal-gr1" title="Hoạt Động">
                                    <Menu.Item key="activitiesdone"><NavLink to="/dashboard/personal/activitiesdone">Đang Tham Gia</NavLink></Menu.Item>
                                    <Menu.Item key="activitiesdoing"><NavLink to="/dashboard/personal/activitiesdoing">Đã Tham Gia</NavLink></Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="personal-gr2" title="Khác">
                                    <Menu.Item key="rank"><NavLink to="/dashboard/personal/rank">Bảng Xếp Hạng</NavLink></Menu.Item>
                                    <Menu.Item key="qrcode"><NavLink to="/dashboard/personal/qrcode">Tạo Qr-Code</NavLink></Menu.Item>
                                    <Menu.Item key="chat"><NavLink to="/dashboard/personal/chat">Nhắn Tin</NavLink></Menu.Item>
                                </Menu.ItemGroup>
                                </SubMenu>
                                {
                                    roleViewMember.includes(roleId)?
                                    <SubMenu key="member" title={<Space><FontAwesomeIcon icon={faUsers} /><span>Thành Viên</span></Space>}>
                                        <Menu.Item key="memberlist"><NavLink to="/dashboard/member/memberlist">Danh Sách Thành Viên</NavLink></Menu.Item>
                                        {
                                            roleMemberCard.includes(roleId)?
                                                <Menu.Item key="membercardlist"><NavLink to="/dashboard/member/membercardlist">Thẻ Thành Viên</NavLink></Menu.Item>
                                            : null
                                        }
                                        {
                                            roleMemberAccount.includes(roleId)?
                                            <Menu.Item key="account"><NavLink to="/dashboard/member/account">Tài Khoản Thành Viên</NavLink></Menu.Item>
                                            : null
                                        }
                                    </SubMenu>
                                    : null
                                }
                                {
                                    roleViewRecruit.includes(roleId)?
                                        <SubMenu key="recruitment" title={<Space><FontAwesomeIcon icon={faUserPlus} /><span>Tuyển Thành Viên</span></Space>}>
                                            {
                                                roleCreateRecruit.includes(roleId)?
                                                <Menu.Item key="createrecruit"><NavLink to="/dashboard/recruitment/createrecruit">Tạo Mới</NavLink></Menu.Item>
                                                : null
                                            }
                                            <Menu.Item key="recruitlist"><NavLink to="/dashboard/recruitment/recruitlist">Danh Sách Tuyển Thành Viên</NavLink></Menu.Item>
                                        </SubMenu>
                                    : null
                                }
                                {
                                    roleNewspaper.includes(roleId)?
                                        <SubMenu key="newspaper" title={<Space><FontAwesomeIcon icon={faNewspaper} /><span>Bài Viết</span></Space>}>
                                            <Menu.Item key="createnewspaper"><NavLink to="/dashboard/newspaper/createnewspaper">Tạo Bài Viết</NavLink></Menu.Item>
                                            <Menu.Item key="newspapers"><NavLink to="/dashboard/newspaper/newspapers">Danh Sách Bài Viết</NavLink></Menu.Item>
                                        </SubMenu>
                                    : null
                                }
                                {
                                    roleViewActivity.includes(roleId)?
                                        <SubMenu key="event" title={<Space><FontAwesomeIcon icon={faHiking} /><span>Hoạt Động</span></Space>}>
                                            {
                                                roleCreateActivity.includes(roleId)?
                                                    <Menu.Item key="createactivity"><NavLink to="/dashboard/event/createactivity">Tạo Hoạt Động</NavLink></Menu.Item>
                                                : null
                                            }
                                            <Menu.Item key="activitieslist"><NavLink to="/dashboard/event/activitieslist">Danh Sách Hoạt Động</NavLink></Menu.Item>
                                        </SubMenu>
                                    : null
                                }
                                <SubMenu key="money" title={<Space><FontAwesomeIcon icon={faDollarSign} /><span>Quỹ</span></Space>}>
                                    {
                                        roleMoney.includes(roleId)?
                                        <Menu.Item key="moneyreceived"><NavLink to="/dashboard/money/moneyreceived">Nhập Quỹ</NavLink></Menu.Item>
                                        :null
                                    }{
                                        roleMoney.includes(roleId)?
                                        <Menu.Item key="paid"><NavLink to="/dashboard/money/paid">Xuất Quỹ</NavLink></Menu.Item>
                                        :null
                                    }
                                    {
                                        !roleMoney.includes(roleId)?
                                        <Menu.Item key="spendingconfirmation"><NavLink to="/dashboard/money/confirmation">Xác Nhận Nhập/Xuất</NavLink></Menu.Item>
                                        : null
                                    }
                                </SubMenu>
                                {
                                    roleForeignAffairs.includes(roleId)?
                                        <SubMenu key="sponsor" title={<Space><FontAwesomeIcon icon={faDonate} /><span>Tài Trợ</span></Space>}>
                                            <Menu.Item key="createcallsfunding"><NavLink to="/dashboard/sponsor/createcallsfunding">Tạo Mới</NavLink></Menu.Item>
                                            <Menu.Item key="sponsorshiplist"><NavLink to="/dashboard/sponsor/sponsorshiplist">Danh Sách Kêu Gọi</NavLink></Menu.Item>
                                        </SubMenu>
                                    : null
                                }
                                {
                                    roleMedia.includes(roleId)?
                                        <SubMenu key="webside" title={<Space><FontAwesomeIcon icon={faFileContract} /><span>Trang Web</span></Space>}>
                                            <Menu.Item key="createbanner"><NavLink to="/dashboard/webside/createbanner">Banner</NavLink></Menu.Item>                                    
                                            <Menu.Item key="introduceclub"><NavLink to="/dashboard/webside/introduceclub">Giới Thiệu CLB</NavLink></Menu.Item>
                                        </SubMenu>
                                    : null
                                }
                            </Menu>
                        </Sider>
                        <Switch>
                            <Redirect exact from="/dashboard" to="/dashboard/personal/activitiesdone" />
                            <Redirect exact from="/dashboard/personal" to="/dashboard/personal/activitiesdone" />
                            <Redirect exact from="/dashboard/member" to="/dashboard/member/memberlist" />
                            <Redirect exact from="/dashboard/recruitment" to="/dashboard/recruitment/createrecruit" />
                            <Redirect exact from="/dashboard/newspaper" to="/dashboard/recruitment/createnewspaper" />
                            <Redirect exact from="/dashboard/event" to="/dashboard/recruitment/createactivity" />
                            <Redirect exact from="/dashboard/money" to="/dashboard/recruitment/payment" />
                            <Redirect exact from="/dashboard/sponsor" to="/dashboard/recruitment/createcallsfunding" />
                            <Redirect exact from="/dashboard/webside" to="/dashboard/recruitment/createbanner" />
                            <Redirect exact from="/dashboard/report" to="/dashboard/recruitment/reportpayment" />
                            <Redirect exact from="/dashboard/statistical" to="/dashboard/recruitment/statisticalmember" />
                            <Route path="/dashboard/personal/activitiesdone">
                                <PersonalEventsCurrentlyParticipating/>
                            </Route>
                            <Route path="/dashboard/personal/activitiesdoing">
                                <IndividualParticipatingEvent/>
                            </Route>
                            <Route path="/dashboard/personal/rank">
                                <MembereRank/>
                            </Route>
                            <Route path="/dashboard/personal/qrcode">
                                <QrCodeManager/>
                            </Route>
                            <Route path="/dashboard/personal/chat">
                                <WebSocketTest/>
                            </Route>
                            <Route path="/dashboard/member/memberlist"
                                render={()=>{
                                    if(roleViewMember.includes(roleId)){
                                        return(<Member/>);
                                    }else{
                                        return (
                                            <Redirect to="/dashboard/personal/activitiesdone"/>
                                        );
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/member/membercardlist"
                                render={()=>{
                                    if(roleViewMember.includes(roleId)){
                                        if(roleMemberCard.includes(roleId))
                                        {
                                            return(<MemberCard/>);
                                        }else{
                                            return(<Redirect to="/dashboard/member/memberlist"/>);
                                        }
                                    }else{
                                        return (
                                            <Redirect to="/dashboard/personal/activitiesdone"/>
                                        );
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/member/account"
                                render={()=>{
                                    if(roleViewMember.includes(roleId)){
                                        if(roleMemberAccount.includes(roleId))
                                        {
                                            return(<AccountList/>);
                                        }else{
                                            return(<Redirect to="/dashboard/member/memberlist"/>);
                                        }
                                    }else{
                                        return (
                                            <Redirect to="/dashboard/personal/activitiesdone"/>
                                        );
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/recruitment/createrecruit"
                                render={() =>{
                                    if(roleViewMember.includes(roleId)){
                                        if(roleCreateRecruit.includes(roleId)){
                                            return(<CreateRecuit/>);
                                        }else{
                                            return(<Redirect to="/dashboard/recruitment/recruitlist"/>);
                                        }
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/recruitment/recruitlist"
                                render={()=>{
                                    if(roleViewMember.includes(roleId)){
                                        return(<RecruitList/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/newspaper/createnewspaper"
                                render={()=>{
                                    if(roleNewspaper.includes(roleId)){
                                        return(<CreateNewspaper/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/newspaper/newspapers"
                                render={()=>{
                                    if(roleNewspaper.includes(roleId)){
                                        return(<NewspaperList/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/webside/createbanner"
                                render={()=>{
                                    if(roleMedia.includes(roleId)){
                                        return(<UploadBanner/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/webside/introduceclub"
                                render={()=>{
                                    if(roleMedia.includes(roleId)){
                                        return(<CreateContact/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/event/createactivity"
                                render={()=>{
                                    if(roleViewActivity.includes(roleId)){
                                        if(roleCreateActivity.includes(roleId)){
                                            return(<CreateActivity/>);
                                        }else{
                                            return(<Redirect to="/dashboard/event/activitieslist"/>);
                                        }
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                                
                            </Route>
                            <Route path="/dashboard/event/activitieslist"
                                render={()=>{
                                    if(roleViewActivity.includes(roleId)){
                                        return(<ActivityList/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/money/moneyreceived"
                                render={()=>{
                                    if(roleMoney.includes(roleId)){
                                        return(<MoneyReceived/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/money/paid"
                                render={()=>{
                                    if(roleMoney.includes(roleId)){
                                        return(<Paid/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/money/confirmation"
                                render={()=>{
                                    if(!roleMoney.includes(roleId)){
                                        return(<SpendConfirmation/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/sponsor/createcallsfunding"
                                render={()=>{
                                    if(roleForeignAffairs.includes(roleId)){
                                        return(<CreateInviteSponsors/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                            <Route path="/dashboard/sponsor/sponsorshiplist"
                                render={()=>{
                                    if(roleForeignAffairs.includes(roleId)){
                                        return(<SponsorsListByAdmin/>);
                                    }else{
                                        return(<Redirect to="/dashboard/personal/activitiesdone"/>);
                                    }
                                }}>
                            </Route>
                        </Switch>
                    {/* {
                        selectKeys === '2'?
                        
                        : 
                        selectKeys === '1' ?
                        
                        : selectKeys === '3'?
                        <MembereRank/>
                        : selectKeys === '4'?
                        <QrCodeManager/>
                        : selectKeys === '5'?
                        <MemberList/>
                        : selectKeys === '6'?
                        <MemberCard/>
                        : selectKeys === '7'?
                        <AccountList/>
                        : selectKeys === '10'?
                        <CreateRecuit/>
                        : null
                    } */}
                    </Layout>
                </div>
            </Router>
        </>
    )
}
export default Dashboard;