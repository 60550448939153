import { Helmet } from 'react-helmet';
import React, {useEffect, useState} from 'react';
import { message, Layout, Row, Col, Card, Skeleton, Result, Tag } from 'antd';
import axios from 'axios';
import Moment from 'react-moment';
import Sliderbar from './Sliderbar';
import Cookies from 'universal-cookie';
import moment from 'moment';
import './css/newspaper.css';
import {
        BrowserRouter as Router,
        Switch,
        Route,
        NavLink,
        useRouteMatch,
    } from "react-router-dom";
import ActivityTopic from './ActivityTopic';
import ChatFacebook from './ChatFacebook';

function Activities(){
    const TITLE = "Hoạt động";
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const { Footer } = Layout;
    const [loading, setLoading] = useState(false);
    const [activityEnded, setActivityEnded] = useState(null);
    const [activityInProgress, setActivityInProgress] = useState(null);
    const [data, setData] = useState(null);
    let match = useRouteMatch();
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const cookies = new Cookies();
    
    const GetListActivitiesAndEvent = async() =>{
        setLoading(true);
        const token = cookies.get('dtu-svc-token');
        var config = null;
        if(token !== null && token !== undefined){
            config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                'Authorization': 'Bearer ' + token, 
            }
        }else{
            config = {
                "Content-Type": "application/json",
                "accept": "*/*",
            }
        }
        await axios.get(serverAPIIp + 'api/ActivitiesAndEvents/GetListActivitiesAndEvent', {headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
                setData(response.data.responseData);
                // console.log(response.data.responseData);
                var actiEnd = [];
                var actiProgress = [];
                response.data.responseData.forEach((item) =>{
                    if(!item.registerable){
                        if(moment(item.endDate).add(7, 'hours') > new Date()){
                            actiProgress.push(item);
                        }else{
                            actiEnd.push(item);
                        }
                    }else{
                        actiProgress.push(item);
                    }
                })
                if(actiEnd.length > 0){
                    actiEnd = actiEnd.sort((a,b) => {
                        return new Date(a.startDate).getTime() - 
                            new Date(b.startDate).getTime()
                    }).reverse();
                    setActivityEnded(actiEnd);
                }
                if(actiProgress.length > 0){
                    setActivityInProgress(actiProgress);
                }
                setLoading(false);
            }else{
                // console.log(response.data.responseData);
                setLoading(false);
                setIsError500(true);
                setErrorMess(response.data.errors);
                message.error(response.data.errors);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    function AllNews(){
        return(
            <div>
                <div>
                    {
                        activityInProgress !== null && activityInProgress.length > 0?
                        <div>
                            <h5 style={{textAlign:"left", marginTop: 20, marginLeft: 20,fontWeight: 600}}>Hoạt động đang diễn ra</h5>
                            <hr/>
                            {
                                activityInProgress.map(item =>{
                                    return(
                                        <NavLink to={"/activities/" + item.id}>
                                            <Card className='hotnews' style={{margin: 10}}>
                                                <Row align="middle">
                                                    <Col span={8} xs={8} xl={8}>
                                                        <img src={serverAPIIp + item.posterPath}/>
                                                    </Col>
                                                    <Col span={16} xs={16} xl={16}>
                                                        <p style={{textAlign:"left", marginLeft: 10, fontWeight: 600, maxHeight: 20, overflow: "hidden"}}>{item.title}</p>
                                                        <p style={{textAlign:"left", marginLeft: 10}} ><Tag color={item.registerable?"#108ee9":"#f50"}>{item.registerable?"Có thể đăng ký":"Hết hạn đăng ký"}</Tag>{!item.isPublic?<Tag color={"#f50"}>Nội bộ</Tag>: null}</p>
                                                        <p style={{textAlign:"left", marginLeft: 10}}>Diễn ra từ: <Moment date={item.startDate} format="HH:mm DD-MM-YYYY"/></p>
                                                        <p style={{textAlign:"left", marginLeft: 10}}>Đến: <Moment date={item.endDate} format="HH:mm DD-MM-YYYY"/></p>
                                                        <p style={{textAlign:"left", marginLeft: 10}}>Hạn cuối đăng ký: <Moment date={item.registrationDeadline} format="HH:mm DD-MM-YYYY"/></p>
                                                        <p style={{textAlign:"left", marginLeft: 10}}>Địa điểm: {item.address.streesName + ', ' + item.address.ward.wardName + ', ' + item.address.district.districtName + ', ' + item.address.province.provinceName}</p>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </NavLink>
                                    );
                                })
                            }
                        </div>
                        : null
                    }
                </div>
                <div>
                    {
                        activityEnded !== null && activityEnded.length > 0?
                        <div style={{marginBottom: 10}}>
                            <h5 style={{textAlign:"left", marginTop: 20, marginLeft: 20,fontWeight: 600}}>Các hoạt động khác</h5>
                            <hr/>
                            {
                                activityEnded.map(item =>{
                                    return(
                                        <NavLink to={"/activities/" + item.id}>
                                            <Card title={item.recruitName} bordered={true} style={{cursor: "pointer", margin: 10}}>
                                                <Row align="middle">
                                                    <Col span={18} xs={24} xl={18}>
                                                        <Row span={24}>
                                                            <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                                <p>
                                                                    <h6 style={{textAlign: "left", fontWeight: 600}}>{item.title}</h6>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                        <Row span={24}>
                                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                <p>
                                                                    <h6 style={{textAlign: "left"}}>Ngày diễn ra: <Moment style={{color:"red", fontWeight: 600 }} date={item.startDate} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                                                </p>
                                                            </Col>
                                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                <p>
                                                                    <h6 style={{textAlign: "left"}}>Ngày kết thúc: <Moment style={{color:"red", fontWeight: 600 }} date={item.endDate} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                        <Row span={24}>
                                                            <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                                <p>
                                                                    <h6 style={{textAlign: "left"}}>Hạn đăng ký: <Moment style={{color:"red", fontWeight: 600 }} date={item.registrationDeadline} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                                                </p>
                                                            </Col>
                                                            <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                                <h6 style={{textAlign: "left"}}>Loại hoạt động: <Tag color={item.isPublic?"#87d068":"#f50"}>{item.isPublic?"Công khai":"Nội bộ"}</Tag></h6>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={6} xs={24} xl={6}>
                                                        <img src={serverAPIIp + item.posterPath} style={{maxHeight: 100, textAlign: "center"}}/>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </NavLink>
                                    );
                                })
                            }
                        </div>
                        :null
                    }
                </div>                
            </div>
        );
    }

    useEffect(() => {
        GetListActivitiesAndEvent();
    }, []);
    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <div>
            <Sliderbar/>
                {
                    loading?
                    <Skeleton active/>
                    :
                    <div>
                        {
                            data !== null?
                            <Router>
                                <Layout>
                                    <div className="d-flex justify-content-center">
                                        <div className="recruitform">
                                            <div className="container card" style={{minHeight: "42vh"}}>
                                                <Switch>
                                                    <Route path={`${match.path}/:newspaperId`}>
                                                        <ActivityTopic/>
                                                    </Route>
                                                    <Route path={match.path}>
                                                        <AllNews/>
                                                    </Route>
                                                </Switch>
                                                <ChatFacebook/>
                                            </div>
                                        </div>
                                    </div>
                                </Layout>
                            </Router>
                            :<Result
                                status={!isError500? "500" : "404"}
                                title={errorMess}
                                subTitle={isError500 
                                ? "Không có hoạt động nào!"
                                : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                        />
                        }
                    </div>
                }
            </div>
        </>
    )
}
export default Activities;