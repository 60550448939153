// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/hero-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".firstnews img{\r\n    max-width: 100%;\r\n    margin: 15 !important;\r\n}\r\n\r\n.hotnews img{\r\n    max-width: 100% !important;\r\n    margin: 0;\r\n}\r\n\r\n.firstnews{\r\n    margin: 10px;\r\n}\r\n\r\n.newspaper img{\r\n    max-width: 100%;\r\n}\r\n\r\n.ant-card{\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top right no-repeat;\r\n    cursor: pointer;\r\n    background-size: cover;\r\n    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);\r\n}\r\n\r\n.ant-card:hover{\r\n  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);\r\n  /* margin: 2x; */\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://src/components/pages/css/newspaper.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uEAAuD;IACvD,eAAe;IACf,sBAAsB;IACtB,4CAA4C;AAChD;;AAEA;EACE,6CAA6C;EAC7C,gBAAgB;AAClB","sourcesContent":[".firstnews img{\r\n    max-width: 100%;\r\n    margin: 15 !important;\r\n}\r\n\r\n.hotnews img{\r\n    max-width: 100% !important;\r\n    margin: 0;\r\n}\r\n\r\n.firstnews{\r\n    margin: 10px;\r\n}\r\n\r\n.newspaper img{\r\n    max-width: 100%;\r\n}\r\n\r\n.ant-card{\r\n    background: url(../img/hero-bg.png) top right no-repeat;\r\n    cursor: pointer;\r\n    background-size: cover;\r\n    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);\r\n}\r\n\r\n.ant-card:hover{\r\n  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);\r\n  /* margin: 2x; */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
