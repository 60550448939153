import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import {Tag, Layout, Card, Result, message, Pagination, Row, Col} from 'antd';
import axios from 'axios';
import './css/memberrigister.css';
import moment from 'moment';
import Moment from 'react-moment';
import Cookies from 'universal-cookie';
import './css/firstsignin.css';
    import {
        BrowserRouter as Router,
        Switch,
        Route,
        NavLink,
        useRouteMatch,
    } from "react-router-dom";
import ActivityAdminTopic from './ActivityAdminTopic';

const TITLE = "Danh sách hoạt động";

function ActivityList(){
    const cookies = new Cookies();
    const { Content, Footer, Header } = Layout;
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [newspaperList, setNewspaperList] = useState(null);
    const [page, setPage] = useState(0);
    const [pageCout, setPageCout] = useState(1);
    let match = useRouteMatch();
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newsCount, setNewsCount] = useState(0);
    const ActivityCard = (props) =>{
        return(
            <NavLink to={"/dashboard/event/activitieslist/" + props.dataFromParent.id}>
                <div className="container">
                    <Card title={props.dataFromParent.recruitName} bordered={true} style={{cursor: "pointer", margin: 10}}>
                        <Row align="middle">
                            <Col span={18} xs={24} xl={18}>
                                <Row span={24}>
                                    <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                        <p>
                                            <h6 style={{textAlign: "left", fontWeight: 600}}>{props.dataFromParent.title}</h6>
                                        </p>
                                    </Col>
                                </Row>
                                <Row span={24}>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <p>
                                            <h6 style={{textAlign: "left"}}>Ngày diễn ra: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.startDate} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                        </p>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <p>
                                            <h6 style={{textAlign: "left"}}>Ngày kết thúc: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.endDate} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                        </p>
                                    </Col>
                                </Row>
                                <Row span={24}>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <p>
                                            <h6 style={{textAlign: "left"}}>Hạn đăng ký: <Moment style={{color:"red", fontWeight: 600 }} date={props.dataFromParent.registrationDeadline} format="HH:mm:ss DD-MM-YYYY"/></h6>
                                        </p>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <h6 style={{textAlign: "left"}}>Loại hoạt động: <Tag color={props.dataFromParent.isPublic?"#87d068":"#f50"}>{props.dataFromParent.isPublic?"Công khai":"Nội bộ"}</Tag></h6>
                                    </Col>
                                </Row>
                                <Row span={24}>
                                    <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                        <h6 style={{textAlign: "left"}}>Người tạo: <Tag color={"#87d068"}>{props.dataFromParent.createrName}</Tag></h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6} xs={24} xl={6}>
                                <img src={serverAPIIp + props.dataFromParent.posterPath} style={{maxHeight: 100, textAlign: "center"}}/>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </NavLink>
        );
    }

    const getActivity = async(pageInput) =>{
        setLoading(true);
        const token = cookies.get('dtu-svc-token');
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        var requestData = {
            page: pageInput,
            pagesize: 20
        };
        await axios.get(serverAPIIp + 'api/ActivitiesAndEvents/GetListActivityByAdmin', {params: requestData, headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
                var totalNews = parseInt(response.data.message);
                setNewsCount(totalNews);
                var totalPage = Math.ceil(totalNews/20);
                setPageCout(totalPage);
                setNewspaperList(response.data.responseData);
            }else{
                setIsError500(true);
                setErrorMess(response.data.errors);
            }
            setLoading(false);
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    const onChangePage = page =>{
        setPage(page - 1);
        getActivity(page - 1);
    }

    useEffect(() => {
        getActivity(page);
    }, []);

    function AllActivity(){
        return(
            <div>
                {
                    loading?
                    <div>
                        <Card loading={loading}/>
                        <Card loading={loading}/>
                        <Card loading={loading}/>
                    </div>
                    :
                    <div>
                        {
                            newspaperList?
                            newspaperList.map((item) => <ActivityCard dataFromParent={item}/>)
                            :
                            <Result
                                status="404"
                                title="Không tìm thấy dữ liệu"
                                subTitle="Hiện tại không có hoạt động nào!"
                            />
                        }
                    </div>
                }
                <Pagination 
                    style={{marginTop: 15}}
                    total={newsCount} 
                    showTotal={(total, range) => `${range[0]}->${range[1]} / ${total}`}
                    defaultPageSize={20}
                    defaultCurrent={page}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            </div>
        )
    }

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Router>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <Layout style={{minHeight: "85vh"}}>
                    <Header style={{background:"#fff"}}>
                        <p className={"activetitle"}>{ TITLE }</p>
                    </Header>
                    <Content>
                        <Layout style={{background: "#fff"}}>
                            <Content>
                            <div style={{minHeight: "70vh"}}>
                                <Switch>
                                    <Route path={`${match.path}/:topicId`}>
                                        <ActivityAdminTopic/>
                                    </Route>
                                    <Route path={match.path}>
                                        <AllActivity/>
                                    </Route>
                                </Switch>
                            </div>
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            </Router>
        </div>
    )
}
export default ActivityList;