import React, {useEffect, useState} from 'react';
import {Table, Layout, message, DatePicker, Row, Col, Input, Form, Button, Modal, Tabs, Skeleton, Select, Switch, Result, Space, Tag } from 'antd';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faDownload, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons';

function SpendConfirmation(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const TITLE = "Xác Nhận Nộp/Nhận Tiền Quỹ";
    const { Content, Footer, Header } = Layout;
    const { Search } = Input;
    const { RangePicker } = DatePicker;
    const { TabPane } = Tabs;
    const [searchform] = Form.useForm();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [size, setSize] = useState(0);
    const [totalConfirm, setTotalConfirm] = useState(0);
    const [totalNotConfirm, setTotalNotConfirm] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [pathGetData, setPathGetData] = useState('api/Fund/GetListCollectMoney');

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function GetListSpendConfirmation(path){
        setIsLoading(true);
        const token = cookies.get('dtu-svc-token');
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }

        var content = searchform.getFieldValue("contentSearch");
        var datetime = searchform.getFieldValue("datetime");
        var startDate = "2019-11-18";
        var datetimeNow = new Date();
        datetimeNow.setDate(datetimeNow.getDate() + 1);
        var endDate = datetimeNow.toISOString().substring(0, 10);
        if(datetime){
            startDate = datetime[0].toISOString().substring(0, 10);
            endDate = datetime[1].toISOString().substring(0, 10)
        }
        const createCollectFundRequestModel = {
            'startDate': startDate,
            'endtDate': endDate,
            'content': content ? content : "",
            'page': page,
            'pageSize': pageSize,
        };
        axios.post(serverAPIIp + path, JSON.stringify(createCollectFundRequestModel), { headers })
        .then((response) =>{
            if(response.data.isSuccess){
                var messages = response.data.message.split(',');
                setSize(parseFloat(messages[0]));
                setTotalConfirm(parseFloat(messages[1]));
                setTotalNotConfirm(parseFloat(messages[2]));
                setData(response.data.responseData);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                    message.error(response.data.errors[0]);
                }
            }
            setIsLoading(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsLoading(false);
        });
    }

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },{
            title: "Ngày",
            dataIndex: "transactionDate",
            key: "transactionDate",
            width: 140,
            render: transactionDate => <span><Moment date={transactionDate} format="DD-MM-YYYY HH:mm:ss"/></span>
        },
        {
            title: "Số tiền",
            dataIndex: "money",
            key: "money",
            render: money => <span><CurrencyFormat value={money} displayType={'text'} thousandSeparator={true}/> VNĐ</span>
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
        },
        {
            title: "Thủ quỹ",
            dataIndex: "treasurer",
            render: (text, record) => <span>{record.treasurer.lastName} {record.treasurer.firstName}</span>
        },
        {
            title: "SĐT (TQ)",
            dataIndex: "phonenumber",
            render: (text, record) => <span>{record.treasurer.phoneNumber}</span>
        },
        {
            title: "Tình Trạng",
            dataIndex: "isConfirmed",
            key: "isConfirmed",
            render: isConfirmed => <Tag color={isConfirmed?"#108ee9": "#F50"}>{isConfirmed?"Đã Xác Nhận":"Chưa Xác Nhận"}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "id",
            render: (text, record) => 
                <Row>
                    <Col style={{padding: 2}}>
                        <Button 
                            type={"primary"} 
                            disabled={record.isConfirmed} 
                            onClick={() => {confirmSpend(record)}}
                            icon={
                                <FontAwesomeIcon 
                                    style={{marginRight: 10}} 
                                    icon={faCheckCircle} 
                                />}
                        >{record.isConfirmed? "Đã Xác Nhận" : "Xác Nhận"}</Button>
                    </Col>
                </Row>
        },
    ]

    const columnsPaid = [
        {
            title: "STT",
            dataIndex: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },{
            title: "Ngày",
            dataIndex: "transactionDate",
            key: "transactionDate",
            width: 140,
            render: transactionDate => <span><Moment date={transactionDate} format="DD-MM-YYYY HH:mm:ss"/></span>
        },
        {
            title: "Thủ quỹ",
            dataIndex: "treasurer",
            render: (text, record) => <span>{record.treasurer.lastName} {record.treasurer.firstName}</span>
        },
        {
            title: "SĐT (TQ)",
            dataIndex: "phonenumber",
            render: (text, record) => <span>{record.treasurer.phoneNumber}</span>
        },
        {
            title: "Số tiền",
            dataIndex: "money",
            key: "money",
            render: money => <span><CurrencyFormat value={money} displayType={'text'} thousandSeparator={true}/> VNĐ</span>
        },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
        },
        {
            title: "Tình Trạng",
            dataIndex: "isConfirmed",
            key: "isConfirmed",
            render: isConfirmed => <Tag color={isConfirmed?"#108ee9": "#F50"}>{isConfirmed?"Đã Xác Nhận":"Chưa Xác Nhận"}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "id",
            render: (text, record) => 
                <Row>
                    <Col style={{padding: 2}}>
                        <Button 
                            type={"primary"} 
                            disabled={record.isConfirmed} 
                            onClick={() => {confirmPaid(record)}}
                            icon={
                                <FontAwesomeIcon 
                                    style={{marginRight: 10}} 
                                    icon={faCheckCircle} 
                                />}
                        >{record.isConfirmed? "Đã Xác Nhận" : "Xác Nhận"}</Button>
                    </Col>
                </Row>
        },
    ]

    function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
        //   console.log(e)
        }
    };

    function confirmSpend(collect) {
        var conent = "Bạn đang tiến hành xác nhận rằng: Bản thân bạn đã nộp cho thũ quỹ số tiền : " + formatMoney(collect.money) + " VNĐ. Hãy chắc chắn đó là điều bạn muốn!";
        Modal.confirm({
          title: 'Xác nhận tiền đã nộp!',
          icon: <ExclamationCircleOutlined />,
          content: conent,
          okText: 'Xác Nhận',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{confirmSpendClick(collect)}
        });
    }
    function confirmPaid(paid) {
        var conent = "Bạn đang tiến hành xác nhận rằng: Bản thân bạn đã nhận từ thủ quỹ số tiền : " + formatMoney(paid.money) + " VNĐ. Hãy chắc chắn đó là điều bạn muốn!";
        Modal.confirm({
          title: 'Xác nhận đã nhận tiền từ thủ quỹ!',
          icon: <ExclamationCircleOutlined />,
          content: conent,
          okText: 'Xác Nhận',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{confirmSpendClick(paid)}
        });
    }

    function confirmSpendClick(collect){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config  = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                spendMoneyOrCollectMoneyId: collect.id,
            };
            axios.get(serverAPIIp + 'api/Fund/PaymentConfirmation', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Xác nhận thành công");
                    var collectList = [];
                    data.forEach((item) =>{
                        if(item.id === collect.id){
                            item.isConfirmed = true;
                        }
                        collectList.push(item);
                    });
                    setData(collectList);
                    var totalMoney = totalConfirm + collect.money;
                    setTotalConfirm(totalMoney);
                    var totalMoneyNotConfirm = totalNotConfirm - collect.money;
                    setTotalNotConfirm(totalMoneyNotConfirm);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function pagination(page, pageSize){
        setPage(page);
        setPageSize(pageSize);
    }

    function callback(key) {
        if(key==="1"){
            setPathGetData('api/Fund/GetListCollectMoney');
        }else{
            setPathGetData('api/Fund/GetListSpendMoney');
        }
    }

    useEffect(() => {
        GetListSpendConfirmation(pathGetData);
    }, [pathGetData]);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>{ TITLE }</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        <div style={{minHeight: "70vh"}}>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Nộp tiền" key="1">
                                <Form form={searchform}>
                                    <Row>
                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                            <Form.Item 
                                                name="datetime" 
                                                label="Ngày:"
                                            >
                                                <RangePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                            <Form.Item 
                                                name="contentSearch" 
                                                label="Nội dung:"
                                            >
                                                <Search placeholder="Nhập nội dung" 
                                                onSearch={() => GetListSpendConfirmation(pathGetData)}
                                                enterButton />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                {
                                    data.length > 0?
                                    <div>
                                        <Table 
                                            key={data.id}
                                            columns={columns} 
                                            dataSource={data} 
                                            loading={isLoading}
                                            pagination={{
                                                onChange: (page, pageSize) =>{
                                                    pagination(page, pageSize);
                                                },
                                                current: page,
                                                pageSize: pageSize,
                                                total: size
                                            }}
                                            scroll={{x: 1000}}/>
                                        <h4 style={{textAlign:"right"}}>Tổng(XN): <CurrencyFormat value={totalConfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                        <h4 style={{textAlign:"right"}}>Tổng(C): <CurrencyFormat value={totalNotConfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                    </div>
                                    :
                                    <Result
                                        status="404"
                                        title="Ối dồi ôi, dữ liệu đâu hết rồi?"
                                        subTitle="Xin lỗi vì đã xảy ra vấn đề này. Nhưng thực sự bạn chưa bao giờ nộp tiền cho thủ quỹ"
                                    />
                                }
                            </TabPane>
                            <TabPane tab="Nhận tiền" key="2">
                            <Form form={searchform}>
                                    <Row>
                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                            <Form.Item 
                                                name="datetime" 
                                                label="Ngày:"
                                            >
                                                <RangePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                            <Form.Item 
                                                name="contentSearch" 
                                                label="Nội dung:"
                                            >
                                                <Search placeholder="Nhập nội dung" 
                                                onSearch={() => GetListSpendConfirmation(pathGetData)}
                                                enterButton />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                {
                                    data.length > 0?
                                    <div>
                                        <Table 
                                            key={data.id}
                                            columns={columnsPaid} 
                                            dataSource={data} 
                                            loading={isLoading}
                                            pagination={{
                                                onChange: (page, pageSize) =>{
                                                    pagination(page, pageSize);
                                                },
                                                current: page,
                                                pageSize: pageSize,
                                                total: size
                                            }}
                                            scroll={{x: 1000}}/>
                                        <h4 style={{textAlign:"right"}}>Tổng(XN): <CurrencyFormat value={totalConfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                        <h4 style={{textAlign:"right"}}>Tổng(C): <CurrencyFormat value={totalNotConfirm} displayType={'text'} thousandSeparator={true} /> VNĐ</h4>
                                    </div>
                                    :
                                    <Result
                                        status="404"
                                        title="Ối dồi ôi, dữ liệu đâu hết rồi?"
                                        subTitle="Xin lỗi vì đã xảy ra vấn đề này. Nhưng thực sự bạn chưa bao giờ nhận tiền từ thủ quỹ"
                                    />
                                }
                            </TabPane>
                        </Tabs>
                        
                        </div>
                    </Layout>
                </Content>
            </Layout>
        </div>
    );
}
export default SpendConfirmation;