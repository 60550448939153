import { Component } from 'react';
import './css/navbar.css';
import './css/login.css';
import 'antd/dist/antd.css';
import LogoCLB from './images/Logo-CLBTinhNguyenSVDuyTan.png';
import Newspaper from './pages/Newspaper';
import Activities from './pages/Activities';
import Recruitment from './pages/Recruitment';
import { Helmet } from 'react-helmet';
import Contact from './pages/Contact-Us';
import Sponsor from './pages/Sponsor-Us';
import PersonalInfo from './pages/Personal-Information';
import Dashboard from './pages/Dashboard';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Modal, Button, Input, Form, Dropdown, Menu, message } from 'antd';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDonate, faHiking, faIdCard, faInfoCircle, faNewspaper, faSignOutAlt, faTachometerAlt, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from "react-router-dom";

class NavbarComp extends Component {
    state = {
        modalVisible: false,
        loading: false,
        usernamevl: '',
        passwordvl: '',
        studenid: '',
        isForgotPassword: false,
        serverAPIIp: 'https://api.dtusvc.com/',
    };

    changeLoginPage() {
        this.setState({ isForgotPassword: !this.state.isForgotPassword });
    }

    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
    }

    forGotPass = async () => {
        this.setState({ loading: true })
        const cookies = new Cookies();
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
            }
            const member = {
                studenId: this.state.studenid
            }
            axios.get(this.state.serverAPIIp + 'api/Account/ForgotPassword', { params: member, headers: config })
                .then((response) => {
                    if (response.data.isSuccess) {
                        message.success(response.data.message);
                        this.setState({
                            modalVisible: false,
                            isForgotPassword: false
                        });
                    } else {
                        message.error(response.data.errors[0]);
                    }
                    this.setState({ loading: false })
                })
                .catch((response) => {
                    message.error("Mất kết nối máy chủ");
                    this.setState({ loading: false })
                })
        }
    }

    setModalOk = async () => {
        this.setState({ loading: true })
        const LoginModelRequest = {
            'username': this.state.usernamevl,
            'password': this.state.passwordvl
        };

        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }

        axios.post(this.state.serverAPIIp + 'api/Account/Authenticate', JSON.stringify(LoginModelRequest), { headers })
            .then((response) => {
                if (response.data.isSuccess) {
                    const cookies = new Cookies();
                    cookies.set('dtu-svc-fullname', response.data.loginData.lastName + ' ' + response.data.loginData.firstName, { path: '/' });
                    cookies.set('dtu-svc-accountId', response.data.loginData.accountId, { path: '/' });
                    cookies.set('dtu-svc-memberId', response.data.loginData.memberId, { path: '/' });
                    cookies.set('dtu-svc-avatarPath', response.data.loginData.avatarPath, { path: '/' });
                    cookies.set('dtu-svc-token', response.data.loginData.token, { path: '/' });
                    cookies.set('dtu-svc-roleId', response.data.loginData.roleId, { path: '/' });
                    cookies.set('dtu-svc-role', response.data.loginData.role, { path: '/' });
                    cookies.set('dtu-svc-studentId', response.data.loginData.studentId, { path: '/' });
                    cookies.set('dtu-svc-isDefaulePassword', response.data.loginData.isDefaulePassword, { path: '/' });
                    this.setState({
                        loading: false,
                        modalVisible: false
                    });
                    if (response.data.loginData.isDefaulePassword) {
                        window.location.href = "/FirstSignIn";
                    } else {
                        message.success("Đăng nhập thành công");
                        window.location.reload();
                    }
                } else {
                    message.error(response.data.errors[0]);
                    this.setState({
                        loading: false
                    })
                }

            })
            .catch((response) => {
                message.error("Đăng nhập thất bại");
                this.setState({
                    loading: false
                })
            });
    }

    removeCookie() {
        const cookies = new Cookies();
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        cookies.remove("dtu-svc-isDefaulePassword")
        window.location.href = "/";
    }

    kickout(token) {
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        axios.get(this.state.serverAPIIp + 'api/Account/Logout', { headers: headers })
            .then((response) => {
                message.success("Đã đăng xuất");
            })
            .catch((response) => {
            })
    }

    logout() {
        const cookies = new Cookies();
        const token = cookies.get('dtu-svc-token');
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        axios.get(this.state.serverAPIIp + 'api/Account/Logout', { headers: headers })
            .then((response) => {
                this.removeCookie();
            })
            .catch((response) => {
                if (response.toString().indexOf(401) >= 0) {
                    message.error("Phiên đăng nhập đã hết hạn");
                    this.removeCookie();
                }
                Modal.error({
                    title: 'Mất kết nối với máy chủ',
                    content: 'Hãy kiểm tra lại kết nối của bạn và thử lại',
                });
            })
    }

    handleCancel(modalVisible) {
        this.setState({ modalVisible });
    }

    handleUserNameInput(e) {
        this.setState({ usernamevl: e.target.value });
    }

    handlePasswordInput(e) {
        this.setState({ passwordvl: e.target.value });
    }

    handleStudentInput(e) {
        this.setState({ studenid: e.target.value });
    }

    render() {
        const { loading, isForgotPassword } = this.state;
        const cookies = new Cookies();
        const token = cookies.get('dtu-svc-token');
        const fullName = cookies.get('dtu-svc-fullname');
        const avatarPath = this.state.serverAPIIp + cookies.get('dtu-svc-avatarPath');
        window.onload = () => {
            if (cookies.get("dtu-svc-isDefaulePassword") === "true") {
                this.kickout(token);
                this.removeCookie();
            }
            // const token = cookies.get('dtu-svc-token');
            // if(token != null){
            //     const headers = {
            //         "Content-Type": "application/json",
            //         "accept": "*/*",
            //         "Authorization": 'Bearer ' + token
            //     }
            //     axios.get(this.state.serverAPIIp +'api/Account/CheckLogin', {headers: headers})
            //     .then((response) =>{
            //         if(!response.data.isSuccess){
            //             this.logout();
            //             Modal.error({
            //                 content: 'Phiên đăng nhập đã hết hạn',
            //             });
            //             window.location.href="/";
            //             console.log(response.data.isSuccess);
            //         }
            //     })
            //     .catch((response)=>{
            //         this.logout();
            //         Modal.error({
            //             content: 'Phiên đăng nhập đã hết hạn',
            //         });
            //         window.location.href="/";
            //     })
            // }
        }

        const menu = (
            <Menu>
                <Menu.Item>
                    <Nav.Link as={NavLink} to={"/membereinfo"} ><FontAwesomeIcon style={{ marginRight: 5 }} icon={faIdCard} />Thông Tin Cá Nhân</Nav.Link>
                </Menu.Item>

                <Menu.Item>
                    <Nav.Link as={NavLink} to={"/dashboard"} ><FontAwesomeIcon style={{ marginRight: 5 }} icon={faTachometerAlt} />Bảng Điều Khiển</Nav.Link>
                </Menu.Item>
                <Menu.Item danger onClick={() => this.logout()}><FontAwesomeIcon style={{ marginRight: 5 }} icon={faSignOutAlt} />Đăng Xuất</Menu.Item>
            </Menu>
        );


        return (
            <Router>
                <div>
                    <Navbar className="navbar container-fluid nav" bg="primary" expand="lg">
                        <Navbar.Brand className="logo" as={NavLink} to={"/"}>
                            <img src={LogoCLB} height={45} />
                        </Navbar.Brand>
                        <Navbar.Toggle className="navbar-toggler" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto navbaritem  nav navbar-nav mx-auto">
                                <Nav.Link as={NavLink} to={"/newspaper"} ><FontAwesomeIcon style={{ marginRight: 2 }} icon={faNewspaper} />Tin Tức
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={"/activities"} ><FontAwesomeIcon style={{ marginRight: 2 }} icon={faHiking} />Hoạt Động
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={"/recruitment"} ><FontAwesomeIcon style={{ marginRight: 2 }} icon={faUserPlus} />Tuyển Thành Viên
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={"/contact-us"} ><FontAwesomeIcon style={{ marginRight: 2 }} icon={faInfoCircle} />Thông Tin
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={"/sponsor-us"} ><FontAwesomeIcon style={{ marginRight: 2 }} icon={faDonate} />Tài Trợ
                                </Nav.Link>
                            </Nav>
                            <div>
                                {
                                    token == null ?
                                        <div className="col-md-4" id="loginbutton">
                                            <Button className="btnloginclass" onClick={() => this.setModalVisible(true)}><a><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                                                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                            </svg>  Đăng Nhập</a>
                                            </Button>
                                        </div>
                                        :
                                        <Dropdown overlay={menu} placement="bottomCenter" arrow>
                                            <div className={"userdropdown"} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                                <img src={avatarPath} height={40} width={40} />
                                                <span>{fullName}</span>
                                            </div>
                                        </Dropdown>
                                }
                            </div>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                {!isForgotPassword ?
                    <div className="ant-modal-content">
                        <Modal title="Đăng nhập"
                            visible={this.state.modalVisible}
                            onCancel={() => this.handleCancel(false)}
                            cancelButtonProps={{ disabled: loading }}
                            footer={[
                                <Button key="back" onClick={() => this.handleCancel(false)}>
                                    Thoát
                                </Button>,
                                <Button key="submit" type="primary" loading={loading} onClick={() => this.setModalOk()}>
                                    Đăng nhập
                                </Button>
                            ]}
                            centered
                        >
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                // onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                                >
                                    <Input placeholder="Tên đăng nhập" className="inputloginuser"
                                        onChange={(e) => this.handleUserNameInput(e)}
                                        value={this.state.usernamevl} />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                                >
                                    <Input.Password placeholder="Mật khẩu" className="inputloginpass"
                                        onChange={(e) => this.handlePasswordInput(e)}
                                        value={this.state.passwordvl}></Input.Password>
                                </Form.Item>
                            </Form>
                            <a className="forgotpass" onClick={(e) => this.changeLoginPage()}>Quên mật khẩu?</a>
                        </Modal>
                    </div>
                    :
                    <div className="ant-modal-content">
                        <Modal title="Quên mật khẩu"
                            visible={this.state.modalVisible}
                            onCancel={() => this.handleCancel(false)}
                            cancelButtonProps={{ disabled: loading }}
                            footer={[
                                <Button key="back" onClick={() => this.handleCancel(false)}>
                                    Thoát
                                </Button>,
                                <Button key="submit" type="primary" loading={loading} onClick={() => this.forGotPass()}>
                                    Xác nhận
                                </Button>
                            ]}
                            centered
                        >
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                // onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="studentid"
                                    rules={[{ required: true, message: 'Nhập mã sinh viên của bạn' }]}
                                >
                                    <Input placeholder="Mã sinh viên" className="inputloginuser"
                                        onChange={(e) => this.handleStudentInput(e)}
                                        value={this.state.studenid} />
                                </Form.Item>
                            </Form>
                            <a className="forgotpass" onClick={(e) => this.changeLoginPage()}>Đăng nhập</a>
                        </Modal>
                    </div>
                }
                <div>
                    <Switch>
                        <Route path="/dashboard"
                            render={() => {
                                if (cookies.get('dtu-svc-token') === undefined) {
                                    return (
                                        <Redirect to="/" />
                                    );
                                } else {
                                    return (
                                        <Dashboard />
                                    )
                                }
                            }}
                        />
                        <Route path="/membereinfo"
                            render={() => {
                                if (cookies.get('dtu-svc-token') === undefined) {
                                    return (
                                        <Redirect to="/" />
                                    )
                                } else {
                                    return (
                                        <PersonalInfo />
                                    )
                                }
                            }}
                        />
                        <Route path="/" exact render={() => {
                            return (
                                <Redirect to="/newspaper" />
                            )
                        }} />
                        <Route path="/newspaper">
                            <Newspaper />
                        </Route>
                        <Route path="/activities">
                            <Activities />
                        </Route>
                        <Route path="/recruitment" exact>
                            <Recruitment />
                        </Route>
                        <Route path="/contact-us" exact>
                            <Contact />
                        </Route>
                        <Route path="/sponsor-us" exact>
                            <Sponsor />
                        </Route>
                        <Route path="/membereinfo" exact>
                            <PersonalInfo />
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default NavbarComp;