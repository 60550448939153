import React, {useEffect, useState, useRef}  from 'react';
import { Table, message, DatePicker, Row, Col, Input, Form, Button, Modal, Skeleton, Result, Space, Tag, Switch } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ImageResize } from "./ImageResize";
import "react-quill/dist/quill.snow.css";
import Preview from "./Preview";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSave, faTrash, faPaperPlane, faCheckCircle, faLock, faLockOpen, faFileExcel, faClosedCaptioning, faCircle} from '@fortawesome/free-solid-svg-icons';
import './css/createrecuit.css';
import { Video } from "./quill-video-resize";
import "./css/quill-video-resize.css";
import moment from 'moment';
import {SearchOutlined} from '@ant-design/icons';
import { ExclamationCircleOutlined, FacebookOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Moment from 'react-moment';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    useRouteMatch,
    useParams
} from "react-router-dom";
import TextArea from 'antd/lib/input/TextArea';

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register({ "formats/video": Video });
const { RangePicker } = DatePicker;
const cookies = new Cookies();
const serverAPIIp = 'https://api.dtusvc.com/';
var upLoadImages = [];
var addImage = false;

function removeCookie(){
    cookies.remove('dtu-svc-fullname');
    cookies.remove('dtu-svc-accountId');
    cookies.remove('dtu-svc-avatarPath');
    cookies.remove('dtu-svc-token');
    cookies.remove('dtu-svc-roleId');
    cookies.remove('dtu-svc-role');
    cookies.remove('dtu-svc-studentId');
    window.location.href="/";
}

function RemoveVietnameseAccents(str) {
    str = str.replace(/\s+/g, ' ');
    str = str.trim();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
 }

const modules = {
    toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [{ color: [] }, { background: [] }],

    [{ align: [] }],
    [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
    ],
    [{ direction: "rtl" }],
    ["link", "image", "video"],
    ["clean"]
    ],
    clipboard: {
    matchVisual: false
    },
    history: {
    delay: 1000,
    maxStack: 50,
    userOnly: false
    },
    imageUploader: {
    upload: async (file) => {
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
        headers: { 
            'content-type': 'multipart/form-data', 
            'Authorization': 'Bearer ' + token, 
            'accept': '*/*'
            }
        };
        fmData.append('image', file);
        try{
            const res = await axios.post(serverAPIIp + 'api/Newspaper/UploadImage', fmData, config );
            if(res.data.isSuccess){
                addImage = true;
                var images = upLoadImages;
                images.push(res.data.responseData);
                upLoadImages = images;
                message.success("Thêm ảnh thành công");
                return serverAPIIp  + res.data.responseData;
            }else{
                if(res.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(res.data.errors[0]);
                return null;
            }
        }catch (err) {
            if(err.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            message.error("Thêm ảnh thất bại");
            return null;
        }
    }
    },
    imageResize: {
    displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white"
    },
    modules: ["Resize", "DisplaySize", "Toolbar"]
    }
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "color",
    "background",
    "font",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "clean",
    "code",
    "formula"
];

function removeImage(imagepath){
const token = cookies.get('dtu-svc-token');
if(token !== null){
    const config = {
        "Content-Type": "application/json",
        "accept": "*/*",
        "Authorization": 'Bearer ' + token
    }
    const removeImageModel = {
        "imagePath": imagepath
    }
    axios.post(serverAPIIp + 'api/Newspaper/RemoveImage', JSON.stringify(removeImageModel), {headers: config})
    .then((response) =>{
        if(response.data.isSuccess){
        }else{
            if(response.data.errors[0].indexOf("(401)") >= 0){
                removeCookie();
            }
            message.error(response.data.errors[0]);
        }
    })
    .catch((response)=>{
        if(response.toString().indexOf(401) >= 0){
            removeCookie();
            message.error("Phiên đăng nhập đã hết hạn");
        }else{
            message.error("Mất kết nối máy chủ");
        }
    })
}
}


export default function RecruitTopic(){
    const [form] = Form.useForm();
    const [scoresform] = Form.useForm();
    const [sendmailform] = Form.useForm();
    const [approveForm] = Form.useForm();
    const [value, setValue] = useState("");
    const [contentNull, setContentNull] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [isCreading, setIsCreading] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [isDateNull, setDateIsNull] = useState(true);
    const [titleIsNull, setTitleIsNull] = useState(true);
    const [recruit, setRecruit] = useState(null);
    const [memberRergister, setMemberRegister] = useState(null);
    const dateFormat = 'YYYY-MM-DD hh:mm';
    const [isRecruitLoading, setIsRecruitLoadding] = useState(true);
    const [isMemberLoading, setIsMemberLoadding] = useState(true);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [selectedRowKeys, setSelectedRowKeys ] = useState([]);
    const [exportDisable, setExportDisable] = useState(true);
    const [isOpenSendMail, setIsOpenSendMail] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [isOpenMember, setIsOpenMember] = useState(false);
    const [isGetMemberLoading, setIsGetMemberLoading] = useState(true);
    const [member, setMember] = useState(null);
    const [scoresInput, setScoresInput] = useState(null);
    const [isLoadingScores, setIsLoadingScores] = useState(false);
    const [isApproveLoading, setIsApproveLoading] = useState(false);
    const [isDownLoading, setIsDownLoading] = useState(false);
    const [isDateToDate, setIsDateToDate] = useState(true);
    const [memberSelectIsNull, setMemberSelectIsNull] = useState(true);
    const [memberSelect, setMemberSelect] = useState(null);
    const [activityHistory, setActivityHistory] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const searchInput = useRef(null);
    const [endOfRecruitment, setEndOfRecruitment] = useState(true);
    const [roleId, setRoleId] = useState(parseInt(cookies.get('dtu-svc-role')));
    const [roleCread, setRoleCread] = useState([1, 2]);
    const [bIsShowApprove, setIsShowApprove] = useState(false);
    let { topicId } = useParams();
    const handleChange = (data) => {
        setValue(data);
        var images = []
        upLoadImages.forEach((image) =>{
            if(data.indexOf(serverAPIIp + image) < 0 && !addImage){
                removeImage(image);
            }else{
                images.push(image);
            }
        })
        setIsChange(true);
        upLoadImages = images;
        addImage = false;
        setContentNull(data === "<p><br></p>");
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);}}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Tìm
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Đặt Lại
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select());
          }
        },
        render: text =>
            searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    function confirm() {
        Modal.confirm({
          title: 'Xoá đợt tuyển thành viên!',
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteClick()}
        });
    }

    function confirmEnd() {
        Modal.confirm({
          title: 'Kết thúc thành viên!',
          icon: <ExclamationCircleOutlined />,
          content: 'Khi bạn kết thúc tuyển thành viên, hệ thống sẽ tự gửi email từ chối tới những thành viên chưa được phê duyệt và khoá hoàn toàn mọi thao tác với đợt tuyển thành viên này. Bạn có chắc chắn muốn kết thúc hay không?',
          okText: 'Kết thúc',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{endRecruitClick()}
        });
    }

    function deteleMemberConfirm(member) {
        Modal.confirm({
          title: 'Xoá '.concat(member.lastName, ' ' , member.firstName, '!'),
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteMemberClick(member.id)}
        });
    }

    function deleteMemberClick(memberId){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config  = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                memberRegisterId: memberId
            };
            axios.get(serverAPIIp + 'api/Recruit/RemoveMembereRegister', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Xoá thành công");
                    var members = [];
                    memberRergister.forEach((item) => {
                        if(item.id !== memberId){
                            members.push(item);
                        }
                    })
                    setMemberRegister(members);
                    var idmember = [];
                    selectedRowKeys.forEach((item) =>{
                        if(item !== memberId){
                            idmember.push(item);
                        }
                    })
                    setSelectedRowKeys(idmember)
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function saveRecruit(){
        setIsCreading(true);
        var timeSlide = form.getFieldValue("timeline");
        var title = form.getFieldValue("title");
        var valid = true;
        if(timeSlide === null || timeSlide === undefined){
            valid = false;
            form.setFields([{
                name: "timeline",
                errors:["Vui lòng nhập thời gian tuyển!"]
            }]);
        }

        if(title === undefined || title === null){
            valid = false;
            form.setFields([{
                name: "title",
                errors:["Vui lòng nhập tiêu đề!"]
            }]);
        }else{
            if(title.replace(/ /g, '') === ''){
                valid = false;
                form.setFields([{
                    name: "title",
                    errors:["Vui lòng nhập tiêu đề!"]
                }]);
            }
        }
        
        if(value === null || value === "<p><br></p>" || value === ""){
            valid = false;
            setContentNull(true);
        }
        const token = cookies.get('dtu-svc-token');
            if(token !== null && valid){
                var startDate = timeSlide[0].toISOString();
                var endDate = timeSlide[1].toISOString();
                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }

                const changeRecruitRequestModel = {
                    id: recruit.id,
                    recruitName: title,
                    recruitContent: value,
                    dateStart: startDate,
                    dateEnd: endDate
                }
                
                var path = 'api/Recruit/ChangeRecruit'
                var data = JSON.stringify(changeRecruitRequestModel);
                axios.post(serverAPIIp + path, data, {headers})
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Cập nhật thành công");
                        setIsCreading(false);
                        window.location.href="/dashboard/recruitment/recruitlist";
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors);
                        setIsCreading(false);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối máy chủ");
                    }
                    setIsCreading(false);
                })
            }
    }

    function deleteClick(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            if(recruit){
                const config  = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    recruitId: recruit.id
                };
                axios.get(serverAPIIp + 'api/Recruit/RemoveRecruit', {headers:config, params:requestData})
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Xoá thành công");
                        form.setFieldsValue({
                            title: "",
                            timelin: undefined
                        });
                        setValue("");
                        if(upLoadImages){
                            upLoadImages.forEach((item) => {
                                removeImage(item);
                            })
                        }
                        upLoadImages = [];
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors[0]);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối máy chủ");
                    }
                })
            }else{
                message.error("Bạn chỉ được xoá bản Tuyển thành viên vừa tạo");
            }
        }
    }

    function endRecruitClick(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config  = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                recruitId: topicId
            };
            axios.get(serverAPIIp + 'api/Recruit/EndRecruitById', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    window.location.reload();
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function onChangeDate(e){
        setDateIsNull(e === null);
        setIsChange(true);
    }

    function onChangeTitle(e){
        setTitleIsNull(e === null);
        setIsChange(true);
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment(recruit.dateStart).add(7, 'hours');
    }

    function disabledDateSendMail(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    const GetRecruitById = async() =>{
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            recruitId: topicId
        };
        await axios.get(serverAPIIp + 'api/Recruit/GetRecruitById', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                setRecruit(response.data.responseData);
                setValue(response.data.responseData.recruitContent);
                form.setFieldsValue({
                    title: response.data.responseData.recruitName,
                    timeline: [moment(response.data.responseData.dateStart, dateFormat).add(7, 'hours'), moment(response.data.responseData.dateEnd, dateFormat).add(7, 'hours')]
                });
                setIsLocked(response.data.responseData.isLocked);
                setEndOfRecruitment(response.data.responseData.endOfRecruitment);
                setDateIsNull(false);
                setTitleIsNull(false);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
                setIsError500(true);
                setErrorMess(response.data.errors);
            }
            setIsRecruitLoadding(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsRecruitLoadding(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    const GetMemberRegister = async() =>{
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            recruitId: topicId
        };
        await axios.get(serverAPIIp + 'api/Recruit/GetMemberRegisterByRecruitID', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                var members = [];
                var number = 1; 
                    response.data.responseData.forEach(item => {
                        members.push({
                            key: item.id,
                            studentId: item.studentId,
                            lastName: item.lastName,
                            firstName: item.firstName,
                            sex: item.sex,
                            phoneNumber: item.phoneNumber,
                            facultyName: item.facultyName,
                            isMember: item.isMember,
                            approve: item.approve,
                            approverName: item.approverName,
                            birthDay: item.birthDay,
                            className: item.className,
                            districtName: item.districtName,
                            email: item.email,
                            id: item.id,
                            provinceName: item.provinceName,
                            registrationDate: item.registrationDate,
                            scores: item.scores,
                            sendEmail: item.sendEmail,
                            wardName: item.wardName,
                            linkFacebook: item.linkFacebook,
                            no: number
                        });
                        number = number + 1;
                    });
                setMemberRegister(members);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
                setIsError500(true);
                setErrorMess(response.data.errors);
            }
            setIsMemberLoadding(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsMemberLoadding(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    const GetMemberRegisterById = async(id) =>{
        setIsGetMemberLoading(true);
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            memberRegisterId: id
        };
        await axios.get(serverAPIIp + 'api/Recruit/GetMemberRegister', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                setMember(response.data.responseData);
                setActivityHistory(response.data.responseData.activityHistory);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
            }
            setIsGetMemberLoading(false);
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsGetMemberLoading(false);
        })
    }

    const LockOrUnlockRecruit = async(id) =>{
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            recruitId: id
        };
        await axios.get(serverAPIIp + 'api/Recruit/LockOrUnlockRecruit', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                message.success(isLocked? "Mở khoá thành công" : "Khoá thành công")
                setIsLocked(!isLocked);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
            }
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
        })
    }

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Ngày Đăng Ký",
            dataIndex: "registrationDate",
            key: "registrationDate",
            width: 110,
            sorter: {
                compare: (a, b) => moment(a.registrationDate).unix() - moment(b.registrationDate).unix(),
                multiple: 1,
            },
            render: registrationDate =>
                <Moment date={registrationDate} format="hh:mm:ss DD/MM/YYYY"/>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            width: 100,
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            ...getColumnSearchProps('lastName'),
        },
        {
            title: "Tên",
            dataIndex: "firstName",
            key: "firstName",
            width: 50,
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            ...getColumnSearchProps('firstName'),
        },
        {
            title: "Giới Tính",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            render : sex => <span>{sex?"Nam":"Nữ"}</span>
        },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
            width: 120,
            sorter: (a, b) => a.facultyName.localeCompare(b.facultyName),
            ...getColumnSearchProps('facultyName'),
        },
        {
            title: "Lớp",
            dataIndex: "className",
            key: "className",
            width: 80,
            ...getColumnSearchProps('className'),
        },
        {
            title: "Gửi Email",
            dataIndex: "sendEmail",
            key: "sendEmail",
            width: 50,
            filters: [{
                text: "Đã gửi",
                value: true,
            },{
                text: "Chưa gửi",
                value: false,
            }],
            onFilter: (value, record) => record.sendEmail === value,
            render: sendEmail => <Tag color={sendEmail?"success":"error"}>{sendEmail?"Đã Gửi":"Chưa"}</Tag>
        },
        {
            title: "Tình Trạng",
            dataIndex: "approve",
            key: "approve",
            width: 50,
            filters: [{
                text: "Đã duyệt",
                value: true,
            },{
                text: "Đang chờ",
                value: false,
            }],
            onFilter: (value, record) => record.approve === value,
            render: approve => <Tag color={approve?"success":"error"}>{approve?"Duyệt":"Chờ"}</Tag>
        },
        {
            title: "Điểm Phỏng Vấn",
            dataIndex: "scores",
            key: "scores",
            width: 50,
            sorter: {
                compare: (a, b) => a.scores - b.scores,
                multiple: 1,
            },
            ...getColumnSearchProps('scores'),
            render: scores => <Tag color={scores > 5 ?"success":"error"}>{scores}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            render: (text, record) => 
            <Row>
                <Col style={{padding: 2}}>
                    <Button 
                        type={"primary"} 
                        title="Thông tin cá nhân"
                        onClick={()=>{setIsOpenMember(true); GetMemberRegisterById(record.id);}}
                        icon={<FontAwesomeIcon icon={faEye} />}/>
                </Col>
                <Col style={{padding: 2}}>
                    <Button 
                    title="Mở Facebook"
                    onClick={()=> window.open(record.linkFacebook, "_blank")}  
                    type={"primary"} 
                    icon={<i className="fab fa-facebook-f"></i>}/>
                </Col>
                <Col style={{padding: 2}}>
                    <Button 
                    onClick={() => {deteleMemberConfirm(record);}} 
                    type={"primary"} 
                    title="Xoá"
                    disabled={record.approve || endOfRecruitment}
                    icon={<FontAwesomeIcon icon={faTrash} />} danger/>
                </Col>
            </Row>
        },
    ]

    const sendMailColumns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 60,
            render:(value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: "Ngày Đăng Ký",
            dataIndex: "registrationDate",
            key: "registrationDate",
            sorter: {
                compare: (a, b) => moment(a.registrationDate).unix() - moment(b.registrationDate).unix(),
                multiple: 1,
            },
            render: registrationDate =>
                <Moment date={registrationDate} format="hh:mm:ss DD/MM/YYYY"/>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            ...getColumnSearchProps('lastName'),
        },
        {
            title: "Tên",
            dataIndex: "firstName",
            key: "firstName",
            width: 100,
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            ...getColumnSearchProps('firstName'),
        },
        {
            title: "Giới Tính",
            dataIndex: "sex",
            key: "sex",
            width: 100,
            render : sex => <span>{sex?"Nam":"Nữ"}</span>
        },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
            sorter: (a, b) => a.facultyName.localeCompare(b.facultyName),
            ...getColumnSearchProps('facultyName'),
        },
        {
            title: "Lớp",
            dataIndex: "className",
            key: "className",
            ...getColumnSearchProps('className'),
        },
        {
            title: "Gửi Email",
            dataIndex: "sendEmail",
            key: "sendEmail",
            width: 100,
            render: sendEmail => <Tag color={sendEmail?"success":"error"}>{sendEmail?"Đã Gửi":"Chưa"}</Tag>
        },
        {
            title: "Tình Trạng",
            dataIndex: "approve",
            key: "approve",
            width: 100,
            render: approve => <Tag color={approve?"success":"error"}>{approve?"Duyệt":"Chờ"}</Tag>
        },
        {
            title: "Điểm",
            dataIndex: "scores",
            key: "scores",
            width: 100,
            sorter: {
                compare: (a, b) => a.scores - b.scores,
                multiple: 1,
            },
            ...getColumnSearchProps('scores'),
            render: scores => <Tag color={scores > 5 ?"success":"error"}>{scores}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            width: 100,
            render: (text, record) => 
            <Row>
                <Col style={{padding: 2}}>
                    <Button 
                    onClick={() => {sendMailDelete(record.id);}} 
                    type={"primary"} 
                    title="Xoá"
                    icon={<FontAwesomeIcon icon={faTrash} />} danger/>
                </Col>
            </Row>
        },
    ]

    const activityHistoryColumns = [
        {
            title: "STT",
            dataIndex: "number",
            key: "number",
            width: 45,
        },
        {
            title: "Chương Trình",
            dataIndex: "eventName",
            key: "eventName",
        },
        {
            title: "Giờ Vào",
            dataIndex: "inStart",
            key: "inStart",
            render: (text, record, index) => {
                if (record.isAdditionalScore) {
                  return {
                    children: <span>{record.reason}</span>,
                    props: {
                      rowSpan: 1,
                    },
                  };
                }
                return <span><Moment date={record.inStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
              },
        },
        {
            title: "Giờ Ra",
            dataIndex: "outStart",
            key: "outStart",
            editable: true,
            render: (text, record) => {
                if (record.isAdditionalScore) {
                  return null;
                }
                else
                {
                    if (record.score === 0) {
                        return <span style={{ color: 'red' }}>Chưa check out</span>;
                    }
                    else{
                        return <span><Moment date={record.outStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
                    }
                }
              },
        },
        {
            title: "Điểm",
            dataIndex: "score",
            key: "score",
            render: scores =>
                <Row align="middle">
                    <Col>
                    {
                        scores === 0?
                            <Tag color="#f50">{parseInt(scores)}</Tag>
                        :
                            <Tag color="#108ee9">{scores.toFixed(2)}</Tag>
                    }
                    </Col>
                </Row >
        },
        {
            title: "Tình Trạng",
            dataIndex: "isSuccsess",
            key: "isSuccsess",
            render: item => <Tag color={item?"#108ee9":"#f50"}>{item? "Hoàn Thành" : "Chưa Hoàn Thành"}</Tag>
        },
        {
            title: "Quản Lý",
            dataIndex: "managerName",
            key: "managerName",
        }
    ]

    function sendMailDelete(id){
        var members = []
        memberSelect.forEach((item) =>{
            if(id !== item.id){
                members.push(item);
            }
        });
        setMemberSelect(members);
        if(members.length === 0){
            setMemberSelectIsNull(true);
        }
    }

    function setVisible(value){
        setIsOpenMember(value);
    }

    function openSendMail(){
        setsearchedColumn('');
        setsearchText('');
        setIsSending(false);
        setMemberSelectIsNull(false);
        var members = [];
        var count = 1;
        selectedRowKeys.forEach((key)=>{
            var member = memberRergister.find(function(item){
                return item.id === key;
            });
            member.no = count;
            members.push(member);
            count = count + 1;
        })
        setMemberSelect(members);
        setIsOpenSendMail(true);
    }

    function setVisibleSendMail(){
        setsearchedColumn('');
        setsearchText('');
        setIsOpenSendMail(!isOpenSendMail);
    }

    const onSelectChange = selectedRowKeys => {
        if(selectedRowKeys.length > 0){
            setExportDisable(false);
        }else{
            setExportDisable(true);
        }
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            disabled: record.approve || endOfRecruitment,
          }),
        onChange: onSelectChange,
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
          Table.SELECTION_NONE,
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: changableRowKeys => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              });
              setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
            },
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: changableRowKeys => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              });
              setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
            },
          },
        ],
      };

    function onChangeScores(e){
        try{
            var fValue = parseFloat(e.target.value.replace(",","."));
            if(fValue < 0 || fValue > 10)
            {
                setScoresInput(null);
            }else{
                setScoresInput(fValue);
            }
        }catch{
            setScoresInput(null);
        }
    }

    function error(title, content) {
        Modal.error({
          title: title,
          content: content,
        });
      }

    const scoring = async() => {
        setIsLoadingScores(true);
        var reviewMess = scoresform.getFieldValue("review");
        if(reviewMess === null || reviewMess === undefined || reviewMess === ''){
            error("Đánh giá đang để trống!", "Đánh giá của bạn rất quan trọng trong việc xét duyệt sau này. Đừng để trống nhé.");
            setIsLoadingScores(false);
        }else{
            if(scoresInput === null || scoresInput === undefined || scoresInput === NaN || scoresform.getFieldError("scores").length > 0){
                error("Điểm không hợp lệ!", "Điểm phải là số nguyên hoặc số thập phân. Thang điểm được tính từ [0-10].");
                setIsLoadingScores(false);
            }else{
                const token = cookies.get('dtu-svc-token');
                const scoringRequestModel = {
                    'id': member.id,
                    'reviews': reviewMess,
                    'scores': scoresInput
                };

                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }

                await axios.post(serverAPIIp + 'api/Recruit/Scoring', JSON.stringify(scoringRequestModel), { headers })
                    .then((response) =>{
                        if(response.data.isSuccess){
                            message.success("Chấm điểm thành công");
                            GetMemberRegisterById(member.id);
                            GetMemberRegister();
                            scoresform.setFieldsValue({
                                review: null
                            })
                            scoresform.setFieldsValue({
                                scores: 0
                            })
                            setScoresInput(null);
                            setIsOpenMember(false);
                        }else{
                            if(response.data.errors[0].indexOf("(401)") >= 0){
                                removeCookie();
                            }
                            message.error(response.data.errors[0]);
                        }
                        setIsLoadingScores(false);
                    })
                    .catch((response)=>{
                        if(response.toString().indexOf(401) >= 0){
                            removeCookie();
                            message.error("Phiên đăng nhập đã hết hạn");
                        }else{
                            message.error("Mất kết nối với máy chủ");
                        }
                        setIsLoadingScores(false);
                    });
            }
        }
    }

    function error() {
        Modal.error({
          title: 'Chọn thành viên!',
          content: 'Để phê duyệt, trước tiên bạn cần phải chọn thành viên cần phê duyệt.',
        });
    }

    function errorApproval(count) {
        Modal.error({
          title: 'Đã xảy ra lỗi!',
          content: 'Hiện tại có '.concat(count, ' bản đăng ký chưa phê duyệt thành công. Nguyên nhân có thể do bạn chưa chấm điểm hoặc đã được phê duyệt trước đó. Hãy kiểm tra và thử lại nhé!'),
        });
    }

    const approving = async (memberId) =>{
        const token = cookies.get("dtu-svc-token");
        if(token !== null){
            setIsApproveLoading(true);
            var chatLink = approveForm.getFieldValue("chatLink");
            if(chatLink === undefined || chatLink.replace(/ /g, '') === ''){
                approveForm.setFields([{
                    name: "chatLink",
                    errors:["Vui lòng nhập link nhóm chat."]
                }]);
            }
            else{
                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    memberId: memberId,
                    recruitId: topicId,
                    linkChat: chatLink
                };
                await axios.post(serverAPIIp + 'api/Recruit/MemberApproval', JSON.stringify(requestData), { headers })
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success(response.data.message);
                        window.location.reload();
                    }else{
                        if((response.data.errors) && response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors);
                        setIsApproveLoading(false);
                    }
                })
                .catch((response)=>{
                    setIsApproveLoading(false);
                    if((response !== undefined) && (response.toString().indexOf(401) >= 0)){
                        // removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối với máy chủ");
                    }
                });
                setIsShowApprove(false);
            }
        }
    }

    function onApprove(){
        setIsApproveLoading(true);
        if(exportDisable){
            error();
            setIsApproveLoading(false);
        }else{
            var checkList = selectedRowKeys;
            
            var errorCout = 0;
            if(selectedRowKeys){
                approving(selectedRowKeys);
            }
            if(errorCout > 0){
                errorApproval(errorCout);
            }
            setSelectedRowKeys(checkList);
            GetMemberRegister();
            setIsApproveLoading(false);
        }
    }

    function sendMailOnClick(){
        var value = sendmailform.getFieldsValue();
        setIsSending(true);
        var isSuccsess = true;
        var address = value.address;
        var datetime = value.datereview;
        var peopleendtime = value.peopleendtime;
        var typereview = value.typereview;
        var error = []
        if(address === null || address === undefined || address === "" || address.replace(/ /g, '') === ''){
            isSuccsess = false;
            error.push({
                name: "address",
                errors: ["Vui lòng nhập địa chỉ phỏng vấn"]
            });
        }

        if(typereview === null || typereview === undefined || typereview === "" || typereview.replace(/ /g, '') === ''){
            isSuccsess = false;
            error.push({
                name: "typereview",
                errors: ["Vui lòng nhập hình thức phỏng vấn"]
            });
        }

        if(isDateToDate){
            if(peopleendtime === null || peopleendtime === undefined || peopleendtime === NaN || scoresform.getFieldError("peopleendtime").length > 0){
                isSuccsess = false;
                error.push({
                    name: "peopleendtime",
                    errors: ["Vui lòng nhập định mức hợp lệ"]
                });
            }
        }
        
        if(datetime === null || datetime === undefined){
            isSuccsess = false;
            error.push({
                name: "datetime",
                errors: ["Vui lòng nhập thời gian phỏng vấn"]
            });
        }
        
        sendmailform.setFields(error);
        if(isSuccsess){
            if(isDateToDate){
                var minutes = dateConverter(datetime[0], datetime[1]);
                var minutesNeeded = memberSelect.length * parseInt(peopleendtime);
                if(minutesNeeded > minutes){
                    warning(minutesNeeded, minutes, peopleendtime);
                    setIsSending(false);
                }else{
                    var timeStart = datetime[0];
                    memberSelect.forEach((item) =>{
                        setTimeout(function(){
                            const sendEmailModel = {
                                'memberId': item.id,
                                'interviewType': typereview,
                                'interviewTime': timeStart.toISOString(),
                                'interviewLocation':  address
                            };
                            emailSending(sendEmailModel);
                            timeStart = moment(timeStart).add(peopleendtime, 'm').toDate();
                        }, 500);
                    })
                    success();
                    setIsSending(false);
                }
            }else{
                memberSelect.forEach((item) =>{
                    setTimeout(function(){
                        const sendEmailModel = {
                            'memberId': item.id,
                            'interviewType': typereview,
                            'interviewTime': datetime.toISOString(),
                            'interviewLocation':  address
                        };
                        emailSending(sendEmailModel);
                    }, 500);
                })
                success();
                setIsSending(false);
            }
        }
        setsearchedColumn('');
        setsearchText('');
        setIsOpenSendMail(false);
        setIsSending(false);
        GetMemberRegister();
        setMemberSelect(null);
        setSelectedRowKeys(null);
    }

    function pagination(page, pageSize){
        setPage(page);
        setPageSize(pageSize);
    }

    function success() {
        Modal.success({
          content: 'Hoàn tất quá trình gửi mail.',
        });
      }
    
    const emailSending = async(sendEmailModel)=>{
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }

        axios.post(serverAPIIp + 'api/Recruit/SendEmailInvitationInterview', JSON.stringify(sendEmailModel), { headers })
            .then((response) =>{
                if(response.data.isSuccess){
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối với máy chủ");
                }
            });
    }

    function warning(minutesNeeded, minutes, peopleendtime) {
        var difference = minutesNeeded - minutes;
        var superfluous = difference/ peopleendtime;
        var timeValid = Math.floor(minutes / memberSelect.length);
        Modal.warning({
          title: "Vượt quá thời gian cho phép",
          content: "Với ".concat(memberSelect.length, " người và định mức ", peopleendtime, " phút/người, phải cần ", minutesNeeded, " phút để phỏng vấn. Thời gian quy định của bạn chỉ ", minutes, " phút. Bạn có thể tăng thêm ", difference, " phút hoặc giảm bớt ", Math.ceil(superfluous), " người, cũng có thể giảm định mức xuống còn ", timeValid," để đủ thời gian phỏng vấn."),
        });
      }

    const dateConverter = (startDate, timeEnd) => {
        const newStartDate= new Date(startDate);
        const newEndDate=new Date(timeEnd);
        let result
        result = Math.ceil((newEndDate.getTime()-newStartDate.getTime())/(1000*60))
        if (result < 0 ) {return 0}
        return result
      }

      function ExportMemberList(type){
        setExportDisable(true);
        setIsDownLoading(true);
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                recruitId: recruit.id,
                type: type
            };
            axios.get(serverAPIIp + 'api/Recruit/DownloadMemberRegisterByRecruitID', {params: requestData, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    window.open(serverAPIIp + response.data.responseData, "_blank");
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
                setExportDisable(false);
                setIsDownLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setExportDisable(false);
                }
                setIsDownLoading(false);
            })
        }
    }

    useEffect(() => {
        if(value === "" && memberRergister === null){
            GetRecruitById();
            GetMemberRegister();
        }
    }, []);

    return(
        <div>
            {
                !isRecruitLoading?
                <div >
                    {
                        recruit?
                        <div>
                            <Form form={form}>
                                <Row span={24}>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <Form.Item 
                                            name="title" 
                                            label="Tiêu đề:"
                                            disabled={isCreading || endOfRecruitment}
                                            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                                        >
                                            <Input maxLength={250} placeholder="Tiêu đề" onChange={(e) =>{onChangeTitle(e)}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <Form.Item
                                            name="timeline" 
                                            label="Thời gian:"
                                            disabled={isCreading || endOfRecruitment}
                                            rules={[{ required: true, message: 'Vui lòng nhập thời gian tuyển!' }]}
                                            >
                                                <RangePicker 
                                                showTime 
                                                format={dateFormat}
                                                disabled={isCreading || endOfRecruitment}
                                                disabledDate={disabledDate}
                                                onChange={(e) => {onChangeDate(e)}}
                                                />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                    <div className = "ant-row ant-form ant-form-horizontal">
                                        <div className="ant-col ant-form-item-label">
                                            <label className="ant-form-item-required" >Nội dung</label>
                                        </div>
                                    </div>
                                    <ReactQuill
                                        placeholder="Nội dung..."
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        disabled={isCreading|| endOfRecruitment}
                                        value={value}
                                        onChange={handleChange}
                                    />
                                    {
                                        contentNull?
                                        <div class="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">Vui lòng nhập nội dung!</div>
                                        </div>
                                        : null
                                    }
                                </Col>
                            </Row>
                            {
                                roleCread.includes(roleId)?

                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    disabled={value === "<p><br></p>" || value === "" || endOfRecruitment}
                                    onClick={() => {setIsShowPreview(!isShowPreview)}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faEye}/>}
                                    type="primary">Xem Trước
                                </Button>
                                : null
                            }

                            {
                                roleCread.includes(roleId)?
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faSave}/>}
                                    onClick={() => {saveRecruit()}}
                                    loading={isCreading}
                                    disabled={value === "<p><br></p>" 
                                    || value === "" || isDateNull || titleIsNull || !isChange || endOfRecruitment}
                                    type="primary">{"Cập Nhật"}
                                </Button>
                                : null
                            }

                            {
                                roleCread.includes(roleId)?
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={isLocked ? faLockOpen : faLock}/>}
                                    danger={!isLocked}
                                    onClick={()=>{LockOrUnlockRecruit(recruit.id)}}
                                    disabled={recruit === null|| endOfRecruitment}
                                    type="primary">{isLocked ? "Mở Khoá" : "Khoá"}
                                </Button>
                                : null
                            }

                            {
                                roleCread.includes(roleId)?
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faTrash}/>}
                                    danger
                                    onClick={()=>{confirm()}}
                                    disabled={recruit === null || endOfRecruitment}
                                    type="primary">Xoá
                                </Button>
                                : null
                            }

                            {
                                roleCread.includes(roleId)?
                                <Button 
                                    style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faCircle} />}
                                    danger
                                    onClick={()=>{confirmEnd()}}
                                    disabled={recruit === null || endOfRecruitment}
                                    type="primary">Kết Thúc Tuyển
                                </Button>
                                : null
                            }
                            {
                                isShowPreview?
                                <div className='container'>
                                    <hr/>
                                    <Preview value={value}/>
                                </div>
                                : null
                            }
                            <div>
                            {
                                isMemberLoading?
                                <Skeleton />
                                :
                                <div className='container'>
                                    {
                                        memberRergister?
                                        <div>
                                            <div>
                                                <div style={{textAlign: "center", marginTop: 25}}>
                                                    <h2>
                                                        Danh sách đăng ký
                                                    </h2>
                                                </div >
                                                
                                                <Row>
                                                        <Col span={8} xs={24} xl={8}>
                                                            <Button type="primary" 
                                                                loading={isDownLoading}
                                                                style={{marginBottom:15}}
                                                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faFileExcel}/>} 
                                                                onClick={() => {ExportMemberList(0)}}
                                                                // disabled={exportDisable}
                                                                size="middle">Tải Toàn Bộ Danh Sách
                                                            </Button>
                                                        </Col>
                                                        <Col span={8} xs={24} xl={8}>
                                                            <Button type="primary" 
                                                                loading={isDownLoading}
                                                                style={{marginBottom:15}}
                                                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faFileExcel}/>} 
                                                                onClick={() => {ExportMemberList(1)}}
                                                                size="middle">Tải Danh Sách Đậu
                                                            </Button>
                                                        </Col>
                                                        <Col span={8} xs={24} xl={8}>
                                                            <Button type="primary" 
                                                                loading={isDownLoading}
                                                                style={{marginBottom:15}}
                                                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faFileExcel}/>} 
                                                                onClick={() => {ExportMemberList(2)}}
                                                                size="middle">Tải Danh Sách Rớt
                                                            </Button>
                                                        </Col>
                                                </Row>
                                                <Table
                                                    columns={columns} 
                                                    dataSource={memberRergister} 
                                                    pagination={{
                                                        onChange: (page, pageSize) =>{
                                                            pagination(page, pageSize);
                                                        },
                                                        current: page,
                                                        pageSize: pageSize,
                                                        total: memberRergister.length
                                                    }}
                                                    rowSelection={rowSelection}
                                                    scroll={{x: 1000}}/>
                                                <Row>
                                                    <Col span={6} xs={24} xl={6}></Col>
                                                    <Col span={6} xs={24} xl={6}>
                                                        {
                                                            roleCread.includes(roleId)?
                                                                <Button type="primary" 
                                                                style={{marginTop:15}}
                                                                icon={<FontAwesomeIcon style={{marginRight: 10}} 
                                                                        icon={faCheckCircle}/>} 
                                                                onClick={()=>{setIsShowApprove(true)}}
                                                                disabled={exportDisable || endOfRecruitment}
                                                                loading={isApproveLoading}
                                                                size="middle">Phê Duyệt
                                                            </Button>
                                                            : null
                                                        }
                                                    </Col>
                                                    <Col span={6} xs={24} xl={6}>
                                                        {
                                                            roleCread.includes(roleId)?
                                                            <Button type="primary" 
                                                                danger
                                                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faPaperPlane}/>} 
                                                                onClick={() => {openSendMail()}}
                                                                disabled={exportDisable || endOfRecruitment}
                                                                style={{marginTop:15}}
                                                                loading={isSending}
                                                                size="middle">Gửi Email Phỏng Vấn
                                                            </Button>
                                                            : null
                                                        }
                                                        
                                                    </Col>
                                                    <Col span={6} xs={24} xl={6}></Col>
                                                </Row>
                                                <Modal
                                                    title={member? member.lastName + ' ' + member.firstName : null}
                                                    // centered
                                                    visible={isOpenMember}
                                                    onOk={() => setVisible(false)}
                                                    onCancel={() => {setVisible(false); setMember(null);}}
                                                    footer={null}
                                                    width={1000}
                                                    style={{top: 50}}
                                                    bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
                                                >
                                                    {
                                                        isGetMemberLoading?
                                                        <Skeleton avatar paragraph={{ rows: 4 }} />
                                                        :
                                                        <div className='container'>
                                                            {
                                                                member?
                                                                <div>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                            label={"Họ và tên"}
                                                                            style={{margin: 5}}
                                                                            >
                                                                                <Input style={{marginLeft: 10}}
                                                                                value={member.lastName + ' ' + member.firstName}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"Giới tính"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value={member.sex ? "Nam" : "Nữ"}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"Ngày sinh"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <DatePicker style={{marginLeft: 10}}
                                                                                    value={moment(member.birthDay).add(7, 'hours')} className={"container"} placeholder="Chọn ngày" format={"YYYY/MM/DD"}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"Tỉnh/Thành"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value ={member.provinceName}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"Quận/Huyện"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value ={member.districtName}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"Xã/Phường"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value ={member.wardName}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"Số điện thoại"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value ={member.phoneNumber}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"Email"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value={member.email}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"Facebook"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Button type="primary"
                                                                                    style={{marginLeft: 10}}
                                                                                    icon={<i style={{marginRight: 5}} className="fab fa-facebook-f"></i>}
                                                                                    block
                                                                                    onClick={() =>{window.open(member.linkFacebook, "_blank")}}
                                                                                >Facebook</Button>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"Khoa/Viện"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value={member.facultyName}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"Lớp"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value={member.className}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12} className={"col-md-5 col-md-offset-2"}>
                                                                            <Form.Item
                                                                                label={"MSSV"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <Input style={{marginLeft: 10}}
                                                                                    value={member.studentId}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={24} xs={24} xl={24} className={"col-md-5"}>
                                                                            <Form.Item
                                                                                label={"reason"}
                                                                                style={{margin: 5}}
                                                                                >
                                                                                    <TextArea style={{marginLeft: 10}}
                                                                                    value={member.reason}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr/>
                                                                    <div className='container'>
                                                                        <p style={{textAlign: 'center', fontSize: 16, fontWeight: 600}}>Hoạt động đã tham gia</p>
                                                                        {
                                                                            activityHistory !== null && activityHistory.length > 0?
                                                                            <Table columns={activityHistoryColumns} 
                                                                                dataSource={activityHistory} 
                                                                                pagination={{hideOnSinglePage: true}}
                                                                                scroll={{x: 1000}}/>
                                                                            : <p style={{color: 'red'}}>Không có hoạt động nào</p>
                                                                        }
                                                                    </div>
                                                                    <hr/>
                                                                    <div className='container'>
                                                                        <Row>
                                                                            <Col span={12} xs={24} xl={12}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Ngày đăng ký: </h6>
                                                                                    </Col>
                                                                                    <Col span={16} xl={16} xs={16}>
                                                                                        <h6><Moment date={member.registrationDate} format="hh:mm:ss a DD/MM/YYYY"/></h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={12} xs={24} xl={12}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Gửi email: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                    <h6><Tag color={member.sendEmail ? "success" : "error"}>{member.sendEmail ? "Đã gửi" : "Chưa gửi"}</Tag></h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={12} xs={24} xl={12}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Điểm PV: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                        <h6><Tag color={member.scores>5?"success":"error"}>{member.scores}</Tag></h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={12} xs={24} xl={12}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Người chấm: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                        <h6><Tag color={member.scorerName ? "success" : "error"}>{member.scorerName?member.scorerName:"Đang cập nhật"}</Tag></h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={24} xs={24} xl={24}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Đánh giá: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                        <h6>{member.reviews ? member.reviews : "Đang cập nhật"}</h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col span={8} xs={24} xl={8}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Phê duyệt: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                        <h6><Tag color={member.approve ? "success" : "error"}>{member.approve ? "Đã phê duyệt" : "Chưa phê duyệt"}</Tag></h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col span={8} xs={24} xl={8}>
                                                                                <Row style={{margin: 5}}>
                                                                                    <Col span={8} xl={8} xs={8}>
                                                                                        <h6>Người duyệt: </h6>
                                                                                    </Col>
                                                                                    <Col span={12} xl={12} xs={12}>
                                                                                        {
                                                                                            <h6><Tag color={member.approverName ? "success" : "error"}>{member.approverName ? member.approverName : "Đang cập nhật"}</Tag></h6>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                        <Form form={scoresform}>
                                                                            <Form.Item
                                                                                label="Điểm"
                                                                                name={"scores"}
                                                                                rules={[{ required: true, message: 'Vui lòng nhập điểm!' }]}
                                                                            >
                                                                                <Input disabled={member.approve} onChange={(e) => {onChangeScores(e)}} type={"number"} min={0} max={10} placeholder="Nhập điểm" />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                label="Đánh giá"
                                                                                name={"review"}
                                                                                rules={[{ required: true, message: 'Vui lòng nhập đánh giá!' }]}
                                                                            >
                                                                                <Input.TextArea disabled={member.approve} placeholder="Nhập đánh giá"/>
                                                                            </Form.Item>
                                                                        </Form>
                                                                    </div>
                                                                    <Button loading={isLoadingScores} disabled={member.approve || endOfRecruitment} onClick={() => {scoring()}} type="primary" block>Lưu điểm</Button>
                                                                </div>
                                                                :
                                                                <Skeleton />
                                                            }
                                                        </div>
                                                    }
                                                </Modal>
                                            </div>
                                            <Modal title="Phê duyệt thành viên" 
                                                visible={bIsShowApprove} 
                                                onOk = {() => {
                                                    setIsShowApprove(false);
                                                    onApprove();
                                                }}
                                                onCancel={()=> {setIsShowApprove(false);}}
                                            >
                                                <Form form={approveForm}>
                                                    <Row >
                                                        <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                                            <Form.Item
                                                                name="chatLink"
                                                                label="Link nhóm chat:"
                                                                rules={[{ required: true, message: 'Vui lòng nhập link nhóm chat!' }]}
                                                            >
                                                                <Input placeholder='Nhập địa link nhóm chat'/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Modal>
                                            <Modal
                                                title="Gửi Email"
                                                // centered
                                                visible={isOpenSendMail}
                                                onOk={() => setVisibleSendMail()}
                                                onCancel={() => setVisibleSendMail()}
                                                footer={null}
                                                width={1000}
                                            >
                                                <Row>
                                                    <Col flex="60px" style={{margin: 20}}>
                                                        <Switch defaultChecked onChange={() => {setIsDateToDate(!isDateToDate)}}/>
                                                    </Col>
                                                    <Col flex="auto" style={{margin: 20}}>
                                                        <h6>Thời gian không đồng nhất</h6>
                                                    </Col>
                                                </Row>
                                                
                                                <Form form={sendmailform} onFinish={()=>{sendMailOnClick()}}>
                                                    <Row >
                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                            <Form.Item
                                                                name="datereview"
                                                                label="Thời gian phỏng vấn:"
                                                                rules={[{ required: true, message: 'Vui lòng nhập thời gian phỏng vấn!' }]}
                                                            >
                                                                {
                                                                    isDateToDate?
                                                                    <RangePicker
                                                                        block
                                                                        showTime 
                                                                        format={dateFormat}
                                                                        disabled={isSending}
                                                                        disabledDate={disabledDateSendMail}
                                                                    />
                                                                    :<DatePicker
                                                                        block
                                                                        showTime 
                                                                        format={dateFormat}
                                                                        disabled={isSending}
                                                                        disabledDate={disabledDateSendMail}    
                                                                    />
                                                                }
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                            {
                                                                isDateToDate?
                                                                <Form.Item
                                                                    name="peopleendtime"
                                                                    label="Định mức"
                                                                    rules={[{ required: true, message: 'Vui lòng nhập thời gian định mức!' }]}
                                                                >
                                                                    <Input disabled={isSending} type={"number"} min={5} max={60} placeholder="Nhập số phút (Đơn vị: Phút/người)"/>
                                                                </Form.Item>
                                                                : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                                            <Form.Item
                                                                name="address"
                                                                label="Địa chỉ:"
                                                                rules={[{ required: true, message: 'Vui lòng nhập thời phỏng vấn!' }]}
                                                            >
                                                                <Input disabled={isSending} placeholder='Nhập địa chỉ phỏng vấn'/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                            <Form.Item
                                                                name="typereview"
                                                                label="Hình thức phỏng vấn:"
                                                                rules={[{ required: true, message: 'Vui lòng nhập hình thức phỏng vấn!' }]}
                                                            >
                                                                <Input disabled={isSending} placeholder='Nhập hình thức phỏng vấn'/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                {
                                                    memberSelect?
                                                    <Table
                                                        columns={sendMailColumns} 
                                                        dataSource={memberSelect} 
                                                        pagination={{
                                                            onChange: (page, pageSize) =>{
                                                                pagination(page, pageSize);
                                                            },
                                                            current: page,
                                                            pageSize: pageSize,
                                                            total: memberSelect.length
                                                        }}
                                                        scroll={{x: 1300, y: 250}}/>
                                                        : <Skeleton />
                                                }
                                                    <Form.Item>
                                                        <Button 
                                                            htmlType="submit"
                                                            type='primary' 
                                                            block 
                                                            style={{marginTop: 15}}
                                                            loading={isSending}
                                                            disabled={memberSelect === null || memberSelectIsNull}
                                                            // onClick={()=>{sendMailOnClick()}}
                                                            icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faPaperPlane}/>}
                                                        >Gửi Email</Button>
                                                    </Form.Item>
                                                </Form>
                                            </Modal>
                                        </div>
                                        :
                                        <Result
                                            status={isError500? "500" : "404"}
                                            title={errorMess}
                                            subTitle={isError500 
                                            ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                                            : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                                        />
                                    }
                                </div>
                            }
                        </div>
                        </div>
                        :
                        <Result
                            status={isError500? "500" : "404"}
                            title={errorMess}
                            subTitle={isError500 
                            ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                            : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                        />
                    }
                </div>
            : <Skeleton />
            }
        </div>
    )
};
