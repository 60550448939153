import React, {useEffect, useState, useRef}  from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Row, Col, Input, Space, message, Skeleton, Result, Button, Table, Tag, Modal  } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'universal-cookie';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { faLock, faLockOpen, faRedo } from '@fortawesome/free-solid-svg-icons';
import {ExclamationCircleOutlined  } from '@ant-design/icons';



function AccountList(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const TITLE = "Tài Khoản Thành Viên";
    const { Content, Footer, Header } = Layout;
    const [data, setData] = useState(null);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const searchInput = useRef(null);

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
     }

     function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }
    
    const lockAccount = async (item) =>{
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const lockOrUnlockAccount = {
                'memberId': item.id,
                'isLock': !item.isLocked
            }
            axios.post(serverAPIIp + 'api/Account/LockOrUnlockAccount', JSON.stringify(lockOrUnlockAccount), {headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    var members = [];
                    data.forEach((member) => {
                        if(member.id === item.id){
                            var memberChange = {
                                avatarPath: member.avatarPath,
                                birthDay: member.birthDay,
                                className: member.className,
                                email: member.email,
                                faculty: member.faculty,
                                facultyName: member.facultyName,
                                firstName: member.firstName,
                                hitsCreateMembershipCards: member.hitsCreateMembershipCards,
                                hometown: member.hometown,
                                id: member.id,
                                isLocked: !member.isLocked,
                                isMember: member.isMember,
                                joinDate: member.joinDate,
                                lastName: member.lastName,
                                linkFacebook: member.linkFacebook,
                                phoneNumber: member.phoneNumber,
                                roles: member.roles,
                                score: member.score,
                                sex: member.sex,
                                studentId: member.studentId,
                            }
                            members.push(memberChange);
                        }else{
                            members.push(member);
                        }
                    })
                    setData(members);
                    if(!item.isLocked){
                        message.success("Khoá tài khoản thành công");
                    }else{
                        message.success("Mở khoá tài khoản thành công");
                    }
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function pagination(page, pageSize){
        setPage(page);
        setPageSize(pageSize);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);}}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Tìm
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Đặt Lại
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select());
          }
        },
        render: text =>
            searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText(null);
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Avatar",
            dataIndex: "avatarPath",
            key: "avatarPath",
            width: 45,
            render : avatarPath => <img src={serverAPIIp + avatarPath} style={{width: 40, height: 40, objectFit: "cover"}}/>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            width: 140,
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Tài Khoản",
            dataIndex: "userName",
            key: "userName",
            ...getColumnSearchProps('userName'),
        },
        {
            title: "Họ",
            dataIndex: "firstName",
            key: "firstName",
            ...getColumnSearchProps('firstName'),
        },
        {
            title: "Tên",
            dataIndex: "lastName",
            key: "lastName",
            width: 50,
            ...getColumnSearchProps('lastName'),
        },
        {
            title: "Giới Tính",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            render : sex => <span>{sex?"Nam":"Nữ"}</span>
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ...getColumnSearchProps('phoneNumber'),
        },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
            ...getColumnSearchProps('facultyName'),
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            render: (text, record) => 
            <Row>
                <Col style={{padding: 2}}>
                    <Button 
                    title="Đặt mật khẩu về mặc định"
                    onClick={()=> {confirm(record)}}  
                    type={"primary"} icon={<FontAwesomeIcon icon={faRedo}/>}/>
                </Col>
                <Col style={{padding: 2}}>
                    <Button 
                    type={"primary"} 
                    onClick={() =>{lockAccount(record)}}
                    title={record.isLocked? "Mở Khoá Tài Khoản":"Khoá Tài Khoản"}
                    icon={<FontAwesomeIcon icon={record.isLocked?faLockOpen: faLock} />} 
                    danger={!record.isLocked}/>
                </Col>
            </Row>
        },
    ]

    function confirm(member) {
        var textConfirm = `Bạn có chắc chắn muốn khôi phục mật khẩu mặc định cho ${member.firstName} ${member.lastName} không?`
        Modal.confirm({
          title: 'Khôi phục mật khẩu mặc định',
          icon: <ExclamationCircleOutlined />,
          content: textConfirm,
          okText: 'Đồng Ý',
          cancelText: 'Thoát',
          onOk: () => {ResetPass(member.id)},
        });
      }

    function ResetPass(membereId){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var member = {
                memberId: membereId
            };
            axios.get(serverAPIIp + 'api/Account/ResetPassword', {headers:config, params:member})
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Khôi phục mật khẩu thành công")
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function GetMemberList(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/Member/GetAccountList', {headers:config})
            .then((response) =>{
                if(response.data.isSuccess){
                    setData(response.data.responseData);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                    setIsError500(true);
                    setErrorMess(response.data.errors)
                }
                setIsLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setErrorMess("Mất kết nối máy chủ");
                    setIsLoading(false);
                }
                setIsError500(false);
            })
        }
    }

    useEffect(async () => {
        GetMemberList();
    }, []);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <span className={"activetitle"}>{"Tài Khoản Thành Viên"}</span>
                </Header>
                <Content >
                    <Layout style={{background: "#fff"}} >
                        <div style={{minHeight: "70vh"}}>
                        {
                            isLoading?
                            <Skeleton active />
                            : 
                            <div>
                                {
                                    data?
                                        <div>
                                            <Table 
                                                columns={columns} 
                                                dataSource={data} 
                                                pagination={{
                                                    onChange: (page, pageSize) =>{
                                                        pagination(page, pageSize);
                                                    },
                                                    current: page,
                                                    pageSize: pageSize,
                                                    total: data.length
                                                }}
                                                scroll={{x: 1000}}/>
                                        </div>
                                    :
                                        <Result
                                        status={isError500? "500" : "404"}
                                        title={errorMess}
                                        subTitle={isError500 
                                        ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                                        : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                                    />
                                }
                            </div>
                        }
                        </div>
                    </Layout>
                </Content>
                <Footer style={{minWidth: "100%",  background: "#fff", textAlign: 'center', maxHeight: 10 }}>©2021 CLB Sinh Viên Tình Nguyện - ĐH Duy Tân</Footer>
            </Layout>
        </div>
    )
}
export default AccountList;