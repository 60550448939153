import React, {useState}  from 'react';
import { Layout, message, DatePicker, Row, Col, Input, Form, Button, Modal } from 'antd';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import { ImageResize } from "./ImageResize";
import "react-quill/dist/quill.snow.css";
import Preview from "./Preview";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import './css/createrecuit.css';
import { Video } from "./quill-video-resize";
import "./css/quill-video-resize.css";
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';


Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register({ "formats/video": Video });
const { RangePicker } = DatePicker;
const cookies = new Cookies();
const serverAPIIp = 'https://api.dtusvc.com/'; 
const TITLE = "Tạo Tuyển Thành Viên";
const { Content, Footer, Header } = Layout;
const dateFormat = 'YYYY-MM-DD hh:mm';
var upLoadImages = [];
var addImage = false;

function removeCookie(){
    cookies.remove('dtu-svc-fullname');
    cookies.remove('dtu-svc-accountId');
    cookies.remove('dtu-svc-avatarPath');
    cookies.remove('dtu-svc-token');
    cookies.remove('dtu-svc-roleId');
    cookies.remove('dtu-svc-role');
    cookies.remove('dtu-svc-studentId');
    window.location.href="/";
}

const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
  
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [{ direction: "rtl" }],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      matchVisual: false
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: false
    },
    imageUploader: {
      upload: async (file) => {
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
          headers: { 
              'content-type': 'multipart/form-data', 
              'Authorization': 'Bearer ' + token, 
              'accept': '*/*'
            }
        };
        fmData.append('image', file);
        try{
            const res = await axios.post(serverAPIIp + 'api/Newspaper/UploadImage', fmData, config );
            if(res.data.isSuccess){
                addImage = true;
                var images = upLoadImages;
                images.push(res.data.responseData);
                upLoadImages = images;
                return serverAPIIp  + res.data.responseData;
            }else{
                if(res.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(res.data.errors[0]);
                return null;
            }
        }catch (err) {
            if(err.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            return null;
        }
      }
    },
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white"
      },
      modules: ["Resize", "DisplaySize", "Toolbar"]
    }
};

const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "code-block",
        "color",
        "background",
        "font",
        "align",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "clean",
        "code",
        "formula"
];

function removeImage(imagepath){
    const token = cookies.get('dtu-svc-token');
    if(token !== null){
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }
        const removeImageModel = {
            "imagePath": imagepath
        }
        axios.post(serverAPIIp + 'api/Newspaper/RemoveImage', JSON.stringify(removeImageModel), {headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
            }
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối máy chủ");
            }
        })
    }
}

export default function CreateRecuit(){
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const [contentNull, setContentNull] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [isCreading, setIsCreading] = useState(false);
    const [isDateNull, setDateIsNull] = useState(true);
    const [titleIsNull, setTitleIsNull] = useState(true);
    const [idRecruit, setIdRecruit] = useState(null);
    const handleChange = (data) => {
        setValue(data);
        var images = []
        upLoadImages.forEach((image) =>{
            if(data.indexOf(serverAPIIp + image) < 0 && !addImage){
                removeImage(image);
            }else{
                images.push(image);
            }
        })
        upLoadImages = images;
        addImage = false;
        setContentNull(data === "<p><br></p>");
    };

    function confirm() {
        Modal.confirm({
          title: 'Xoá đợt tuyển thành viên!',
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteClick()}
        });
    }

    function saveRecruit(){
        setIsCreading(true);
        var timeSlide = form.getFieldValue("timeline");
        var title = form.getFieldValue("title");
        var valid = true;
        if(timeSlide === null || timeSlide === undefined){
            valid = false;
            form.setFields([{
                name: "timeline",
                errors:["Vui lòng nhập thời gian tuyển!"]
            }]);
        }

        if(title === undefined || title === null){
            valid = false;
            form.setFields([{
                name: "title",
                errors:["Vui lòng nhập tiêu đề!"]
            }]);
        }else{
            if(title.replace(/ /g, '') === ''){
                valid = false;
                form.setFields([{
                    name: "title",
                    errors:["Vui lòng nhập tiêu đề!"]
                }]);
            }
        }
        
        if(value === null || value === "<p><br></p>" || value === ""){
            valid = false;
            setContentNull(true);
        }
        if(isDateNull || titleIsNull){
            setIsCreading(false);
        }else{
            const token = cookies.get('dtu-svc-token');
            if(token !== null && valid){
                var startDate = timeSlide[0].toISOString();
                var endDate = timeSlide[1].toISOString();
                const headers = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                const createRecruitRequestModel = {
                    recruitName: title,
                    recruitContent: value,
                    dateStart: startDate,
                    dateEnd: endDate
                }

                const changeRecruitRequestModel = {
                    id: idRecruit,
                    recruitName: title,
                    recruitContent: value,
                    dateStart: startDate,
                    dateEnd: endDate
                }
                
                var path = 'api/Recruit/ChangeRecruit'
                var data = JSON.stringify(changeRecruitRequestModel);
                if(idRecruit === null){
                    data = JSON.stringify(createRecruitRequestModel);
                    path = 'api/Recruit/CreateRecruit';
                }
                axios.post(serverAPIIp + path, data, {headers})
                .then((response) =>{
                    if(response.data.isSuccess){
                        if(idRecruit === null){
                            message.success("Tạo tuyển thành viên thành công");
                            setIdRecruit(response.data.responseData.id);
                            setIsCreading(false);
                        }else{
                            message.success("Cập nhật thành công");
                            setIsCreading(false);
                        }
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors);
                        setIsCreading(false);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối máy chủ");
                    }
                    setIsCreading(false);
                })
            }
        }
    }

    function deleteClick(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            if(idRecruit){
                const config  = {
                    "Content-Type": "application/json",
                    "accept": "*/*",
                    "Authorization": 'Bearer ' + token
                }
                var requestData = {
                    recruitId: idRecruit
                };
                axios.get(serverAPIIp + 'api/Recruit/RemoveRecruit', {headers:config, params:requestData})
                .then((response) =>{
                    if(response.data.isSuccess){
                        message.success("Xoá thành công");
                        form.setFieldsValue({
                            title: "",
                            timeline: undefined
                        });
                        setValue("");
                        if(upLoadImages){
                            upLoadImages.forEach((item) => {
                                removeImage(item);
                            })
                        }
                        upLoadImages = [];
                        setIdRecruit(null);
                    }else{
                        if(response.data.errors[0].indexOf("(401)") >= 0){
                            removeCookie();
                        }
                        message.error(response.data.errors[0]);
                    }
                })
                .catch((response)=>{
                    if(response.toString().indexOf(401) >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }else{
                        message.error("Mất kết nối máy chủ");
                    }
                })
            }else{
                message.error("Bạn chỉ được xoá bản Tuyển thành viên vừa tạo");
            }
        }
    }

    function onChangeDate(e){
        setDateIsNull(e === null);
    }

    function onChangeTitle(e){
        setTitleIsNull(e === null);
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>{ TITLE }</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        <div style={{minHeight: "70vh"}}>
                            <Form form={form}>
                                <Row span={24}>
                                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                        <Form.Item 
                                        name="title" 
                                        label="Tiêu đề:"
                                        disabled={isCreading}
                                        rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                                        >
                                            <Input maxLength={250} placeholder="Tiêu đề" onChange={(e) =>{onChangeTitle(e)}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                        <Form.Item
                                        name="timeline" 
                                        label="Thời gian:"
                                        disabled={isCreading}
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian tuyển!' }]}
                                        >
                                            <RangePicker 
                                            showTime
                                            format={dateFormat}
                                            disabledDate={disabledDate}
                                            onChange={(e) => {onChangeDate(e)}}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col span={24} className={"col-md-5"} xs={24} xl={24}>
                                    <div className = "ant-row ant-form ant-form-horizontal">
                                        <div className="ant-col ant-form-item-label">
                                            <label className="ant-form-item-required" >Nội dung</label>
                                        </div>
                                    </div>
                                    <ReactQuill
                                        placeholder="Nội dung..."
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        disabled={isCreading}
                                        value={value}
                                        onChange={handleChange}
                                    />
                                    {
                                        contentNull?
                                        <div class="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">Vui lòng nhập nội dung!</div>
                                        </div>
                                        : null
                                    }
                                </Col>
                            </Row>
                            <Button 
                                style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                disabled={value === "<p><br></p>" || value === ""}
                                onClick={() => {setIsShowPreview(!isShowPreview)}}
                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faEye}/>}
                                type="primary">Xem Trước
                            </Button>
                            <Button 
                                style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faSave}/>}
                                onClick={() => {saveRecruit()}}
                                loading={isCreading}
                                disabled={value === "<p><br></p>" 
                                || value === "" || isDateNull || titleIsNull}
                                type="primary">{idRecruit === null? "Tạo Mới" : "Cập Nhật"}
                            </Button>
                            <Button 
                                style={{marginTop: 15, marginLeft: 15, marginRight: 15}}
                                icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faTrash}/>}
                                danger
                                disabled={idRecruit === null}
                                onClick={() => {confirm()}}
                                type="primary">Xoá
                            </Button>
                            {
                                isShowPreview?
                                <div>
                                    <hr/>
                                    <Preview value={value}/>
                                </div>
                                : null
                            }
                        </div>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
};