import React, {useEffect, useState}  from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Row, Col, Form, Input, DatePicker, Space, message, Skeleton, Result,  Button, Timeline, Empty, Table, Tag  } from 'antd';
import {Card} from 'react-bootstrap';
import './css/individualparticipatingevent.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import MemberInEven from './MemberInEven';
import Moment from 'react-moment';
import {ArrowLeftOutlined,ClockCircleOutlined  } from '@ant-design/icons';

function IndividualParticipatingEvent(){
    const { Content, Footer, Header } = Layout;
    const TITLE = "Hoạt Động Đã Tham Gia";
    const { Search } = Input;
    const { RangePicker } = DatePicker;
    
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [form] = Form.useForm();
    
    const [dataNotNull, setDataNotNull] = useState(false);
    const [searchNotNull, setSearchNotNull] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);
    const [findData, setFindData] = useState(null);
    const [itemData, setItemData] = useState(null);
    const dateFormat = "YYYY-MM-DD hh:mm:ss";
    const columns = [{
        title: 'STT',
        dataIndex: 'number',
        key: 'number'
        },
        {
            title: 'Điểm danh vào',
            dataIndex: 'startDate',
            key: 'startDate',
            render: startDateText => <Moment date={startDateText} format="DD-MM-YYYY hh:mm:ss a"/>
        },
        {
            title: 'Điểm danh ra',
            dataIndex: 'endDate',
            key: 'endDate',
            render: endDateText => 
                <div>
                    {
                        endDateText !== "0001-01-01T00:00:00"?
                            <Moment date={endDateText} format="DD-MM-YYYY hh:mm:ss a"/>
                        :   <span style={{color: "red"}}>Chưa hoàn thành</span>
                    }
                </div>
        },
        {
            title: 'Điểm',
            dataIndex: 'scores',
            key: 'scores',
            render: scores =>
                <Row align="middle">
                    <Col>
                    {
                        scores === 0?
                            <Tag color="red">{scores}</Tag>
                        :
                            <Tag color="#108ee9">{scores.toFixed(2)}</Tag>
                    }
                    </Col>
                </Row >
        },
        {
            title: 'Quản lý',
            dataIndex: 'managerName',
            key: 'managerName'
        },
    ]

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function GetIndividualParticipatingEvent(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/GetIndividualParticipatingEvent', {headers: headers})
            .then((response) =>{
                if(response.data.isSuccess){
                    setDataNotNull(true);
                    setIsError(false);
                    setData(response.data.responseData);
                    setFindData(response.data.responseData);
                    setLoading(false);
                    setSearchNotNull(true);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                        message.error(response.data.errors[0]);
                    }
                    setDataNotNull(false);
                    setLoading(false);
                    setErrorMess(response.data.errors[0]);
                    setIsError(false);
                    setSearchNotNull(false);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối với máy chủ");
                    setLoading(false);
                    setIsError(true);
                    setDataNotNull(false);
                    setSearchNotNull(false);
                    setErrorMess("Mất kết nối với máy chủ");
                }
            })
        }
    }

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
         str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
         str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
         str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
         str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
         str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
         str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
         str = str.replace(/đ/g, "d");
         str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
         str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
         str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
         str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
         str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
         str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
         str = str.replace(/Đ/g, "D");
         return str;
     }

    function searchEvent(value){
        var searchArr = [];
        var valuedate = form.getFieldValue("searchdate");
        data.find((obj) => {
            if((RemoveVietnameseAccents(obj.eventTitle.toLowerCase()).indexOf(RemoveVietnameseAccents(value.toLowerCase()))) >= 0){
                if(valuedate !== undefined && valuedate !== null){
                    if(obj.registrationDeadline >= valuedate[0].toISOString() && obj.registrationDeadline <= valuedate[1].toISOString()){
                        searchArr.push(obj);
                    }
                }else{
                    searchArr.push(obj);
                }
            }
        });
        if(value !== null){
            setFindData(searchArr);
        }else{
            setFindData(data);
        }
        setSearchNotNull(true);
        if(searchArr.length < 1){
            setSearchNotNull(false);
        }
    }

    function onChangeDate(){
        var searchArr = [];
        var valuedate = form.getFieldValue("searchdate");
        data.find((obj) =>{
            if(valuedate !== undefined && valuedate !== null){
                if(obj.registrationDeadline >= valuedate[0].toISOString() && obj.registrationDeadline <= valuedate[1].toISOString()){
                    searchArr.push(obj);
                }
            }else{
                setFindData(data);
            }
        });
        if(searchArr.length < 1){
            setSearchNotNull(false);
        }else{
            setFindData(searchArr);
            setSearchNotNull(true);
        }
    }
    
    const onSearch = value => {
        GetIndividualParticipatingEvent();
        searchEvent(value);
    };

    const onChange = value => {
        searchEvent(value.target.value);
    };

    const itemOnclick = (dataItem)=>{
        setItemData(dataItem);
    }

    useEffect(async () => {
        GetIndividualParticipatingEvent();
    }, []);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>Hoạt Động Đã Tham Gia</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        <Space>
                            <Form className={"row gutters cantop"} form = {form} style={{minHeight: "70vh"}}>
                                {
                                    itemData?
                                        <div>
                                            <Row style={{padding: 10}}>
                                                <Col flex="34px">
                                                    <Button shape="circle" onClick={()=>{setItemData(null)}} icon={<ArrowLeftOutlined />} size="large" />
                                                </Col>
                                                <Col flex="auto">
                                                    <Card.Title>{itemData.eventTitle}</Card.Title>
                                                </Col>
                                            </Row>
                                            <hr/>
                                            {
                                                itemData.posterPath?
                                                    <img className={"container"} src={serverAPIIp + itemData.posterPath} style={{paddingLeft : 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15}}/>
                                                :
                                                null
                                            }
                                            <hr/>
                                            <Card.Text style={{fontSize: 18, fontWeight: 600}}>Dòng thời gian</Card.Text>
                                            <Timeline style={{paddingLeft : 30, paddingRight: 30, paddingTop: 15}}>
                                                <Timeline.Item style={{textAlign: "left"}}>Ngày bắt đầu <Moment date={itemData.startDate} format="DD-MM-YYYY"/></Timeline.Item>
                                                <Timeline.Item style={{textAlign: "left"}} color="green">Thời gian thực hiện <Moment date={itemData.startDate} format="DD-MM-YYYY hh:mm a"/></Timeline.Item>
                                                <Timeline.Item style={{textAlign: "left"}}>Ngày kết thúc <Moment date={itemData.endDate} format="DD-MM-YYYY"/></Timeline.Item>
                                                <Timeline.Item style={{textAlign: "left", fontWeight: 600, color: "red"}} dot={<ClockCircleOutlined className="timeline-clock-icon" />} color="red">
                                                    Hiện tại: <Moment date={new Date()} format="DD-MM-YYYY hh:mm a"/>
                                                </Timeline.Item>
                                            </Timeline>
                                            <hr/>
                                            <Card.Text style={{fontSize: 18, fontWeight: 600}}>Nội dung thực hiện</Card.Text>
                                            <Card.Text style={{textAlign:"initial", paddingLeft : 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15}} >
                                                <div class="ql-container ql-snow" style={{border: "white"}}>
                                                    <div style={{minHeight: "0vh"}} class="ql-editor" dangerouslySetInnerHTML={{ __html: itemData.content }} />
                                                </div>
                                            </Card.Text>
                                            <hr/>
                                            <Card.Text style={{fontSize: 18, fontWeight: 600}}>Kết quả</Card.Text>
                                            {
                                                itemData.isJoin?
                                                    <Table style={{paddingBottom: 20}} columns={columns} dataSource={itemData.checkInList} pagination={{hideOnSinglePage: true}}/>
                                                :
                                                <Empty description={
                                                    <span>
                                                        Bạn đã đăng ký tham gia chương trình này nhưng chưa từng hoạt động lần nào! Hãy tích cực hơn nhé!
                                                    </span>
                                                  }
                                                style={{padding: 30}}/>
                                            }
                                        </div>
                                    :
                                    <div>
                                        {
                                            dataNotNull ?
                                            <Row span={24}>
                                                <Col span={12} className={"col-md-5"} xs={24} xl={12} >
                                                    <Form.Item name="searchdate"
                                                    >
                                                        <RangePicker 
                                                        format={dateFormat} 
                                                        showTime
                                                        onChange={onChangeDate}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                                    <Search placeholder="Nhập vào tiêu đề cần tìm kiếm" onChange={onChange} onSearch={onSearch} enterButton />
                                                </Col>
                                            </Row>
                                            : null
                                        }
                                        <div className={"site-card-border-less-wrapper"}>
                                            {
                                                isLoading ? 
                                                <Skeleton active />
                                                :
                                                <div>
                                                    {
                                                        searchNotNull?
                                                            <div >
                                                                {findData.map((dataItem) => (<div onClick={()=>{itemOnclick(dataItem)}}><MemberInEven dataFromParent = {dataItem}/></div>))}
                                                            </div>
                                                        :
                                                        <Result
                                                            status={isError? "500" : "404"}
                                                            title={errorMess}
                                                            subTitle={isError 
                                                                ? "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !" 
                                                                : "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </Form>
                        </Space>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
}
export default IndividualParticipatingEvent;