import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import { Layout, Card, Result, message, Image, Row, Col, Upload, Button, Skeleton, Modal,} from 'antd';
import { faTrash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';


const TITLE = "Danh sách Slider";

function UploadBanner(){
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const cookies = new Cookies();
    const { Content, Footer, Header } = Layout;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [poster, setPoster] = useState(null);
    const [progress, setProgress] = useState(0);
    function getSliderbar(){
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token, 
        }
        axios.get(serverAPIIp + 'api/Newspaper/GetListBannerByMember', {headers: headers})
        .then((response) =>{
            // console.log(response);
            if(response.data.result.isSuccess){
                setData(response.data.result.responseData);
            }else{
                message.error(response.data.result.errors[0]);
            }
            setLoading(false);
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
        })
    }

    function confirm(id) {
        Modal.confirm({
          title: 'Xoá!',
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteClick(id)}
        });
    }

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function deleteClick(id){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config  = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                bannerId: id
            };

            axios.get(serverAPIIp + 'api/Newspaper/RemoveBanner', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Xoá thành công");
                    var banner = [];
                    data.find(function(item){
                        if(item.id !== id){
                            banner.push(item);
                        }
                    });
                    setData(banner);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

      const dummyRequest = async (options) => {
        const {onSuccess, onError, file, onProgress } = options;
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            onSuccess("error");
        }else{
            const token = cookies.get("dtu-svc-token");
            var fmData = new FormData();
            const config = {
            headers: { 
                'content-type': 'multipart/form-data', 
                'Authorization': 'Bearer ' + token, 
                'accept': '*/*'
                },
                onUploadProgress: (event) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    setProgress(percent);
                    if (percent === 100) {
                        setTimeout(() => setProgress(0), 1000);
                    }
                    onProgress({ percent: Math.floor((event.loaded / event.total) * 100 )});
                },
            };
            fmData.append('BannerFile', file);
            try {
                const res = await axios.post(serverAPIIp + 'api/Newspaper/CreateBanner', fmData, config );
                // console.log(res);
                if(res.data.isSuccess){
                    message.success("Tải ảnh lên thành công");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }else{
                    if(res.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(res.data.errors);
                }
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } catch (err) {
                if(err.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối với máy chủ");
                    onError({ err });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            }
            onSuccess('Ok');
        }
      };

    useEffect(() => {
        getSliderbar();
    }, []);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <p className={"activetitle"}>{ TITLE }</p>
                </Header>
                <Content>
                    <Layout style={{background: "#fff"}}>
                        <div style={{minHeight: "70vh"}}>
                            {
                                loading?
                                <div>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                    <Skeleton active/>
                                </div>
                                :
                                <div>
                                    {
                                        data !== null && data.length !== 0?
                                            <div>
                                                <div className="container">
                                                    <Card bordered={true} style={{cursor: "auto", margin: 10}}>
                                                        <ImgCrop aspect={152 / 30} cropperProps={"posterCrop"}
                                                            modalTitle="Cắt ảnh"
                                                            modalOk={"Lưu"}
                                                            modalCancel={"Huỷ"}>
                                                            <Upload
                                                                accept="image/*"
                                                                // onChange={handleOnChange}
                                                                // onPreview={onPreview}
                                                                customRequest={dummyRequest}
                                                                listType="picture"
                                                                style={{borderStyle: "dotted"}}
                                                                progress={progress}
                                                                maxCount={1}
                                                                >
                                                                {
                                                                    defaultFileList.length < 1 && '+ Tải lên'
                                                                }
                                                            </Upload>
                                                        </ImgCrop>
                                                    </Card>
                                                </div>
                                                {
                                                    data.map((item) =>{
                                                        return(
                                                            <div className="container">
                                                                <Card  bordered={true} style={{cursor: "auto", margin: 10}}>
                                                                    <Image src={serverAPIIp + item.bannerPath}/>
                                                                    <Row>
                                                                        <Col span={12} xs={24} xl={12}>
                                                                            <p style={{textAlign: "left"}}>Thời gian: <Moment style={{fontWeight: 600 }} date={item.createDate} format="hh:mm a DD-MM-YYYY"/></p>
                                                                        </Col>
                                                                        <Col span={12} xs={24} xl={12}>
                                                                            <p style={{textAlign: "left"}}>Người tải lên: <span style={{fontWeight: 600 }}>{item.createrId}</span></p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Button 
                                                                    type='primary' 
                                                                    block 
                                                                    danger
                                                                    onClick={()=>{confirm(item.id)}}
                                                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faTrash}/>}
                                                                    >Xoá</Button>
                                                                </Card>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <Result
                                            status={"500"}
                                            title={"Không tìm thấy dữ liệu"}
                                            subTitle={"Không có dữ liệu nào. Bạn hãy tải lên nhé!"}
                                    />
                                    }
                                </div>
                            }
                        </div>
                    </Layout>
                </Content>
            </Layout>
        </div>
    );
}
export default UploadBanner;