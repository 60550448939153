import React, {useEffect, useState, useRef}  from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Row, Col, Input, Space, message, Skeleton, Result, Button, Table, Tag, Modal  } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'universal-cookie';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { faChartBar, faEye } from '@fortawesome/free-solid-svg-icons';
import MemberProfile from './MemberProfile';
import ActivityHistory from './ActivityHistory';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    useRouteMatch,
} from "react-router-dom";


function MemberOutList(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const TITLE = "Danh Sách Thành Viên";
    const [data, setData] = useState(null);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    let match = useRouteMatch();

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
     }

     function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function confirm(id) {
        Modal.confirm({
          title: 'Xoá thành viên!',
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteMember(id)}
        });
    }
    
    const deleteMember = async (id) =>{
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const checkin = {
                memberId: id
              }
            axios.get(serverAPIIp + 'api/Member/LockMember', {params: checkin, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    deleteMemberFromData(id);
                    message.success("Xoá thành viên thành công");
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function deleteMemberFromData(id){
        var members = [];
        data.forEach(item => {
            if(item.id !== id){
                members.push(item);
            }
        });
        setData(members);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={ searchInput }
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);}}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Tìm
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Đặt Lại
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select());
          }
        },
        render: text =>
            searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText(null);
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render:(value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Avatar",
            dataIndex: "avatarPath",
            key: "avatarPath",
            width: 45,
            render : avatarPath => <img src={serverAPIIp + avatarPath} style={{width: 40, height: 40, objectFit: "cover"}}/>
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            width: 140,
        },
        {
            title: "Họ",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: "Tên",
            dataIndex: "firstName",
            key: "firstName",
            width: 50,
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: "Giới Tính",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            render : sex => <span>{sex?"Nam":"Nữ"}</span>
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
        },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
        },
        {
            title: "Tình Trạng",
            dataIndex: "isMember",
            key: "isMember",
            width: 90,
            render: isMember => <Tag color={isMember?"success":"error"}>{isMember?"Hoạt động":"Đã rời"}</Tag>
        },
        {
            title: "Hành Động",
            dataIndex: "action",
            render: (text, record) => 
            <Row>
                <NavLink to={"/dashboard/member/memberlist/memberout/viewinfo/" + record.id}>
                    <Col style={{padding: 2}}>
                    
                        <Button 
                        type={"primary"} 
                        title="Thông tin cá nhân"
                        // onClick={() => {setItemData(record); setMemberTitle("Thông Tin Thành Viên")}} 
                        icon={<FontAwesomeIcon icon={faEye} />}/>
                    
                    </Col>
                </NavLink>
                <NavLink to={"/dashboard/member/memberlist/memberout/activity/"  + record.id}>
                    <Col style={{padding: 2}}>
                        <Button 
                        color="green" 
                        title="Các hoạt động"
                        type={"primary"} 
                        // onClick={() => {setIdMember(record); setMemberTitle("Quá Trình Hoạt Động")}} 
                        icon={<FontAwesomeIcon icon={faChartBar} />}/>
                    </Col>
                </NavLink>
                <Col style={{padding: 2}}>
                    <Button 
                    title="Mở Facebook"
                    onClick={()=> window.open(record.linkFacebook, "_blank")}  
                    type={"primary"} 
                    icon={<i className="fab fa-facebook-f"></i>}/>
                </Col>
            </Row>
        },
    ]

    function GetMemberList(){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                type: 2
            };
            axios.get(serverAPIIp + 'api/Member/GetMemberList', {headers:config, params:requestData})
            .then((response) =>{
                if(response.data.isSuccess){
                    setData(response.data.responseData);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                    setErrorMess(response.data.errors);
                    setIsError500(true);
                }
                setIsLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setIsLoading(false);
                    setErrorMess("Mất kết nối máy chủ");
                    setIsError500(false);
                }
            })
        }
    }

    function pagination(page, pageSize){
        setPage(page);
        setPageSize(pageSize);
    }

    useEffect(() => {
        GetMemberList();
    }, []);

    function AllMember(){
        return(
            <div>
                {
                isLoading?
                <Skeleton active />
                : 
                <div>
                {
                data?
                <div>
                    <Table 
                        columns={columns} 
                        dataSource={data} 
                        pagination={{
                            onChange: (page, pageSize) =>{
                                pagination(page, pageSize);
                            },
                            current: page,
                            pageSize: pageSize,
                            total: data.length
                        }}
                        scroll={{x: 1000}}/>
                </div>
                :
                <Result
                    status={isError500? "500" : "404"}
                    title={errorMess}
                    subTitle={isError500 
                    ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                    : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                />
                }
                </div>
            }
            </div>
        );
    }

    return(
        <div className="container card-body">
            <Router>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <div style={{minHeight: "61vh"}}>
                    <Switch>
                        <Route path={`${match.path}/memberout/viewinfo/:memberId`} exact>
                            <MemberProfile/>
                        </Route>
                        <Route path={`${match.path}/memberout/activity/:memberId`} exact>
                            <ActivityHistory/>
                        </Route>
                        <Route path={match.path} exact>
                            <AllMember/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    )
}
export default MemberOutList;