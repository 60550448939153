import React from 'react'
import { Helmet } from 'react-helmet'
import Sliderbar from './Sliderbar';

function SponsorUs(){
    const TITLE = "Tài trợ";
    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <div>
                <Sliderbar/>
                <div>Trang này đang trong quá trình phát triển</div>
            </div>
        </>
    )
}
export default SponsorUs;