import React, { useEffect, useState, useRef } from 'react';
import { Skeleton, Input, Space, message, Result, Button, Table, Tag } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'universal-cookie';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import moment from 'moment';


function MemberWithoutCard() {
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/';
    const [data, setData] = useState(null);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [searchText, setsearchText] = useState(null);
    const [searchedColumn, setsearchedColumn] = useState(null);
    const searchInput = useRef(null);
    const [isLoaddingCreate, setIsLoaddingCreate] = useState(false);
    const [textCreateButton, setTextCreateButton] = useState('Tạo Thẻ Thành Viên');
    const [exportDisable, setExportDisable] = useState(true);
    const [isLoadding, setIsLoadding] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    }

    function removeCookie() {
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href = "/";
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []); }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Tìm
                    </Button>
                    <Button onClick={() => { handleReset(clearFilters); }} size="small" style={{ width: 90 }}>
                        Đặt Lại
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? RemoveVietnameseAccents(record[dataIndex].toString()).toLowerCase().includes(RemoveVietnameseAccents(value.toLowerCase()))
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText(null);
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "no1",
            key: "no1",
            width: 50,
            render: (value, item, index) => (page - 1) * pageSize + index + 1
        },
        {
            title: "Ngày Gia Nhập",
            dataIndex: "joinDate",
            key: "joinDate",
            width: 110,
            sorter: {
                compare: (a, b) => moment(a.joinDate).unix() - moment(b.joinDate).unix(),
                multiple: 1,
            },
            render: joinDate =>
                <Moment date={joinDate} format="DD/MM/YYYY" />
        },
        {
            title: "Avatar",
            dataIndex: "avatarPath",
            key: "avatarPath",
            width: 45,
            render: avatarPath => <img src={serverAPIIp + avatarPath} style={{ width: 40, height: 40, objectFit: "cover" }} />
        },
        {
            title: "MSSV",
            dataIndex: "studentId",
            key: "studentId",
            width: 140,
            ...getColumnSearchProps('studentId'),
        },
        {
            title: "Họ",
            dataIndex: "lastName",
            key: "lastName",
            ...getColumnSearchProps('lastName'),
        },
        {
            title: "Tên",
            dataIndex: "firstName",
            key: "firstName",
            width: 50,
            ...getColumnSearchProps('firstName'),
        },
        {
            title: "Giới Tính",
            dataIndex: "sex",
            key: "sex",
            width: 50,
            render: sex => <span>{sex ? "Nam" : "Nữ"}</span>
        },
        {
            title: "Khoa",
            dataIndex: "facultyName",
            key: "facultyName",
            ...getColumnSearchProps('facultyName'),
        },
        {
            title: "Tình Trạng",
            dataIndex: "isLocked",
            key: "isLocked",
            width: 90,
            render: isLocked => <Tag color={!isLocked ? "success" : "error"}>{!isLocked ? "Hoạt động" : "Không HĐ"}</Tag>
        }
    ]

    const CreateMemberCard = async (id) => {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const createData = {
                memberIds: id
            }
            await axios.post(serverAPIIp + 'api/Member/DownloadMemberCardEx', JSON.stringify(createData), { headers })
                .then((response) => {
                    if (response.data.isSuccess) {
                        // var members = [];
                        // data.forEach(item=>{
                        //     if(response.data.responseData.indexOf(item.id) < 0){
                        //         members.push(
                        //             {
                        //                 key: item.id,
                        //                 avatarPath: item.avatarPath,
                        //                 studentId: item.studentId,
                        //                 lastName: item.lastName,
                        //                 firstName: item.firstName,
                        //                 sex: item.sex,
                        //                 phoneNumber: item.phoneNumber,
                        //                 facultyName: item.facultyName,
                        //                 isMember: item.isMember
                        //             }
                        //         );
                        //     }
                        // });
                        // setData(members);
                        window.open(serverAPIIp + response.data.responseData, "_blank");
                        message.success("Đã hoàn tất quá trình tạo thẻ thành viên");
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                    }

                    setIsLoaddingCreate(false);
                    setTextCreateButton("Tạo Thẻ Thành Viên");
                    setSelectedRowKeys(null);
                    setExportDisable(true);
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }

                    setIsLoaddingCreate(false);
                    setTextCreateButton("Tạo Thẻ Thành Viên");
                    setSelectedRowKeys(null);
                    setExportDisable(true);
                })
        }
    }

    function GetMemberList() {
        const token = cookies.get('dtu-svc-token');
        if (token !== null) {
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            var requestData = {
                type: 4
            };
            axios.get(serverAPIIp + 'api/Member/GetMemberList', { headers: config, params: requestData })
                .then((response) => {
                    if (response.data.isSuccess) {
                        var members = []
                        response.data.responseData.forEach(item => {
                            members.push({
                                key: item.id,
                                avatarPath: item.avatarPath,
                                studentId: item.studentId,
                                lastName: item.lastName,
                                firstName: item.firstName,
                                sex: item.sex,
                                phoneNumber: item.phoneNumber,
                                facultyName: item.facultyName,
                                isLocked: item.isLocked,
                                joinDate: item.joinDate,
                            });
                        });
                        setData(members);
                        setIsLoadding(false);
                    } else {
                        if (response.data.errors[0].indexOf("(401)") >= 0) {
                            removeCookie();
                        }
                        setErrorMess(response.data.errors[0]);
                        setIsError500(true);
                        setIsLoadding(false);
                    }
                })
                .catch((response) => {
                    if (response.toString().indexOf(401) >= 0) {
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    } else {
                        message.error("Mất kết nối máy chủ");
                    }
                    setIsError500(false);
                    setIsLoadding(false);
                })
        }
    }

    const onCreateMemberCard = () => {
        setIsLoaddingCreate(true);
        setTextCreateButton("Đang Tạo Thẻ");
        CreateMemberCard(selectedRowKeys);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) {
            setExportDisable(false);
        } else {
            setExportDisable(true);
        }
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            disabled: record.avatarPath.includes("/LogoCLB.png?"),
        }),
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
                },
            },
            {
                key: 'even',
                text: 'Select Even Row',
                onSelect: changableRowKeys => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
                },
            },
        ],
    };

    function pagination(page, pageSize) {
        setPage(page);
        setPageSize(pageSize);
    }

    useEffect(async () => {
        GetMemberList();
    }, []);

    return (
        <div>
            {
                !isLoadding ?
                    <div className="container card-body">
                        <div>
                            {
                                data ?
                                    <div>
                                        <Button type="primary"
                                            style={{ marginBottom: 15, float: "right" }}
                                            icon={<FontAwesomeIcon style={{ marginRight: 10 }} icon={faIdCard} />}
                                            onClick={onCreateMemberCard}
                                            disabled={exportDisable}
                                            loading={isLoaddingCreate}
                                            size="middle">{textCreateButton}</Button>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            pagination={{
                                                onChange: (page, pageSize) => {
                                                    pagination(page, pageSize);
                                                },
                                                current: page,
                                                pageSize: pageSize,
                                                total: data.length
                                            }}
                                            rowSelection={rowSelection}
                                            scroll={{ x: 1000 }} />
                                    </div>
                                    :
                                    <Result
                                        status={isError500 ? "404" : "500"}
                                        title={errorMess}
                                        subTitle={isError500
                                            ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                                            : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                                    />
                            }
                        </div>
                    </div>
                    :
                    <Skeleton loading={isLoadding} active avatar></Skeleton>
            }
        </div>
    )
}
export default MemberWithoutCard;