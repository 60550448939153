import React, {useEffect, useState}  from 'react';
import {Skeleton, Row, Col, Input, Pagination , message, Result, Image, Button} from 'antd';
import Cookies from 'universal-cookie';
import {Card} from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {DownloadOutlined  } from '@ant-design/icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function MemberWithCard(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/';
    const [cards, setCards] = useState(null);
    const [cardsSearch, setCardsSearch] = useState(null);
    const [downloadDisable, setDownloadDisable] = useState(false);
    const { Search } = Input;
    const [isError, setIsError] = useState(false);
    const [isLoadding, setIsLoadding] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [totalRow, setTotalRow] = useState(0);

    const onChangePage = page =>{
        var memberCards = [];
        for(var i = ((page - 1) * 10); i < (page * 10); i++){
            if(i < cards.length){
                memberCards.push(cards[i]);
            }
        }
        setCardsSearch(memberCards);
    }

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }

    function download(){
        setDownloadDisable(true);
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            axios.get(serverAPIIp + 'api/Member/DownloadMembereCardList', {headers:config})
            .then((response) =>{
                if(response.data.isSuccess){
                    window.open(serverAPIIp + response.data.responseData, "_blank");
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
                setDownloadDisable(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setDownloadDisable(false);
                }
            })
        }
    }

    function RemoveVietnameseAccents(str) {
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
         str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
         str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
         str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
         str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
         str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
         str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
         str = str.replace(/đ/g, "d");
         str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
         str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
         str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
         str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
         str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
         str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
         str = str.replace(/Đ/g, "D");
         return str;
     }

    function onSearchCard(value){
        var memberCards = [];
        cards.forEach(item => {
            if(item.studentId.indexOf(value) >= 0
            || RemoveVietnameseAccents(item.firstName + ' ' + item.lastName).toLowerCase().indexOf(RemoveVietnameseAccents(value).toLowerCase()) >= 0){
                memberCards.push(item);
            }
        });
        if(value !== "")
        {
            setTotalRow(memberCards.length);
            setCardsSearch(memberCards);
        }else{
            setTotalRow(cards.length);
            setCardsSearch(cards);
        }
    }

    function DownloadMemberCardById(memberId){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const member = {
                memberId: memberId
            }
            axios.get(serverAPIIp + 'api/Member/DownloadMembereCardById', {params: member, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    window.open(serverAPIIp + response.data.responseData, "_blank");
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors[0]);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    async function GetMemberCardList(){
        setIsLoadding(true);
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            await axios.get(serverAPIIp + 'api/Member/GetFolderCardList', {headers:config})
            .then((response) =>{
                if(response.data.isSuccess){
                    setCards(response.data.responseData);
                    var memberCard = []
                    for(var i = 0; i < 10; i++){
                        if(i < response.data.responseData.length){
                            memberCard.push(response.data.responseData[i]);
                        }
                    }
                    setCardsSearch(memberCard);
                    setTotalRow(response.data.responseData.length);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    setIsError(false);
                    setErrorMess(response.data.errors[0]);
                }
                setIsLoadding(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setErrorMess("Mất kết nối máy chủ");
                    setIsLoadding(false);
                    setIsError(true);
                }
            })
        }
    }

    useEffect(async () => {
        GetMemberCardList();
    }, []);

    function MemberCard(props){
        return(
            <div>
                <Card border="primary container">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Row style={{marginTop: 15}}>
                            <Col flex="auto">
                                <span className="text-primary" style={{fontSize: 12}}>
                                    {props.dataFromParent.firstName + ' ' + props.dataFromParent.lastName}
                                </span>
                            </Col>
                            <Col flex="32px">
                                <Button 
                                type="primary" 
                                shape="circle"
                                icon={<DownloadOutlined />}
                                onClick={() => {DownloadMemberCardById(props.dataFromParent.memberId)}}
                                size={"middle"}/>
                            </Col>
                        </Row>
                        <hr/>
                    </div>
                    <Row>
                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                            <Image style={{marginBottom:15}} src={serverAPIIp + props.dataFromParent.cardFrontFileName}/>
                        </Col>
                        <Col style={{marginBottom:15}} span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                            <Image src={serverAPIIp + props.dataFromParent.cardBackFileName}/>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

    return(
        <div>
            {
                !isLoadding?
                <div>
                    <div >
                        {
                            cards?
                            <Row>
                                <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                                    <Search 
                                    style={{marginBottom:15}} 
                                    placeholder="Nhập vào tên hoặc mã sinh viên"
                                    allowClear
                                    onSearch={onSearchCard}
                                    onChange={(e) => onSearchCard(e.target.value)}
                                    enterButton />
                                </Col>
                                <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                                    <Button type="primary" 
                                    style={{marginBottom:15, float: "right"}}
                                    icon={<FontAwesomeIcon style={{marginRight: 10}} icon={faDownload}/>} 
                                    onClick={() => {download()}}
                                    disabled={downloadDisable}
                                    size="middle">Tải Xuống Toàn Bộ Thẻ</Button>
                                </Col>
                            </Row>
                            : null
                        },
                        {
                            <div>
                                {
                                    cardsSearch?
                                    <div>
                                        {
                                            cardsSearch.map((item) => (<MemberCard dataFromParent = {item}/>))
                                        }
                                        <Pagination 
                                            total={totalRow} 
                                            showTotal={(total, range) => `${range[0]}->${range[1]} / ${total} thẻ`}
                                            defaultPageSize={10}
                                            defaultCurrent={1}
                                            onChange={onChangePage}
                                            showSizeChanger={false}
                                        />
                                    </div>
                                    :
                                    <Result
                                        status={isError? "500" : "404"}
                                        title={errorMess}
                                        subTitle={isError 
                                        ? "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !" 
                                        : "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
                :
                <Skeleton loading={isLoadding} active avatar></Skeleton>
            }
        </div>
    )
}
export default MemberWithCard;