import { Helmet } from 'react-helmet'
import ChatFacebook from './ChatFacebook';
import Sliderbar from './Sliderbar';
import React, {useEffect, useState}  from 'react';
import { message, Layout} from 'antd';
import axios from 'axios';

function ContactUs(){
    const { Content, Footer, Header } = Layout;
    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    function GetContact(){
        setIsLoading(true);
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
        }
        axios.get(serverAPIIp + 'api/Regulation/GetRegulation', {headers: headers})
        .then((response) =>{
            if(response.data.isSuccess){
                // console.log(response.data.responseData);
                setValue(response.data.responseData.content);
            }else{
                message.error(response.data.responseData.errors);
            }
            setIsLoading(false);
        })
        .catch((response)=>{
            message.error("Mất kết nối máy chủ");
            setIsLoading(false);
        })
        setIsLoading(false);
    }

    useEffect(() => {
        GetContact();
    }, []);

    const TITLE = "Thông Tin";
    return(
        <>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <div>
                <Sliderbar/>
                {
                    !isLoading?
                    <div className='container'>
                        <div class="ql-container ql-snow" style={{background: "white"}}>
                        <div class="ql-editor" dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                    </div>
                    :null
                }
                <ChatFacebook/>
            </div>
        </>
    )
}
export default ContactUs;