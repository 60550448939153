import React, {useState, useEffect} from 'react';
import Cookies from 'universal-cookie';
import { Form, Input, Upload, message, Button, Row, Col} from 'antd';
import axios from 'axios';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './css/firstsignin.css';
import TopImage from './img/hero-img.png';
import Value1 from './img/values-1.png';
import Value2 from './img/values-2.png';
import Value3 from './img/values-3.png';
import ImgCrop from 'antd-img-crop';
import { Helmet } from 'react-helmet';

function FirstSignIn(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [loadingformwd, setLoadingformwd] = useState(false);
    const [oldPass, setOldPass] = useState(null);
    const [newPass, setNewPass] = useState(null);
    const [rePass, setRePass] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isChangePass, setIsChangePass] = useState(false);
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [progress, setProgress] = useState(0);
    const [avatar, setAvatar] = useState(null);
    const [form] = Form.useForm();

    const uploadButton = (
        <div>
          {uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Tải lên</div>
        </div>
      );

    const handleOnChange = ({ fileList: newFileList }) => {
        setDefaultFileList(newFileList);
    };

    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }
    const handlePasswordOk = () =>{
        setLoadingformwd(true);
        formwd();
    }
    const formwd = async() => {
        const token = cookies.get('dtu-svc-token');
        const changePassword = {
            'oldPassword': oldPass,
            'newPassword': newPass,
            'rePassword': rePass,
        };

        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            "Authorization": 'Bearer ' + token
        }

        await axios.post(serverAPIIp + 'api/Account/ChangePassword', JSON.stringify(changePassword), { headers })
            .then((response) =>{
                if(response.data.isSuccess){
                    message.success("Thay đổi mật khẩu thành công");
                    cookies.set('dtu-svc-isDefaulePassword', false, { path: '/' });
                    setIsChangePass(true);
                    if(avatar.indexOf('Avatar/LogoCLB.png') < 0){
                        window.location.href="/";
                    }
                }else{
                    message.error(response.data.errors[0]);
                }
                setLoadingformwd(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối với máy chủ");
                    setLoadingformwd(false);
                }
            });
    }

    const onChangeRenewPasswd = e =>{
        setRePass(e.target.value);
        if(newPass === null
            || oldPass === null
            || e.target.value === null
            || newPass !== e.target.value){
                setIsPasswordValid(false);
        }else{
                setIsPasswordValid(true);
        }
    }

    const onChangeOldPassword = e =>{
        var passwd = e.target.value;
        setOldPass(passwd);
        if(newPass === null
            || passwd === null
            || rePass === null
            || newPass !== rePass){
                setIsPasswordValid(false);
        }else{
            setIsPasswordValid(true);
        }
    }

    const onChangeNewPassword = e =>{
        var passwd = e.target.value;
        setNewPass(passwd);
        var regexStr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if(regexStr.test(passwd) === false){
            setIsPasswordValid(false);
        }else{
            if(e === null
                || oldPass === null
                || rePass === null
                || passwd !== rePass){
                    setIsPasswordValid(false);
            }else{
                setIsPasswordValid(true);
            }
        }
    }

    const uploadImage = async (options) => {
        setUploading(true);
        const {onSuccess, onError, file, onProgress } = options;
        const token = cookies.get("dtu-svc-token");
        var fmData = new FormData();
        const config = {
          headers: { 
              'content-type': 'multipart/form-data', 
              'Authorization': 'Bearer ' + token, 
              'accept': '*/*'
            },
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setProgress(percent);
              if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
              }
              onProgress({ percent: (event.loaded / event.total) * 100 });
            },
        };
        fmData.append('AvatarFromFile', file);
        try {
          const res = await axios.post(serverAPIIp + 'api/Account/ChangeAvatar', fmData, config );
    
          onSuccess('Ok');
          if(res.data.isSuccess){
              message.success("Cập nhật hình đại diện thành công");
              cookies.set('dtu-svc-avatarPath', res.data.responseData, { path: '/' });
              setAvatar(res.data.responseData);
              if(isChangePass){
                setTimeout(() => {
                    window.location.href="/";
                  }, 1500);
              }
            }else{
                if(res.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(res.data.errors);
          }
          setUploading(false);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } catch (err) {
            if(err.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
                onError({ err });
                setUploading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        }
    };

    useEffect(() => {
        setAvatar(cookies.get('dtu-svc-avatarPath'));
        if(cookies.get('dtu-svc-isDefaulePassword') === "false" || cookies.get('dtu-svc-token') === undefined){
            window.location.href="/";
        }
    }, []);

    return(
        <div style={{marginLeft: 0}}>
            <Helmet>
                <title>{ "Lần đầu đăng nhập" }</title>
            </Helmet>
            <section style={{marginLeft: 0}} id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row container">
                    <Row justify="center" align="middle">
                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                            <h1 >Xin chào </h1><h1 style={{color: 'red'}}>{cookies.get("dtu-svc-fullname")}</h1>
                            <h2 >Chào mừng bạn đến với Hệ thống quản lý CLB Sinh viên Tình nguyện Đại học Duy Tân</h2>
                            <h3>Bạn nhìn thấy trang này vì đây là lần đầu bạn đăng nhập vào hệ thống. Hãy tiến hành đổi mật khẩu để tiếp tục sử dụng</h3>
                        </Col>
                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                            <img src={TopImage} className="img-fluid" alt="" />
                        </Col>
                    </Row>
                </div>
            </div>
            </section>{/* End Hero */}
            <main id="main" className="container">
            
            {/* ======= Values Section ======= */}
            <section id="values" className="values">
                <div className="container" data-aos="fade-up">
                <header className="section-header">
                    <p style ={{marginTop: 20}}>Các chức năng chính của hệ thống</p>
                </header>
                <div className="row">
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
                    <div className="box">
                        <img src={Value1} className="img-fluid"/>
                        <h3>Quản lý Thu/Chi.</h3>
                        <p>Chức năng này sẽ giúp thủ quỹ thiết lập các khoản thu chi và báo cáo thu chi hàng tháng. Ngoài ra còn có thể xem tình trạng quỹ theo mốc thời gian.</p>
                    </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0" >
                    <div className="box">
                        <img src={Value2} className="img-fluid" alt="" />
                        <h3>Quản lý thành viên.</h3>
                        <p>Với số lượng thành viên quá đông dẫn đến việc quản lý thủ công là không hiệu quả. Hệ thống sẽ giúp quản lý hồ sơ của thành viên. Để an toàn chúng tôi đã mã hoá toàn bộ thông tin cá nhân của thành viên ở CSDL</p>
                    </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={600}>
                    <div className="box">
                        <img src={Value3} className="img-fluid" alt="" />
                        <h3>Quản lý các hoạt động.</h3>
                        <p>Ngạc nhiên chưa? Từ nay các bạn có thể nắm thông tin các hoạt động, đăng ký tham gia hoạt động với 1 cái click. Ngoài ra còn tích hợp điểm danh bằng thẻ thành viên/thẻ sinh viên khi tham gia hoạt động.</p>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <header className="section-header">
                <p style ={{marginTop: 20}}>Bảo vệ tài khoản của bạn.</p>
            </header>
            <div className="values">
                <Row className="box" justify="center" align="middle" style={{marginBottom: 30}}>
                    <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                        <div className="content">
                            <h2>Đổi mật khẩu</h2>
                            <p  style={{textAlign:"left", fontSize:16}}>Có thể bạn đã biết: Mật khẩu mặc định của CLB sẽ được tạo thành từ: MSSV + Ngày sinh của thành viên. Mật khẩu như vậy là không an toàn cho tài khoản của bạn. Vậy nên để tiếp tục sử dụng Hệ thống của chúng tôi, bạn vui lòng thay đổi mật khẩu của mình.</p>
                            <p style={{textAlign:"left", fontSize:18, fontWeight: 600, color: "red"}}>Chú ý:</p>
                            <ul style={{textAlign:"left", fontSize:18, fontWeight: 600, color: "red"}}>
                                <li>
                                    Độ dài tối thiểu của mật khẩu là 8 ký tự.
                                </li>
                                <li>
                                    Mật khẩu phải có ít nhất 01 chữ cái viết thường.
                                </li>
                                <li>
                                    Mật khẩu phải có ít nhất 01 chữ cái viết hoa.
                                </li>
                                <li>
                                    Mật khẩu phải có ít nhất 01 số.
                                </li>
                                <li>
                                    Mật khẩu phải có ít nhất 01 ký tự đặc biệt.
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                        <div className="container">
                            <Form from={form}>
                                <Row align="middle">
                                    <Col style={{textAlign: "left"}} span={8} className={"col-md-5"} xs={24} xl={8}>
                                        <h6>Mật khẩu cũ</h6>
                                    </Col>
                                    <Col span={16} className={"col-md-5 col-md-offset-2"} xs={24} xl={16}>
                                        <Form.Item
                                            name="oldpassword"
                                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu cũ!' }]}
                                            onChange={onChangeOldPassword}
                                        >
                                            <Input.Password placeholder="Nhập mật khẩu cũ"></Input.Password>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row align="middle">
                                    <Col style={{textAlign: "left"}} span={8} className={"col-md-5"} xs={24} xl={8}>
                                        <h6>Mật khẩu mới</h6>
                                    </Col>
                                    <Col span={16} className={"col-md-5 col-md-offset-2"} xs={24} xl={16}>
                                        <Form.Item
                                            name="newpassword"
                                            onChange={onChangeNewPassword}
                                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới!' }]}
                                        >
                                            <Input.Password placeholder="Mật khẩu mới"></Input.Password>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row align="middle">
                                    <Col style={{textAlign: "left"}} span={8} className={"col-md-5"} xs={24} xl={8}>
                                        <h6>Xác nhận mật khẩu</h6>
                                    </Col>
                                    <Col span={16} className={"col-md-5 col-md-offset-2"} xs={24} xl={16}>
                                        <Form.Item
                                        onChange={onChangeRenewPasswd}
                                            name="renewpassword"
                                            rules={[{ required: true, message: 'Vui lòng nhập xác nhận mật khẩu!' }]}
                                        >
                                            <Input.Password placeholder="Nhập lại mật khẩu mới"></Input.Password>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row align="middle">
                                    <Col style={{textAlign: "left"}} span={8} className={"col-md-5"} xs={24} xl={8}>
                                    </Col>
                                    <Col span={16} className={"col-md-5 col-md-offset-2"} xs={24} xl={16}>
                                    <Button disabled={!isPasswordValid} loading={loadingformwd} type="primary" onClick={() =>{handlePasswordOk()}} align="middle">Đổi mật khẩu</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            {
                cookies.get('dtu-svc-avatarPath').indexOf('Avatar/LogoCLB.png') >= 0?
                <div className="values">
                    <header className="section-header">
                        <p style ={{marginTop: 20}}>Thiết lập ảnh đại diện</p>
                    </header>
                    <Row className="box" justify="center" align="middle" style={{marginBottom: 30}}>
                        <Col span={12} className={"col-md-5"} xs={24} xl={12}>
                            <div className="content">
                                <h2>
                                    Tải lên ảnh đại diện
                                </h2>
                                <p  style={{textAlign:"left", fontSize:18, fontWeight: 600}}>Có thể bạn chưa biết:</p>
                                <ul style={{textAlign:"left", fontSize:16}}>
                                    <li>
                                        Ảnh đại diện sẽ hiển thị khi bạn đăng nhập vào tài khoản
                                    </li>
                                    <li>
                                        Ảnh đại diện sẽ được CLB sử dụng để làm thẻ thành viên
                                    </li>
                                    <li>
                                        Ảnh đại diện phải có kích thước tối thiểu: Chiều rộng: 950px, Chiều cao: 1320px
                                    </li>
                                    <li>
                                        Ảnh đại diện chỉ được có 1 mình bạn
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col span={12} className={"col-md-5 col-md-offset-2"} xs={24} xl={12}>
                            <ImgCrop aspect={95 / 132} cropperProps={"avatarCrop"}
                                modalTitle="Cắt ảnh"
                                modalOk={"Lưu"}
                                modalCancel={"Huỷ"}>
                                <Upload
                                    accept="image/*"
                                    className={"avatar-uploader image-upload-grid"}
                                    customRequest={uploadImage}
                                    onChange={handleOnChange}
                                    listType="picture-card"
                                    defaultFile={defaultFileList}
                                    maxCount={1}
                                >
                                    {uploadButton}
                                </Upload>
                            </ImgCrop>
                        </Col>
                    </Row>
                </div>
                : null
            }
            </main>
            <div style={{margin: 15}}><p>©2021 CLB Sinh Viên Tình Nguyện - ĐH Duy Tân</p><br/></div>
        </div>
    )
}

export default FirstSignIn;