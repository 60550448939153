import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Carousel, Skeleton, message, Image} from 'antd';

function Sliderbar(){
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    function getSliderbar(){
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        axios.get(serverAPIIp + 'api/Newspaper/GetListBanner', {headers: headers})
        .then((response) =>{
            if(response.data.result.isSuccess){
                setData(response.data.result.responseData);
            }else{
                message.error(response.data.result.errors[0]);
            }
            setLoading(false);
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
        })
    }
    useEffect(() => {
        getSliderbar();
    }, []);
    return(
        <div>
            {
                loading?
                <Skeleton active/>
                :
                <div>
                    {
                        data?
                        <Carousel autoplay style={{marginBottom: 15}}>
                            {
                                data.map((item) =>{
                                    return(
                                        <Image src={serverAPIIp + item.bannerPath}/>
                                        // <div className={"contentStyle"}>
                                        //     <img style={{maxHeight: 300}} src={serverAPIIp + item.bannerPath}/>
                                        // </div>
                                    )
                                })
                            }
                        </Carousel>
                        :null
                    }
                </div>
            }
        </div>
    )
}
export default Sliderbar;