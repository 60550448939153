import React, { useState } from 'react';
import moment from 'moment';
import './Message.css';
import { Image } from 'antd';

export default function Message(props) {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp
    } = props;

    function linkify(text) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function(url) {
        let color = isMine?"color: #FA0004; font-weight: 600; " : "";
        return '<a style="'+color+' word-wrap: break-word;" href="' + url + '" target="_blank">' + url + "</a>";
      });
    }

    const friendlyTimestamp = moment(data.datetime).format('LLLL');
    return (
      <div>
        <div className={[
          'message',
          `${isMine ? 'mine' : ''}`,
          `${startsSequence ? 'start' : ''}`, 'end'
        ].join(' ')}>
          {
            showTimestamp &&
              <div className="timestamp">
                { friendlyTimestamp }
              </div>
          }
          <div className={isMine?'name mine': 'name'}>
            <span>{data.author}</span>
            <br/>
            <span style={{fontSize: 12, fontWeight: 400}}>{friendlyTimestamp}</span>
          </div>
          <div className="bubble-container">
            {
              isMine === false?
              <img style={{margin: 5, borderRadius: "25px 25px 25px 25px", objectFit:"cover"}} src={data.avatar} height={40} width={40}/>
              : null
            }
            <div className="bubble" title={friendlyTimestamp}>
              { data.content? 
                <div style={{wordWrap: "break-word"}} dangerouslySetInnerHTML={{ __html: linkify(data.content) }} />
              : null }
              {
                data.imagePath?
                <Image
                  style={{marginTop:5, borderRadius: "10px 10px 10px 10px", objectFit:"cover"}}
                  width={200}
                  src={data.imagePath}
                />
                : null
              }
            </div>
            {
              isMine ?
              <img style={{margin: 5, borderRadius: "25px 25px 25px 25px", objectFit:"cover"}} src={data.avatar} height={40} width={40}/>
              : null
            }
          </div>
        </div>
        <br/>
      </div>
    );
}