import React, {useState,}  from 'react';
import { Tabs, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import MemberWithCard from './MemberWithCard';
import MemberWithoutCard from './MemberWithoutCard';
import ReMemberWithCard from './ReMemberWithCard';

function MemberCard(){
    const TITLE = "Thẻ Thành Viên";
    const { Content, Footer, Header } = Layout;
    // const { TabPane } = Tabs;
    // const [page, setPage] = useState(<MemberWithCard/>);
    // function callback(key) {
    //     if(key === "1"){
    //         setPage(<MemberWithCard/>);
    //     }
    //     if(key === "2"){
    //         setPage(<MemberWithoutCard/>);
    //     }
    //     if(key === "3"){
    //         setPage(<ReMemberWithCard/>);
    //     }
    // }
    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Helmet>
                <title>{ TITLE }</title>
            </Helmet>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <span className={"activetitle"}>Quản Lý Thẻ Thành Viên</span>
                </Header>
                <Content >
                    <Layout style={{background: "#fff", minHeight: "85vh"}} >
                        {/* <Tabs onChange={callback} type="card">
                            <TabPane tab="Thẻ Đã Tạo" key="1">
                                {
                                    page
                                }
                            </TabPane>
                            <TabPane tab="Thành Viên Chưa Có Thẻ" key="2">
                                {
                                    page
                                }
                            </TabPane>
                            <TabPane tab="Làm Lại Thẻ" key="3">
                                {
                                    page
                                }
                            </TabPane>
                        </Tabs> */}
                        <MemberWithoutCard/>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
}
export default MemberCard;