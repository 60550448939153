import {
    BrowserRouter as Router,
    useRouteMatch,
    useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import React, {useEffect, useState, useRef}  from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import './css/newspaper.css';
// import moment from 'moment';
import { Table, message, Layout, Row, Col, Card, Form, Button, Modal, Skeleton, Result, Space, Tag } from 'antd';
import Moment from 'react-moment';

function NewsTopic(){
    const cookies = new Cookies();
    const serverAPIIp = 'https://api.dtusvc.com/';
    let { newspaperId } = useParams();
    const [loading, setLoading] = useState(false);
    const [loadingAll, setLoadingAll] = useState(false);
    const [data, setData] = useState(null);
    const [isError500, setIsError500] = useState(true);
    const [errorMess, setErrorMess] = useState(null);
    const [newspaperList, setNewspaperList] = useState(null);
    let match = useRouteMatch();

    const getNewspaper = async() =>{
        setLoading(true);
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        var requestData = {
            newspaperId: newspaperId,
        };
        await axios.get(serverAPIIp + 'api/Newspaper/GetNewspaperServiceById', {params: requestData, headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
                setData(response.data.responseData);
                setLoading(false);
            }else{
                setLoading(false);
                message.error(response.data.errors);
                setIsError500(true);
                setErrorMess(response.data.errors);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoading(false);
            setIsError500(false);
            setErrorMess("Mất kết nối với máy chủ");
        })
    }

    const getNewspaperList = async () =>{
        setLoadingAll(true);
        const config = {
            "Content-Type": "application/json",
            "accept": "*/*"
        }
        var requestData = {
            newspaperId: newspaperId,
        };
        await axios.get(serverAPIIp + 'api/Newspaper/GetListNewspaper', {params: requestData, headers: config})
        .then((response) =>{
            if(response.data.isSuccess){
                setNewspaperList(response.data.responseData);
                setLoadingAll(false);
            }else{
                setLoadingAll(false);
                message.error(response.data.errors);
            }
        })
        .catch((response)=>{
            message.error("Mất kết nối với máy chủ");
            setLoadingAll(false);
        })
    }

    useEffect(() => {
        getNewspaper();
        getNewspaperList();
    }, []);

    return(
        <div style={{margin: 15}}>
            {
                loading?
                <Skeleton active/>
                :
                <div style={{marginTop: 10}}>
                    {
                        data?
                        <div className="newspaper">
                            <h4 style={{fontWeight: 600}}>{data.title}</h4>
                            <img src={serverAPIIp + data.posterPath}/>
                            <div class="ql-container ql-snow" style={{border: "white"}}>
                                <div style={{minHeight: "0vh"}} class="ql-editor" dangerouslySetInnerHTML={{ __html: data.content }} />
                            </div>
                            <p style={{textAlign:"left", marginLeft: 10}}>{"Theo: " + `${data.uploaderFirstName} ${data.uploaderLastName}`}</p>
                            <p style={{textAlign:"left", marginLeft: 10}}>{"Ngày: "}<Moment date={data.createDate} format="HH:mm:ss DD-MM-YYYY"/></p>
                            <div>
                                {
                                    loadingAll?
                                    <Skeleton active/>
                                    :
                                    <div>
                                    {
                                        newspaperList?
                                        <div>
                                            <hr/>
                                            <h5 style={{textAlign:"left", marginTop: 20, marginLeft: 20,fontWeight: 600}}>Xem thêm</h5>
                                            <hr/>
                                            {
                                                newspaperList.map(item =>{
                                                    return(
                                                        <div onClick={()=>{window.location.href= `${match.path.replace(":newspaperId", "")}${item.id}`;}}>
                                                            <Card className='hotnews' style={{margin: 10}}>
                                                                <Row align="middle">
                                                                    <Col span={8} xs={8} xl={8}>
                                                                        <img src={serverAPIIp + item.posterPath}/>
                                                                    </Col>
                                                                    <Col span={16} xs={16} xl={16}>
                                                                        <p style={{textAlign:"left", marginLeft: 10, fontWeight: 600,}}>{item.title}</p>
                                                                        <p style={{textAlign:"left", marginLeft: 10}}>{"Ngày: "}<Moment date={item.createDate} format="HH:mm:ss DD-MM-YYYY"/></p>
                                                                        <p style={{textAlign:"left", marginLeft: 10, marginBottom: 0, marginTop: 10}}>{"Người đăng: "}{item.uploaderFirstName + ' ' + item.uploaderLastName}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        :
                        <Result
                            status={isError500? "500" : "404"}
                            title={errorMess}
                            subTitle={isError500 
                            ? "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"
                            : "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !"}
                        />
                    }
                </div>
            }
        </div>
    );
}
export default NewsTopic;