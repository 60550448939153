import React, {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';
import { Form, Row, Col, DatePicker, Modal , message, Skeleton, Result, Image, Button, Table, Tag, Checkbox } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'react-moment';
import {faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import {Card} from 'react-bootstrap';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    BrowserRouter as Router,
    useParams
} from "react-router-dom";

function ActivityHistory(){
    const cookies = new Cookies();
    const { RangePicker } = DatePicker;
    const [data, setData] = useState(null);
    const [memberData, setMembereData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const serverAPIIp = 'https://api.dtusvc.com/'; 
    const [errorMess, setErrorMess] = useState(null);
    const [isError, setIsError] = useState(false);
    const [checkInId, setCheckInId] = useState(null);
    const [isLoadingChange, setIsLoadingChange] = useState(false);
    const [isChangeCheckIn, setIsChangeCheckIn] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [score, setScore] = useState(0);
    let { memberId } = useParams();
    const [roleCreateActivity, setCreateActivity] = useState([1,2]);
    const [roleId, setRoleId] = useState(parseInt(cookies.get('dtu-svc-role')));

    const dateFormat = "YYYY-MM-DD hh:mm:ss";
    const [formChange] = Form.useForm();
    function removeCookie(){
        cookies.remove('dtu-svc-fullname');
        cookies.remove('dtu-svc-accountId');
        cookies.remove('dtu-svc-avatarPath');
        cookies.remove('dtu-svc-token');
        cookies.remove('dtu-svc-roleId');
        cookies.remove('dtu-svc-role');
        cookies.remove('dtu-svc-studentId');
        window.location.href="/";
    }
    const openChangeCheckIn = (itemId) => {
        const dateFormat = 'YYYY/MM/DD hh:mm:ss';
        var chk = data.find(function (item){
            return item.id === itemId;
        });
        formChange.setFields([{
            name: "changeDate",
            value:[moment(chk.inStart, dateFormat).add(7, 'hours'), moment(chk.outStart, dateFormat).add(7, 'hours')]
        }]);
        setIsChangeCheckIn(true);
        setCheckInId(itemId);
    }

    const cancelModal = () =>{
        setIsChangeCheckIn(false);
        setIsLoadingChange(false);
    }

    const deleteCheckIn = async (id) =>{
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const checkin = {
                checkInId: id
              }
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/RemoveCheckIn', {params: checkin, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    getActivityHistory(memberId);
                    message.success("Xoá điểm danh thành công");
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                }
            })
        }
    }

    function confirm(id) {
        Modal.confirm({
          title: 'Xoá!',
          icon: <ExclamationCircleOutlined />,
          content: 'Việc xoá của bạn sẽ không khôi phục lại được. Bạn có chắc chắn muốn xoá?',
          okText: 'Xoá',
          cancelText: 'Huỷ Bỏ',
          onOk:() =>{deleteCheckIn(id)}
        });
    }

    const onChangeCheckIn = () => {
        var valuedate = formChange.getFieldValue("changeDate");
        if(valuedate !== null && valuedate !== undefined){
            formChange.setFields([{
                name: "changeDate",
                errors:[]
            }]);
            changeCheckIn(valuedate);
        }else{
            formChange.setFields([{
                name: "changeDate",
                errors:["Vui lòng chọn ngày tháng!"]
            }]);
        }
    }

    function onChangeCheckBox(e) {
        setIsCheckAll(e.target.checked);
      }

    const changeCheckIn = async (valuedate) =>{
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const checkInModel = {
                checkInID: checkInId,
                startDate: valuedate[0].toISOString(),
                endDate: valuedate[1].toISOString(),
                isAll: isCheckAll
            }

            const headers = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            await axios.post(serverAPIIp + 'api/ActivitiesAndEvents/ChangeCheckIn', JSON.stringify(checkInModel), {headers})
            .then((response) =>{
                if(response.data.isSuccess){
                    getActivityHistory(memberId)
                    setIsLoadingChange(false);
                    message.success("Cập nhật điểm danh thành công");
                    cancelModal();
                    setIsCheckAll(false);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                    }
                    message.error(response.data.errors);
                    setIsLoadingChange(false);
                }
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setIsLoadingChange(false);
                }
            })
        }
    }

    const GetMemberById = async() =>{
        setIsLoading(true);
        const token = cookies.get("dtu-svc-token");
        const headers = {
            "Content-Type": "application/json",
            "accept": "*/*",
            'Authorization': 'Bearer ' + token,
        }
        var requestData = {
            memberId: memberId
        };
        await axios.get(serverAPIIp + 'api/Member/GetMemberInfo', {headers:headers, params:requestData})
        .then((response) =>{
            if(response.data.isSuccess){
                setMembereData(response.data.responseData);
                setIsLoading(false);
            }else{
                if(response.data.errors[0].indexOf("(401)") >= 0){
                    removeCookie();
                }
                message.error(response.data.errors[0]);
                setIsLoading(false);
            }
        })
        .catch((response)=>{
            if(response.toString().indexOf(401) >= 0){
                removeCookie();
                message.error("Phiên đăng nhập đã hết hạn");
            }else{
                message.error("Mất kết nối với máy chủ");
            }
            setIsLoading(false);
        })
    }

    function getActivityHistory(memberId){
        const token = cookies.get('dtu-svc-token');
        if(token !== null){
            const config = {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": 'Bearer ' + token
            }
            const member = {
                memberId: memberId
              }
            axios.get(serverAPIIp + 'api/ActivitiesAndEvents/GetActivityHistoryByMember', {params: member, headers: config})
            .then((response) =>{
                if(response.data.isSuccess){
                    setData(response.data.responseData);
                    calScore(response.data.responseData);
                    setIsError(false);
                }else{
                    if(response.data.errors[0].indexOf("(401)") >= 0){
                        removeCookie();
                        message.error("Phiên đăng nhập đã hết hạn");
                    }
                    setErrorMess(response.data.errors[0]);
                    setIsError(false);
                }
                setIsLoading(false);
            })
            .catch((response)=>{
                if(response.toString().indexOf(401) >= 0){
                    removeCookie();
                    message.error("Phiên đăng nhập đã hết hạn");
                }else{
                    message.error("Mất kết nối máy chủ");
                    setIsLoading(false);
                    setErrorMess("Mất kết nối với máy chủ");
                    setIsError(true);
                }
            })
        }
    }

    function calScore(listCheckIn){
        var totalScore = 0;
        listCheckIn.forEach(element => {
            totalScore += parseFloat(element.score);
        });
        setScore(totalScore);
    }

    useEffect(() => {
        getActivityHistory(memberId);
        GetMemberById(memberId);
    }, [])

    const columns = [
        {
            title: "STT",
            dataIndex: "number",
            key: "number",
            width: 45,
        },
        {
            title: "Chương Trình",
            dataIndex: "eventName",
            key: "eventName",
        },
        {
            title: "Giờ Vào",
            dataIndex: "inStart",
            key: "inStart",
            render: (text, record, index) => {
                if (record.isAdditionalScore) {
                  return {
                    children: <span>{record.reason}</span>,
                    props: {
                      rowSpan: 1,
                    },
                  };
                }
                return <span><Moment date={record.inStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
              },
        },
        {
            title: "Giờ Ra",
            dataIndex: "outStart",
            key: "outStart",
            editable: true,
            render: (text, record) => {
                if (record.isAdditionalScore) {
                  return null;
                }
                else
                {
                    if (record.score === 0) {
                        return <span style={{ color: 'red' }}>Chưa check out</span>;
                    }
                    else{
                        return <span><Moment date={record.outStart} format="DD-MM-YYYY HH:mm:ss" /></span>;
                    }
                }
              },
        },
        {
            title: "Điểm",
            dataIndex: "score",
            key: "score",
            render: scores =>
                <Row align="middle">
                    <Col>
                    {
                        scores === 0?
                            <Tag color="#f50">{parseInt(scores)}</Tag>
                        :
                            <Tag color="#108ee9">{scores.toFixed(2)}</Tag>
                    }
                    </Col>
                </Row >
        },
        {
            title: "Tình Trạng",
            dataIndex: "isSuccsess",
            key: "isSuccsess",
            render: item => <Tag color={item?"#108ee9":"#f50"}>{item? "Hoàn Thành" : "Chưa Hoàn Thành"}</Tag>
        },
        {
            title: "Quản Lý",
            dataIndex: "managerName",
            key: "managerName",
        },
        {
            title: "Hành Động",
            dataIndex: "id",
            key: "id",
            render: itemId => 
            {
                <Row>
                    {
                        roleCreateActivity.includes(roleId)?
                        <Col style={{padding: 2}}>
                            <Button onClick={() => {openChangeCheckIn(itemId);}} type={"primary"} icon={<FontAwesomeIcon icon={faEdit} />}/>
                        </Col>
                        : null
                    }
                    {
                        roleCreateActivity.includes(roleId)?
                        <Col style={{padding: 2}}>
                            <Button type={"primary"} onClick={() => {confirm(itemId)}} icon={<FontAwesomeIcon icon={faTrash} />} danger/>
                        </Col>
                        : null
                    }
                    
                </Row>
            }
        },
    ]

    return(
        <div>
            <div className="card h-100">
                {
                    memberData?
                    <div className="card-body">
                    <div className="row gutters">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h6 className="mb-2 text-primary">
                                {memberData.lastName + ' ' + memberData.firstName}
                            </h6>
                            <hr/>
                        </div>
                        <div>
                            <Modal 
                            visible={isChangeCheckIn}
                            onOk={changeCheckIn}
                            onCancel={cancelModal}
                            centered
                            title={"Cập nhật thời gian điểm danh"}
                            footer={[
                                <Button disabled={isLoadingChange} key="back" onClick={cancelModal}>
                                Quay lại
                                </Button>,
                                <Button key="submit" type="primary" loading={isLoadingChange} onClick={onChangeCheckIn}>
                                Cập nhật
                                </Button>
                            ]}>
                                <Form form={formChange}>
                                    <Form.Item name="changeDate" 
                                    style={{textAlign: "center"}}
                                    rules={[{ required: true, message: 'Vui lòng chọn ngày tháng!' }]}>
                                        <RangePicker 
                                            format={dateFormat} 
                                            showTime
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        name="isSetFullList"
                                    >
                                        <Checkbox defaultChecked={isCheckAll} onChange={(e) => onChangeCheckBox(e)}>Áp dụng thời gian ra cho toàn bộ danh sách.</Checkbox>
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Row>
                                <Col xl={6} className={"col-md-5"}>
                                    <Image 
                                        src={serverAPIIp + memberData.avatarPath} 
                                        alt="Maxwell Admin" 
                                        style={{width: 150, height: 150, objectFit: "cover"}}/>
                                </Col>
                                <Col style={{fontSize: 16, textAlign: "left"}}
                                    className={"col-md-5 col-md-offset-2"}>
                                    <Col style={{marginBottom: 10, marginTop: 10}}>
                                        <span>Mã sinh viên: {memberData.studentId}</span>
                                    </Col>
                                    <Col style={{marginBottom: 10, marginTop: 10}}>
                                        <span>Khoa/Viện: {memberData.faculty.facultyName}</span>
                                    </Col>
                                    <Col style={{marginBottom: 10, marginTop: 10}}>
                                        <span>Giới Tính: {memberData.sex ? "Nam" : "Nữ"}</span>
                                    </Col>
                                    <Col style={{marginBottom: 10, marginTop: 10}}>
                                        <span>Điểm Hoạt Động: <Tag color={score > 0 ? "#108ee9" :"#f50"}>{score.toFixed(2)}</Tag></span>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                :<Skeleton active />
                }
                
            </div>
            <Card.Title>Quá Trình Tham Gia</Card.Title>
            {
                isLoading?
                <Skeleton active />
                :
                <div>
                    {
                        data?
                        <Table columns={columns} 
                        dataSource={data} 
                        pagination={{hideOnSinglePage: true}}
                        scroll={{x: 1000}}/>
                        : 
                        <Result
                            status={isError? "500" : "404"}
                            title={errorMess}
                            subTitle={isError 
                                ? "Hãy kiểm tra lại kết nối internet của bạn rồi thử lại !" 
                                : "Không tìm thấy dữ liệu rồi!\nNếu có bất kỳ thắc mắc nào hãy liên hệ cho BCN CLB để được giải đáp nhé!"}
                        />
                    }
                </div>
            }
        </div>
    );
}
export default ActivityHistory;