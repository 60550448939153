import React, {useEffect, useState, useRef}  from 'react';
import { Tabs, Layout } from 'antd';
import MemberList from './MemberList';
import MemberOutList from './MemberOutList';
import { useHistory } from "react-router-dom";
function Member(){
    const { Content, Footer, Header } = Layout;
    const { TabPane } = Tabs;
    let history = useHistory();
    const [page, setPage] = useState(window.location.pathname.includes("/memberout/")? <MemberOutList/> : <MemberList/>);
    const [keyTab, setkeyTab] = useState(window.location.pathname.includes("/memberout/")? "/memberout/" : "/memberactivity/")
    function callback(key) {
        if(key === "/memberactivity/"){
            setkeyTab("/memberactivity/");
            setPage(<MemberList/>);
            if(window.location.pathname.includes("/memberout/")){
                history.goBack();
            }
        }
        if(key === "/memberout/"){
            setkeyTab("/memberout/");
            setPage(<MemberOutList/>);
            if(window.location.pathname.includes("/memberactivity/")){
                history.goBack();
            }
        }
    }

    useEffect(() => {
        // console.log(window.location.pathname);
        // console.log(window.location.pathname.indexOf("/memberout/") !== 0);
    }, []);

    return(
        <div className="container card-body" style={{ background: "#fff"}}>
            <Layout style={{minHeight: "85vh"}}>
                <Header style={{background:"#fff"}}>
                    <span className={"activetitle"}>Quản Lý Thành Viên</span>
                </Header>
                <Content>
                    <Layout style={{background: "#fff", minHeight: "85vh"}} >
                        <Tabs onChange={callback} activeKey={keyTab} type="card">
                            <TabPane tab="Thành Viên Hiện Tại" key="/memberactivity/">
                                {
                                    page
                                }
                            </TabPane>
                            <TabPane tab="Thành Viên Đã Rời" key="/memberout/">
                                {
                                    page
                                }
                            </TabPane>
                        </Tabs>
                    </Layout>
                </Content>
            </Layout>
        </div>
    )
}
export default Member;